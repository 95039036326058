import { Button, Menu } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Chevrondown } from '../../../../assets/icon/caretdown.svg';

export default function DatabaseDropDown() {
  return (
    <>
      <Menu radius="md">
        <Menu.Target>
          <Button rightIcon={<Chevrondown />} className="z-40 bg-transparent px-4 py-2 text-xl font-normal text-black hover:bg-transparent">
            Database
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Link to="/idea/ideadata">
            <Menu.Item className="text-lg">BuddyKru Idea Database</Menu.Item>
          </Link>
          <Link to="/ideas">
            <Menu.Item className="text-lg">Idea Database</Menu.Item>
          </Link>
          <Link to="/users">
            <Menu.Item className="text-lg">User Database</Menu.Item>
          </Link>
          <Link to="/ins">
            <Menu.Item className="text-lg">ชาวอินส์ Database</Menu.Item>
          </Link>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
