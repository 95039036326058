import { Avatar, Badge, Image } from '@mantine/core';
import React from 'react';

import IconAvatar from '@assets/user-database/Avatar.svg';

interface UserIdProps {
  alt: string | undefined;
  picture: string;
  userId: number | React.ReactNode;
}

export default function UserAvatar({ picture, userId, alt }: UserIdProps) {
  return (
    <Badge
      sx={{ paddingLeft: 0, paddingRight: 6 }}
      size="lg"
      radius="xl"
      className="border border-disabled bg-white text-black"
      leftSection={
        <Avatar alt={alt} size="sm" radius="xl">
          <Image src={picture || IconAvatar} alt="frame" className="absolute" />
        </Avatar>
      }
    >
      {userId}
    </Badge>
  );
}
