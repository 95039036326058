import { TextInput, TextInputProps } from '@mantine/core';
import React, { MouseEventHandler, KeyboardEventHandler } from 'react';
import { ReactComponent as SearchIco } from '../../../assets/icon/search.svg';

interface NewSearchProps extends TextInputProps {
  onClickSearch: MouseEventHandler<HTMLElement>;
}

const NewSearch: React.FC<NewSearchProps> = ({ onClickSearch, ...props }: NewSearchProps) => {
  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      onClickSearch(event as unknown as React.MouseEvent<HTMLElement>);
    }
  };

  return (
    <div className="flex items-center">
      <TextInput
        className="w-full"
        radius="xl"
        onKeyDown={handleKeyPress}
        rightSection={
          <div className="cursor-pointer" onClick={onClickSearch}>
            <SearchIco />
          </div>
        }
        {...props}
      />
    </div>
  );
};

export default NewSearch;
