import { donationPlan } from '@api/donation/donation.service';
import { useInsUserDatabaseDispatch, useInsUserDatabaseState } from '@context/InsUserDatabase.context';
import { MultiSelect, SelectItem } from '@mantine/core';
import { InsUserDatabaseActionType } from '@models/InsUserDatabase.type';
import { Plan, PlanType } from '@models/Plan.type';
import React, { useEffect, useState } from 'react';

const CurrentPackagesFilter = () => {
  const { currentPackage } = useInsUserDatabaseState();
  const dispatch = useInsUserDatabaseDispatch();
  const [options, setOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    donationPlan().subscribe((p: Plan[]) => {
      const plans = p.filter((t) => t.planType !== PlanType.TIPS);
      const op = plans.map((plan) => ({ value: plan.id.toString(), label: plan.name }));
      setOptions(op);
    });
  }, []);

  const handleChangePackage = (value: string[]): void => {
    dispatch({ type: InsUserDatabaseActionType.SET_CURRENT_PACKAGE, payload: value.map((v) => parseInt(v)) });
    dispatch({ type: InsUserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: InsUserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  return (
    <MultiSelect
      onChange={handleChangePackage}
      value={currentPackage?.map((p) => p.toString()) || []}
      nothingFound="No options"
      searchable
      clearable
      radius="md"
      placeholder="แพ็กเกจปัจจุบัน"
      data={options}
    />
  );
};

export default CurrentPackagesFilter;
