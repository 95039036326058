import axiosGolang from '@api/axiosGolang';
import { AddInsForm, UserDonator } from '@models/InsMember.type';
import { Plan } from '@models/Plan.type';
import { Observable, from, map } from 'rxjs';

const url = '/client/plan';
const urlDonation = '/backoffice/donation';

export const donationPlan = (): Observable<Plan[]> => {
  return from(axiosGolang.get(url)).pipe(map((d) => d.data));
};

export const donatorById = (userId: number): Observable<UserDonator> => {
  return from(axiosGolang.get(`${urlDonation}/donator/${userId}`)).pipe(map((d) => d.data));
};

export const configPlan = (insForm: AddInsForm): Observable<void> => {
  return from(axiosGolang.post(`${urlDonation}/config-plan`, insForm)).pipe(map((d) => d.data));
};
