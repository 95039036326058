import React from 'react';
import { Select } from '@mantine/core';

export default function SelectMenu(props) {
  return (
    <Select
      // className={props.className}
      // label={props.label || ""}
      // placeholder={props.placeholder}
      nothingFound="No options"
      // data={props.data}
      {...props}
    />
  );
}
