import API from './api';
const exportedObject = {
  list: (data) => {
    return API.post(`/school-group/list`, data).then((res) => res.data.data.items);
  },
  add: ({ affilition, code, expiredAt, maxMember, membershipEndAt, membershipStartAt, name, province, updatedAt }) => {
    return API.post(`/school-group/create`, {
      affilition,
      code,
      expiredAt,
      maxMember: Number(maxMember),
      membershipEndAt,
      membershipStartAt,
      name,
      province,
      updatedAt,
    }).then((res) => res.data.data);
  },
  update: ({ schoolGroupID, affilition, code, expiredAt, maxMember, membershipEndAt, membershipStartAt, name, province, updatedAt }) => {
    return API.put(`/school-group/update`, {
      schoolGroupID,
      affilition,
      code,
      expiredAt,
      maxMember: Number(maxMember),
      membershipEndAt,
      membershipStartAt,
      name,
      province,
      updatedAt,
    }).then((res) => res.data.data);
  },
  del: (data) => {
    return API.delete(`/school-group/list`, data).then((res) => res.data.data);
  },
  detail: ({ schoolGroupID }) => {
    return API.post(`/school-group/detail`, { schoolGroupID }).then((res) => res.data.data);
  },
};
export default exportedObject;
