import React, { useState } from 'react';

import Select from '../../../../UI/Selector/Select';
import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

export default function AddAdminForm({ form, setpassword }) {
  // form.setFieldValue("password", "asdasdasd");
  return (
    <form onSubmit={() => {}}>
      <TextInput label="อีเมล" placeholder="กรอกอีเมล" {...form.getInputProps('email')} />
      <TextInput
        label="รหัสผ่าน"
        placeholder="กรอกรหัสผ่าน"
        value={form.getInputProps('password').value}
        error={form.getInputProps('password').error}
        onChange={(ev) => {
          form.setFieldValue('password', ev.target.value);
          setpassword(ev.target.value);
        }}
      />
      <TextInput label="ยืนยันรหัสผ่าน" placeholder="ยืนยันรหัสผ่าน" type="password" {...form.getInputProps('password2')} />
      <Select
        className="mt-1"
        label="ตำแหน่ง"
        placeholder="ตำแหน่ง"
        data={[
          { value: 'admin', label: 'Admin' },
          { value: 'superadmin', label: 'Super Admin' },
        ]}
        {...form.getInputProps('isSuperAdmin')}
      ></Select>
    </form>
  );
}
