import ideaCollectionService from '@api/idea-collection/idea-collection.service';
import { Button, List, Table } from '@mantine/core';
import { CollectionCategory, CollectionPagination, IdeaCollectionGroupByYear } from '@models/IdeaCollection';
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import ModalEditIdeaCollection from './ModalEditIdeaCollection';
import { APIResponseError } from '@models/Error';
import { AxiosError } from 'axios';

interface IdeaCollectionSectionProps {
  ideaGroup: IdeaCollectionGroupByYear[];
  fetchData: () => void;
}

const IdeaCollectionSection = ({ ideaGroup, fetchData }: IdeaCollectionSectionProps): JSX.Element => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [currentIdeaCollection, setCurrentIdeaCollection] = useState<IdeaCollectionGroupByYear | undefined>();
  const [categories, setCategories] = useState<CollectionCategory[]>([]);

  const fetchCategories = useCallback(() => {
    ideaCollectionService.getCategories(1, 9999).subscribe((resp: CollectionPagination<CollectionCategory>) => {
      setCategories(resp.data);
    });
  }, []);

  useEffect(() => {
    fetchData();
    fetchCategories();
  }, []);

  const handleNew = (): void => {
    setCurrentIdeaCollection(undefined);
    setOpenEdit(true);
  };

  const handleEdit = (value: IdeaCollectionGroupByYear): void => {
    setCurrentIdeaCollection(value);
    setOpenEdit(true);
  };

  const handleConfirmModal = (): void => {
    fetchData();
    setOpenEdit(false);
  };

  const handleDelete = (value: IdeaCollectionGroupByYear): void => {
    Swal.fire({
      title: 'ลบ Collection ปีนี้?',
      html: `
          <span>Collection ที่ต้องการลบ : Collection ปี ${value.year}</span>
          <span className="text-error">เมื่อลบแล้ว ไอเดียที่ถูกเลือกให้อยู่ใน Collection ของปี ${value.year} จะถูกยกเลิกทั้งหมด</span>
        `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF4E00',
      confirmButtonText: 'ลบ collection นี้',
      cancelButtonText: 'ไม่',
      customClass: {
        title: 'text-error',
        htmlContainer: 'flex flex-col',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        ideaCollectionService.deleteIdeaCollection(value.year).subscribe({
          next: () => {
            fetchData();
          },
          error: (err: AxiosError<APIResponseError>) => {
            let errorMmg = err.response?.data?.error?.message;
            if (err.response?.status === 400) {
              errorMmg = `เนื่องจากมี <strong>${err.response?.data?.error?.message}</strong> ถูกใช้งานอยู่แล้ว`;
            }

            Swal.fire({
              icon: 'error',
              title: 'ไม่สามารถลบได้',
              html: errorMmg,
            });
          },
        });
      }
    });
  };

  return (
    <div className="grid gap-4">
      {openEdit && (
        <ModalEditIdeaCollection
          ideaCollection={currentIdeaCollection}
          categories={categories}
          onCancel={() => setOpenEdit(false)}
          onConfirm={handleConfirmModal}
        />
      )}

      <div>
        <h2 className="text-2xl font-semibold">จัดการ Collection แต่ละปี</h2>
        <p className="text-disabled2">
          สำหรับนำหมวดหมู่ Collection ที่สร้างไว้ในตารางด้านบน มาจัดให้อยู่ในปีต่างๆ (1 หมวดหมู่สามารถอยู่ใน Collection หลายปีได้)
        </p>
      </div>
      <div>
        <Button
          leftIcon={<IconPlus size={14} />}
          radius="xl"
          variant="outline"
          color="gray"
          className="font-pridi text-lg font-normal text-black"
          onClick={handleNew}
        >
          เพิ่มปีและจัดการ Collection ในปีนั้น
        </Button>
      </div>

      <div className="flex w-[780px] flex-col justify-between gap-1">
        <Table highlightOnHover withBorder fontSize="md">
          <thead>
            <tr className="bg-primary-100">
              <th className="w-[130px]"></th>
              <th className="w-24">ปี</th>
              <th>ชื่อหมวดหมู่ Collection</th>
            </tr>
          </thead>
          <tbody>
            {ideaGroup.map((collection) => (
              <tr key={collection.year}>
                <td>
                  <div className="flex items-end gap-2">
                    <div className="cursor-pointer rounded-2xl border bg-primary-100 px-2" onClick={() => handleEdit(collection)}>
                      <IconPencil color="#0C2B66" className="size-6" />
                    </div>
                    <div className="cursor-pointer rounded-2xl border bg-primary-100 px-2" onClick={() => handleDelete(collection)}>
                      <IconTrash color="red" className="size-6" />
                    </div>
                  </div>
                </td>
                <td>{collection.year}</td>
                <td>
                  <List listStyleType="disc">
                    {collection.ideaCollections.map((category) => (
                      <List.Item key={`category-${category.collectionCategory.id}`}>{category.collectionCategory.name}</List.Item>
                    ))}
                  </List>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default IdeaCollectionSection;
