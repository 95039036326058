import { NumberInput, Select, TextInput } from '@mantine/core';
import React, { useEffect } from 'react';

import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { useForm } from '@mantine/form';
import { usePackageList } from '../../api/presenter/usePackage';
import withReactContent from 'sweetalert2-react-content';

export default function AddPackageForm({ values, Dialog, buttonTitle, modalTitile, buttonIcon, fetch, edit = false }) {
  const form = useForm({
    initialValues: {
      packageTypeID: values?.packageTypeID || 1,
      name: values?.name || '',
      price: values?.price || 0,
      duration: values?.duration || 0,
    },

    validate: {
      packageTypeID: (v) => (v ? null : true),
      name: (v) => (v ? null : true),
      price: (v) => (v ? null : true),
      duration: (v) => (v ? null : true),
    },
  });

  const handleOnSubmit = (setOpened) => {
    const { hasErrors } = form.validate();
    if (!hasErrors) {
      if (!values?.packageID) {
        handleAddCupon(form.values, setOpened);
      } else {
        handleEditCupon(form.values, setOpened);
      }
    }
  };

  const MySwal = withReactContent(Swal);
  const { dispatch, mutate } = usePackageList();

  const handleAddCupon = (data, setOpened) => {
    dispatch('create', { ...data, packageTypeID: parseInt(data.packageTypeID) })
      .then((res) => {
        setOpened(false);
        mutate();
        MySwal.fire({
          icon: 'success',
          title: 'สร้าง แพคเกจ สำเร็จ!',
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        setOpened(false);
        MySwal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(err);
      });
  };
  const handleEditCupon = (data, setOpened) => {
    dispatch('update', {
      ...data,
      packageTypeID: parseInt(data.packageTypeID),
      packageID: values.packageID,
    })
      .then((res) => {
        setOpened(false);
        mutate();
        MySwal.fire({
          icon: 'success',
          title: 'แก้ไข แพคเกจ สำเร็จ!',
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        setOpened(false);
        MySwal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(err);
      });
  };

  return (
    <Dialog
      buttonTitle={buttonTitle}
      modalTitile={modalTitile}
      buttonIcon={buttonIcon}
      onSubmit={handleOnSubmit}
      onCloseModal={() =>
        form.setValues({
          packageTypeID: values?.packageTypeID || 1,
          name: values?.name || '',
          price: values?.price || 0,
          duration: values?.duration || 0,
        })
      }
    >
      <form>
        <Select
          label="ประเภทแพ็กเกจ"
          placeholder="ประเภท"
          id={'packageTypeID'}
          data={[
            { label: 'Buddy Kru', value: '1' },
            { label: 'Workshop', value: '2' },
          ]}
          {...form.getInputProps('packageTypeID')}
        />
        <TextInput label="ชื่อแพ็กเกจ" placeholder="ใส่ชื่อแพ็กเกจ" id={'name'} {...form.getInputProps('name')} />
        <NumberInput label="ระยะเวลา (เดือน)" placeholder="ใส่เลข" min={0} id={'duration'} {...form.getInputProps('duration')} />
        <NumberInput label="ราคา" placeholder="ใส่เลข" min={0} id={'price'} {...form.getInputProps('price')} />
      </form>
    </Dialog>
  );
}
