import axiosGolang from '@api/axiosGolang';
import { NotificationInsReq, NotificationInsRes, NotificationUserReq } from '@models/NotificationReq';
import { Observable, from, map } from 'rxjs';

const url = '/backoffice/notification';

export const allNotification = (): Observable<NotificationInsRes[]> => {
  return from(axiosGolang.get(`${url}/scheduler`)).pipe(map((d) => d.data));
};

export const getNotificationById = (id: number): Observable<NotificationInsRes> => {
  return from(axiosGolang.get(`${url}/scheduler/${id}`)).pipe(map((d) => d.data));
};

export const createNotificationSchedule = (noti: NotificationInsReq): Observable<void> => {
  return from(axiosGolang.post(`${url}/scheduler`, noti)).pipe(map((d) => d.data));
};

export const updateNotification = (noti: NotificationInsReq): Observable<void> => {
  return from(axiosGolang.patch(`${url}/scheduler/${noti.id}`, noti)).pipe(map((d) => d.data));
};

export const deleteNotification = (id: number): Observable<void> => {
  return from(axiosGolang.delete(`${url}/scheduler/${id}`)).pipe(map((d) => d.data));
};

export const createNotification = (noti: NotificationUserReq): Observable<{ count: number; status: string }> => {
  return from(axiosGolang.post(`${url}/create`, noti)).pipe(map((d) => d.data));
};
