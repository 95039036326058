import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Box,
  Checkbox,
  Container,
  Group,
  LoadingOverlay,
  Popover,
  ScrollArea,
  Space,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import React, { useEffect, useId, useState } from 'react';
import { useHiddenTagList, useIdeaDataTable } from '../../api/presenter/useIdeaDataTable';
import { CSVLink, CSVDownload } from 'react-csv';
import IdeaOption from './IdeaOption';
import IdeaUpload from './IdeaUpload';
import { ReactComponent as NewWindow } from '../../assets/icon/open.svg';
import { ReactComponent as Pencil } from '../../assets/icon/pencil.svg';
import userIconSource from '../../assets/icon/userIcon.svg';
import { ReactComponent as Search } from '../../assets/icon/SearchNoBg.svg';
import TableGlobal from '../Table/TableGlobal';
import TagAdd from './TagAdd';
import UserNameUI from '../../components/UI/User/UserName';
import dayjs from 'dayjs';
import download from 'downloadjs';
import { parse } from 'json2csv';
import { useDebouncedValue } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { ReactComponent as Download } from '../../assets/icon/download.svg';
import { forwardRef } from 'react';
export default function IdeaDatabase() {
  const [createAtFilter, setCreateAtFilter] = useState(null);
  const [updateAtFilter, setUpdateAtFilter] = useState(null);
  const [hiddenTagFilter, sethiddenTagFilter] = useState([]);
  const [userTagFilter, setUserTagFilter] = useState('');
  const [ideaFilter, setIdeaFilter] = useState(null);
  const [sortFilter, setsortFilter] = useState(null);
  const [searchFilter, setsearchFilter] = useState('');

  const [debounced] = useDebouncedValue(searchFilter, 200);
  const [userTagDebounced] = useDebouncedValue(userTagFilter, 200);
  const { data: taglist, mutate: mutateTags } = useHiddenTagList();
  const { data, mutate, isLoading } = useIdeaDataTable({
    hiddenTagFilter,
    userTagFilter: userTagDebounced?.length ? userTagDebounced?.split(' ').filter((i) => i.length) : [],
    search: debounced,
    ...(() => {
      if (ideaFilter === null) {
        return {
          isHighlight: null,
          InskruSelected: null,
        };
      } else {
        return {
          // Comment isHighlight for get All idea
          isHighlight: ideaFilter === 1,
          InskruSelected: ideaFilter === 2,
        };
      }
    })(),
    ...(() => {
      if (createAtFilter?.[0] && createAtFilter?.[1])
        return {
          startCreatedAt: createAtFilter?.[0] || null,
          endCreatedAt: createAtFilter?.[1] || null,
        };
    })(),
    ...(() => {
      if (updateAtFilter?.[0] && updateAtFilter?.[1])
        return {
          startUpdatedAt: updateAtFilter?.[0] || null,
          endUpdatedAt: updateAtFilter?.[1] || null,
        };
    })(),

    orderby: sortFilter || null,
  });

  const refetchData = () => {
    mutate();
    mutateTags();
  };

  const tableColumns = [
    {
      name: 'Selected',
      center: true,
      selector: (row) => (
        <>
          {row?.inskruSelected ? (
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Tooltip label={row?.inskruSelected} sx={{ width: '100%' }}>
                <div ref={forwardRef}>{row?.inskruSelected}</div>
              </Tooltip>
            </Box>
          ) : (
            <Checkbox
              styles={{
                input: {
                  [`:disabled`]: {
                    cursor: 'pointer',
                  },
                },
              }}
              disabled
            />
          )}
        </>
      ),
    },
    {
      name: 'ชื่อไอเดีย',
      width: '180px',
      selector: (row) => (
        <Group spacing={0} noWrap>
          <Container px={0} sx={{ maxWidth: 130 }}>
            <Text component="a" href={`${process.env.REACT_APP_IDEADB_URL}/${row.slug}`} className="text" target="_blank" rel="noreferrer">
              {row.ideaName}
            </Text>
          </Container>
          <a href={`${process.env.REACT_APP_IDEADB_URL}/${row.slug}`} target="_blank" rel="noreferrer">
            <NewWindow />
          </a>
        </Group>
      ),
    },
    {
      name: 'คนเขียน',
      left: true,
      width: '200px',
      selector: (row) => <UserNameUI username={row.writter} />,
    },
    {
      name: 'User Tag',
      center: true,
      style: { justifyContent: 'flex-end', height: '52px' },
      width: '240px',
      selector: (row) => (
        <div className="flex">
          <div className="my-custom-width scrollbarTable mr-1 flex snap-x flex-nowrap items-center space-x-1.5 overflow-x-auto scroll-smooth">
            <div className="snap-center">
              <ScrollArea sx={{ maxHeight: 64 }} type="always" scrollbarSize={2} styles={{ corner: { borderRadius: '50%' } }}>
                <Group pr={16} spacing={8} noWrap>
                  {row?.ideaUserTags?.map((item, idx) => {
                    return (
                      <React.Fragment key={`userTags-${idx}`}>
                        <Badge variant="filled" size="lg" className="bg-secondary py-4 text-[12px] font-normal text-white">
                          {item}
                        </Badge>
                      </React.Fragment>
                    );
                  })}
                </Group>
                <Space h={2} />
              </ScrollArea>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'Hidden Tag by admin',
      center: true,
      width: '240px',
      style: { justifyContent: 'flex-end', height: '52px' },
      selector: (row) => (
        <div className="flex">
          <div className="my-custom-width scrollbarTable mr-1 flex snap-x flex-nowrap items-center space-x-1.5 overflow-x-auto scroll-smooth">
            <div className="snap-center">
              <ScrollArea sx={{ maxHeight: 64 }} type="always" scrollbarSize={2} styles={{ corner: { borderRadius: '50%' } }}>
                <Group pr={16} spacing={8} noWrap>
                  {row.hiddenTagByAdmin?.map((item, idx) => {
                    return (
                      <React.Fragment key={`hiddenTags-${idx}`}>
                        <Badge variant="filled" size="lg" className="bg-error-200 py-4 text-[12px] font-normal text-black">
                          {item}
                        </Badge>
                      </React.Fragment>
                    );
                  })}
                </Group>
                <Space h={2} />
              </ScrollArea>
            </div>
          </div>
          <TagAdd
            taglist={taglist}
            idx={row.id}
            value={row?.hiddenTagByAdmin || []}
            raw={row?.ideaHiddenTags || []}
            refetchData={refetchData}
            form={form}
          />
        </div>
      ),
    },
    {
      name: 'Received (user)',
      center: true,
      width: '140px',
      selector: (row) => (
        <>
          <UserBrowser label={row?.receivedUser?.length || 0} data={row?.receivedUser || []} />
        </>
      ),
    },
    {
      name: 'Created',
      center: true,
      width: '140px',
      selector: (row) => row.created,
    },
    {
      name: 'Updated',
      center: true,
      selector: (row) => row.updated,
    },
    {
      name: '',
      center: true,
      selector: (row) => (
        <>
          <Anchor href={`${process.env.REACT_APP_IDEADB_URL}/${row.slug}`} target="_blank">
            <ActionIcon
              variant="outline"
              radius={24}
              styles={{
                root: {
                  width: '64px !important',
                  backgroundColor: '#FBF8EF !important',
                  border: '1px solid#E5E5E5',
                },
              }}
            >
              <Pencil />
            </ActionIcon>
          </Anchor>
        </>
      ),
    },
    // {
    //   name: "",
    //   center: true,
    //   width: "140px",
    //   selector: (row) => (
    //     <Link to="/idea/editidea">
    //       <Button
    //         classNames={{
    //           root: "z-40 border rounded-full bg-primary-100  hover:bg-primary-100 border-outline text-black border-blue",
    //         }}
    //       >
    //         <Pencil />
    //       </Button>
    //     </Link>
    //   ),
    // },
  ];

  const form = useForm({
    initialValues: {
      items: [],
    },
  });

  useEffect(() => {
    if (data?.length) {
      const tmp = data
        .filter((item) => item?.title?.length || null)
        .map((item, index) => {
          return {
            inskruSelected: item?.inskruSelected,
            ideaName: item.title,
            writter: item.ownerName ? item.ownerName : 'Username',
            userTag: 'วิทยาศาสตร์',
            hiddenTagByAdmin: item?.ideaHiddenTags?.map((item) => item.hiddenTag) || [],
            ideaUserTags: item?.ideaUserTags?.map((item) => item.userTag) || [],
            receivedUser: item?.memberIdeas?.map((i) => i) || [],
            created: item?.createdTime ? dayjs(item.createdTime).format('DD/MM/YY') : dayjs(item.createdAt).format('DD/MM/YY'),
            updated: item?.updatedTime ? dayjs(item.updatedTime).format('DD/MM/YY') : dayjs(item.createdAt).format('DD/MM/YY'),
            id: item?.ideaID,
            ideaHiddenTags: item?.ideaHiddenTags || [],
            index,
            slug: item?.slug || '',
          };
        });
      form.setFieldValue('items', tmp);
    }
  }, [data]);

  const ideaData = form.getInputProps('items').value;
  const dataCSV =
    ideaData.map((i) => ({
      [`ชื่อไอเดีย`]: i.ideaName,
      [`ลิงก์ไอเดีย`]: `${process.env.REACT_APP_IDEADB_URL}/${i.slug}`,
      [`คนเขียน`]: i.writter,
      [`User Tag`]: i?.ideaUserTags.join(','),
      [`Hidden Tag by admin`]: i?.hiddenTagByAdmin.join(','),
      [`Received (user)`]: i?.receivedUser?.length || 0,
      [`Created`]: i.created,
      [`Updated`]: i.updated,
    })) || {};

  return (
    <div className="container mx-auto">
      <div className="text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">Idea database</div>
      <div className="mt-8 grid w-fit">
        <CSVLink
          data={dataCSV}
          filename={`ideadatabase-exported-${dayjs().format('DD-MM-YYYY_HH-mm-ss')}.csv`}
          className="bg-red flex h-auto w-auto space-x-1 rounded-full border border-black p-1 text-center"
        >
          <Download className="self-center" />
          <span>export csv.</span>
        </CSVLink>
      </div>
      {/* <IdeaUpload downloadHandler={exportCSVHandler} /> */}
      <IdeaOption
        actions={{
          changeHiddenTag: sethiddenTagFilter,
          changeUserTag: setUserTagFilter,
          changeSearch: setsearchFilter,
          changeCreateAt: setCreateAtFilter,
          changeUpdateAt: setUpdateAtFilter,
          changeIdeaFilter: setIdeaFilter,
          changeSort: setsortFilter,
        }}
        values={{
          taglist,
          hiddenTagFilter,
          userTagFilter,
          searchFilter,
          createAtFilter,
          updateAtFilter,
          ideaFilter,
          sortFilter,
        }}
      />
      <div className="text-md mb-4 mt-8">จำนวน {data?.length} ไอเดีย</div>
      <Container px={0} sx={{ position: 'relative', maxWidth: 'unset' }}>
        <LoadingOverlay visible={isLoading} />
        <TableGlobal columns={tableColumns} data={data?.length ? form.getInputProps('items').value : []} />
      </Container>
    </div>
  );
}

const UserBrowser = ({ label, data, ...props }) => {
  const [opened, setOpened] = useState(false);
  const [search, setsearch] = useState('');

  const filteredData = () => {
    const regex = new RegExp(`(${search?.toLowerCase()})`, 'g');
    const res = data?.filter((i) => {
      if (i.member?.fullName?.toLowerCase()?.search(regex) !== -1) {
        return i;
      }
      return null;
    });

    return res;
  };

  return (
    <>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        position="bottom"
        placement="center"
        transition="pop-top-right"
        styles={{ inner: { padding: 14 } }}
      >
        <Popover.Target>
          <Box
            sx={{
              textDecoration: 'underline',
              color: '#0C2B66',
              cursor: 'pointer',
            }}
            onClick={() => setOpened(true)}
          >
            {label}
          </Box>
        </Popover.Target>
        <Popover.Dropdown>
          <Box sx={{ width: '200px' }}>
            <Text sx={{ color: '#6A6A6A', fontSize: '14px' }}>Received (user)</Text>
            <Space h={14} />
            <TextInput
              icon={
                <>
                  <Search />
                </>
              }
              value={search}
              onChange={(ev) => setsearch(ev.target.value)}
            />
            <ScrollArea style={{ height: '200px' }}>
              <Stack mt={16} pr={16}>
                {filteredData()?.map((i, idx) => {
                  return (
                    <Anchor href={`${process.env.REACT_APP_OLDSITE_URL}/profile/${i.member.oldID}`} target={'_blank'}>
                      <Group key={`user-brower-list-${idx}`} spacing={0} sx={{ justifyContent: 'space-between' }} noWrap>
                        <Avatar src={userIconSource} alt="user-icon" radius={'50%'} defaultValue={userIconSource} />
                        <Text
                          sx={{
                            maxWidth: '100px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            color: 'black',
                          }}
                        >
                          {i.member.fullName || ''}
                        </Text>
                        <NewWindow />
                      </Group>
                    </Anchor>
                  );
                })}
              </Stack>
            </ScrollArea>
          </Box>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
