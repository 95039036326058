const OCCUPATIONS = [
  'คุณครู',
  'นักศึกษาครู',
  'อาจารย์มหาวิทยาลัย',
  'ศึกษานิเทศก์',
  'ผู้บริหารโรงเรียน',
  'เจ้าหน้าที่มูลนิธิ (NGO) / องค์กรเพื่อสังคม (Social Enterprise)',
  'เจ้าหน้าที่สถานศึกษา',
  'ติวเตอร์',
  'นักการศึกษา',
  'นักเรียน/นักศึกษา',
  'นักวิชาการ',
  'บุคลากรหน่วยงานการศึกษาของรัฐ',
  'พนักงานบริษัทเอกชน (ธุรกิจเกี่ยวกับการศึกษา)',
  'พนักงานบริษัทเอกชน (ธุรกิจทั่วไป)',
  'พระ',
  'พ่อแม่/ผู้ปกครอง',
  'วิทยากร/กระบวนกร/นักออกแบบการเรียนรู้',
  'หน่วยงานขับเคลื่อนการศึกษาภาคเอกชน',
  'อื่นๆ',
];

export default OCCUPATIONS;
