import React, { forwardRef, useEffect, useState } from 'react';
import { Search } from '@api/schools/school.service';
import { useUserDatabaseDispatch, useUserDatabaseState } from '@context/UserDatabase.context';
import { MultiSelect, SelectItem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Pagination } from '@models/Pagination.type';
import { SchoolSuggest } from '@models/School.type';
import { UserDatabaseActionType } from '@models/UserDatabase.type';
import { finalize } from 'rxjs';

const SchoolSelect = () => {
  const dispatch = useUserDatabaseDispatch();
  const { school } = useUserDatabaseState();
  const [options, setOptions] = useState<SelectItem[]>([]);
  const [searchText, setSearchText] = useState<string>(school[0]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [pages, setPages] = useState<number>(2);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [minScroll, setMinScroll] = useState<number>(0);
  const [debounced] = useDebouncedValue(searchText, 500);

  const fetchSchools = () => {
    const searchOptions = {
      text: debounced,
      pages: 1,
      sizes: 30,
    };

    setFetching(true);
    Search(searchOptions)
      .pipe(finalize(() => setFetching(false)))
      .subscribe(({ data, totalPages }: Pagination<SchoolSuggest>) => {
        const respOpt = data?.map((item) => ({ value: item.schoolName.trim(), label: item.schoolName.trim() }));
        setOptions(options.concat(respOpt));
        setTotalPages(totalPages);
        setMinScroll(0);
      });
  };

  useEffect(() => {
    if (debounced) {
      setPages(2);
      fetchSchools();
    }
    if (!debounced) {
      setOptions([]);
    }
  }, [debounced]);

  const handleChangeSchool = (values: string[]) => {
    const selectedSchools = values.join(', ');
    dispatch({ type: UserDatabaseActionType.SET_SCHOOL, payload: values.map((v) => v) });
    setSearchText(selectedSchools);
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    event.stopPropagation();

    const target = event.target as HTMLElement;
    const current = target.scrollTop + target.offsetHeight;
    const max = target.scrollHeight - 2;
    const hasNext = pages <= totalPages;

    if (current >= max && current > minScroll && debounced && hasNext && !fetching) {
      setMinScroll(max);
      setPages((prev) => prev + 1);
      setFetching(true);

      const searchOptions = {
        text: debounced,
        pages: pages,
        sizes: 30,
      };

      Search(searchOptions)
        .pipe(finalize(() => setFetching(false)))
        .subscribe(({ data, totalPages }: Pagination<SchoolSuggest>) => {
          const respOpt = data?.map((item) => ({ value: item.schoolName.trim(), label: item.schoolName.trim() }));
          setOptions(options.concat(respOpt));
          setTotalPages(totalPages);
        });
    }
  };

  const CustomMultiSelect = forwardRef<HTMLDivElement, SelectItem>((props, ref) => {
    return (
      <div ref={ref} onScroll={handleScroll} style={{ maxHeight: 300, overflowY: 'auto' }}>
        {props.children}
      </div>
    );
  });

  CustomMultiSelect.displayName = 'CustomMultiSelect';

  return (
    <MultiSelect
      onChange={handleChangeSchool}
      onSearchChange={setSearchText}
      searchable
      value={school}
      placeholder="โรงเรียน"
      data={options}
      dropdownComponent={CustomMultiSelect}
      maxDropdownHeight={300}
      nothingFound="ไม่พบโรงเรียนที่คุณค้นหา"
    />
  );
};

export default SchoolSelect;
