export enum SortType {
  USER_ID = 'USER_ID',
  TOP_DONATE = 'TOP_DONATE',
  INS_MEMBER = 'INS_MEMBER',
}

export enum ApplicationChannelType {
  FACEBOOK = 'FACEBOOK',
  EMAIL = 'EMAIL',
}

export default class UserDatabaseFilter {
  sizes: number = 10;
  pages: number = 1;
  sort: SortType = SortType.USER_ID;
  applicationChannel: ApplicationChannelType | undefined = undefined;
  textSearch: string = '';
  lineName: string = '';
  email: string = '';
  donationPackage: number[] | undefined = undefined;
  school: string[] = [];
  province: string[] = [];
  occupation: string[] | undefined = undefined;
  isSearch: boolean = true;
}

export enum UserDatabaseActionType {
  SET_TEXT_SEARCH = 'SET_TEXT_SEARCH',
  SET_LINE_NAME = 'SET_LINE_NAME',
  SET_EMAIL_SEARCH = 'SET_EMAIL_SEARCH',
  SET_SORT = 'SET_SORT',
  SET_DONATION_PACKAGE = 'SET_DONATION_PACKAGE',
  SET_APPLICATION_CHANNEL = 'SET_APPLICATION_CHANNEL',
  SET_SCHOOL = 'SET_SCHOOL',
  SET_PROVINCE = 'SET_PROVINCE',
  SET_OCCUPATION = 'SET_OCCUPATION',
  SET_PAGE = 'SET_PAGE',
  CLICK_SEARCH = 'CLICK_SEARCH',
  CLEAR = 'CLEAR',
}

export type UserDatabaseAction =
  | { type: UserDatabaseActionType.SET_TEXT_SEARCH; payload: string }
  | { type: UserDatabaseActionType.SET_LINE_NAME; payload: string }
  | { type: UserDatabaseActionType.SET_EMAIL_SEARCH; payload: string }
  | { type: UserDatabaseActionType.SET_SORT; payload: SortType }
  | { type: UserDatabaseActionType.SET_DONATION_PACKAGE; payload: number[] }
  | { type: UserDatabaseActionType.SET_APPLICATION_CHANNEL; payload: ApplicationChannelType | undefined }
  | { type: UserDatabaseActionType.SET_SCHOOL; payload: string[] }
  | { type: UserDatabaseActionType.SET_PROVINCE; payload: string[] }
  | { type: UserDatabaseActionType.SET_OCCUPATION; payload: string[] | undefined }
  | { type: UserDatabaseActionType.SET_PAGE; payload: number }
  | { type: UserDatabaseActionType.CLICK_SEARCH; payload: boolean }
  | { type: UserDatabaseActionType.CLEAR; payload?: undefined };

export type UserDatabaseDispatch = (action: UserDatabaseAction) => void;
