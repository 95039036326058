import '@fontsource/pridi';
import 'aos/dist/aos.css';
import 'sweetalert2/dist/sweetalert2.css';
import './App.css';

import '@fontsource/pridi/200.css';
import '@fontsource/pridi/300.css';
import '@fontsource/pridi/400.css';
import '@fontsource/pridi/500.css';
import '@fontsource/pridi/600.css';
import '@fontsource/pridi/700.css';

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { MantineProvider } from '@mantine/core';
import BannerPage from '@pages/banners';
import EditBanner from '@pages/banners/edit';
import { default as IdeaDatabasePage, default as IdeasPage } from '@pages/ideas';
import InsUserPage from '@pages/ins';
import AddIns from '@pages/ins/adds';
import InsNotification from '@pages/notification/ins';
import CreateNotification from '@pages/notification/ins/create';
import EditNotification from '@pages/notification/ins/edit';
import UserNotification from '@pages/notification/user';
import UserPage from '@pages/users';
import AOS from 'aos';
import { Helmet } from 'react-helmet';
import InskruIcon from './assets/InskruIcon.ico';
import HomeLayout from './components/layouts/HomeLayout';
import Layout from './components/layouts/Layout';
import { AuthProvider } from './context/AuthProvider';
import { LoaderProvider } from './context/LoadingOverlay';
import useUser from './hooks/use-user';
import AdminManagePage from './pages/AccountSetting';
import CuponSettingPage from './pages/CouponSetting';
import EditIdeaPage from './pages/EditIdea';
import Home from './pages/Home';
import IdeaDataPage from './pages/IdeaData';
import IdeaPage from './pages/IdeaPage';
import LogicMessagePage from './pages/LogicMessage';
import Login from './pages/Login';
import MemberManagePage from './pages/MemberManage';
import PackageSetting from './pages/PackageSetting';
import SchoolManagePage from './pages/SchoolManage';
import SchoolPage from './pages/SchoolPage';
import SubschoolManagePage from './pages/SubschoolManage';
import NewEditIdeaWrapper from '@pages/ideas/edit';
import BannerForm from '@pages/banners/components/BannerForm';
import IdeaCollections from '@pages/idea-collections';

export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <MantineProvider
      theme={{
        fontFamily: 'Pridi, serif',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inskru Admin</title>
        <link id="favicon" rel="icon" href={InskruIcon} type="image/x-icon" />
      </Helmet>

      <BrowserRouter>
        <AuthProvider>
          <LoaderProvider>
            <Routes>
              <Route
                onEnter={() => {
                  window.location.reload();
                }}
                path="/"
                element={<Login />}
              />

              <Route
                element={
                  <ReqAuth>
                    <Layout />
                  </ReqAuth>
                }
              >
                <Route path="adminmanage" element={<AdminManagePage />}></Route>
                <Route path="membermanage" element={<MemberManagePage />}></Route>
                <Route path="school" element={<SchoolManagePage />}>
                  <Route path="schoolmanage" element={<SchoolPage />}></Route>
                  <Route path=":id" element={<SubschoolManagePage />}></Route>
                </Route>

                <Route path="couponsetting" element={<CuponSettingPage />}></Route>
                <Route path="setting/package" element={<PackageSetting />}></Route>

                <Route path="idea" element={<IdeaPage />}>
                  <Route path="ideadata" element={<IdeaDataPage />}></Route>
                  <Route path="editidea" element={<EditIdeaPage />}></Route>
                </Route>

                <Route path="logicmsg" element={<LogicMessagePage />}></Route>

                <Route path="users">
                  <Route path="" element={<UserPage />}></Route>
                </Route>

                <Route path="ins">
                  <Route path="" element={<InsUserPage />}></Route>
                  <Route path="adds" element={<AddIns />}></Route>
                </Route>

                <Route path="ideas">
                  <Route path="" element={<IdeasPage />}></Route>
                </Route>

                <Route path="notification">
                  <Route path="ins">
                    <Route path="" element={<InsNotification />}></Route>
                    <Route path="create" element={<CreateNotification />}></Route>
                    <Route path="edit/:id" element={<EditNotification />}></Route>
                  </Route>
                  <Route path="user">
                    <Route path="" element={<UserNotification />}></Route>
                  </Route>
                </Route>

                <Route path="ideas">
                  <Route path="" element={<IdeaDatabasePage />}></Route>
                  <Route path="edit/:id" element={<NewEditIdeaWrapper />}></Route>
                </Route>

                <Route path="banners">
                  <Route path="" element={<BannerPage />}></Route>
                  <Route path="create" element={<BannerForm />}></Route>
                  <Route path=":id" element={<EditBanner />}></Route>
                </Route>

                <Route path="idea-collections">
                  <Route path="" element={<IdeaCollections />}></Route>
                </Route>
              </Route>
              <Route
                element={
                  <ReqAuth>
                    <HomeLayout />
                  </ReqAuth>
                }
              >
                <Route path="home" element={<Home />}></Route>
                {/* <Route path="poke" element={<Pokemon />}></Route> */}
              </Route>
            </Routes>
          </LoaderProvider>
        </AuthProvider>
      </BrowserRouter>
    </MantineProvider>
  );
}

const ReqAuth = ({ children }) => {
  const nav = useNavigate();
  let location = useLocation();
  const { user, loading, loggedOut } = useUser();
  useEffect(() => {
    if (loggedOut) {
      nav('/');
    }
  }, [loggedOut, nav]);
  if (loggedOut) return 'redirecting...';
  let profile = null;
  if (loading) {
    profile = 'loading...';
    console.log(profile);
  }
  if (!user) {
    return <nav to="/" state={{ from: location }} />;
  }
  if (user) {
    return <>{children}</>;
  }
};
