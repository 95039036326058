import Paper10 from '@assets/img/createPage/template/Paper.Web.10.png';
import Paper11 from '@assets/img/createPage/template/Paper.Web.11.png';
import Paper12 from '@assets/img/createPage/template/Paper.Web.12.png';
import Paper7 from '@assets/img/createPage/template/Paper.Web.7.png';
import Paper8 from '@assets/img/createPage/template/Paper.Web.8.png';
import Paper9 from '@assets/img/createPage/template/Paper.Web.9.png';
import { Group } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { IconEdit, IconX } from '@tabler/icons-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';

interface ImageUploaderProps {
  preview: string;
  setPreview: (preview: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

const imageMappings: Record<string, string> = {
  'Paper.Web.10.png': Paper10,
  'Paper.Web.11.png': Paper11,
  'Paper.Web.12.png': Paper12,
  'Paper.Web.7.png': Paper7,
  'Paper.Web.8.png': Paper8,
  'Paper.Web.9.png': Paper9,
};

const ImageUploader: React.FC<ImageUploaderProps> = ({ preview, setPreview, setFieldValue }) => {
  const uploadEndpoint = `${process.env.REACT_APP_API_URL}/client/upload/cover`;

  const handleImageUpload = async (files: File[]) => {
    const formData = new FormData();
    formData.append('images', files[0]);

    try {
      const response = await axios.post(uploadEndpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const imageUrl = response.data.image;
      const imageObject = {
        image: imageUrl,
        original: imageUrl,
        thumbnail: imageUrl,
        thumbnailWebp: imageUrl,
      };

      setPreview(imageUrl);
      setFieldValue('images', [imageObject]);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDeleteImage = () => {
    setPreview('');
    setFieldValue('images', []);
  };

  const getLocalImage = () => {
    const matchingKey = Object.keys(imageMappings).find((key) => preview.includes(key));
    return matchingKey ? imageMappings[matchingKey] : preview;
  };

  return (
    <div className="relative aspect-[3/2] w-full max-w-[410px] rounded-2xl bg-cover bg-center bg-no-repeat">
      {preview && (
        <div className="absolute right-2 top-2 z-50 flex gap-2">
          <button type="button" onClick={handleDeleteImage} title="btn-close">
            <IconX size={30} className="rounded-full border border-black bg-white p-1 text-error" />
          </button>
        </div>
      )}
      <Dropzone
        onDrop={handleImageUpload}
        onReject={(files) => console.log('rejected files', files)}
        maxSize={3 * 1024 ** 2}
        accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.webp]}
        multiple={false}
        className="absolute inset-0 rounded-2xl"
        style={{ backgroundImage: `url(${getLocalImage()})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      >
        <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
          {preview && (
            <div className="absolute right-10 top-1.5 z-50 flex gap-2">
              <IconEdit size={30} className="rounded-full border border-black bg-white p-1 text-blue-500" />
            </div>
          )}
          {!preview && (
            <div className="flex cursor-pointer flex-col items-center justify-center">
              <div className="text-textHint/50">
                <p>เลือก / อัพโหลดภาพปก / คลิปวิดีโอ</p>
                <p>คำแนะนำ: ขนาดไฟล์ควรน้อยกว่า 20 MB</p>
              </div>
            </div>
          )}
        </Group>
      </Dropzone>
    </div>
  );
};

ImageUploader.propTypes = {
  preview: PropTypes.string.isRequired,
  setPreview: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default ImageUploader;
