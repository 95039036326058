import { Button, Group, TextInput } from '@mantine/core';

import { API } from '../../../../services/API';
import DatePicker from '../../../UI/DatePickerRange/DatePicker';
import DatePickerWithLabel from '../../../UI/DatePickerRange/DatePickerWithLabel';
import React from 'react';
import Select from '../../../UI/Selector/Select';
import affiliations from '../../../../constants/affiliations';
import provinces from '../../../../constants/provinces';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useForm } from '@mantine/form';

const SCHOOL_GROUP_ADD_URL = '/school-group/create';
const SCHOOL_GROUP_UPDATE_URL = '/school-group/update';

export default function AddSchoolForm({ Dialog, buttonTitle, modalTitile, buttonIcon, values, fetch }) {
  const axiosPrivate = useAxiosPrivate();

  const form = useForm({
    initialValues: {
      schoolGroupID: values?.schoolGroupID || '',
      name: values?.name || '',
      affilition: values?.affilition || '',
      // code: values?.code || "",
      // expiredAt: values?.expiredAt ? new Date(values.expiredAt) : "",
      // maxMember: values?.maxMember || "",
      membershipEndAt: values?.membershipEndAt ? new Date(values.membershipEndAt) : null,
      membershipStartAt: values?.membershipStartAt ? new Date(values.membershipStartAt) : null,
      province: values?.province || '',
    },
    validate: {
      name: (v) => (v ? null : true),
      affilition: (v) => (v ? null : true),
      province: (v) => (v ? null : true),
      membershipEndAt: (v) => (v ? null : true),
      membershipStartAt: (v) => (v ? null : true),
    },
  });

  const handleOnSubmit = (setOpened) => {
    const { hasErrors } = form.validate();
    if (!hasErrors) {
      if (!form.values.schoolGroupID) {
        handleAddSchoolGroup(form.values, setOpened);
      } else {
        handleEditSchoolGroup(form.values, setOpened);
      }
    }
  };

  const handleAddSchoolGroup = async ({ membershipEndAt, membershipStartAt, affilition, name, province }, setOpened) => {
    const response = await axiosPrivate.post(SCHOOL_GROUP_ADD_URL, {
      membershipEndAt,
      membershipStartAt,
      affilition,
      name,
      province,
    });
    fetch();
    setOpened(false);
    console.log(response);
    // API.schoolGroup.add(data).then((res) => {
    //   fetch();
    //   setOpened(false);
    // });
  };

  const handleEditSchoolGroup = async (data, setOpened) => {
    const response = await axiosPrivate.put(SCHOOL_GROUP_UPDATE_URL, data);
    console.log(response);
    fetch();
    setOpened(false);
  };

  const setValue = (e) => {
    if (e[0] && e[1]) {
      form.setFieldValue('membershipStartAt', e[0]);
      form.setFieldValue('membershipEndAt', e[1]);
    }
  };
  return (
    <Dialog buttonTitle={buttonTitle} modalTitile={modalTitile} buttonIcon={buttonIcon} onSubmit={handleOnSubmit}>
      <form>
        {/* <h1>{values?.id ? "แก้ไขกลุ่มโรงเรียน" : "เพิ่มกลุ่มโรงเรียน"}</h1> */}
        <TextInput label="ตั้งชื่อกลุ่มโรงเรียน" placeholder="ใส่ชื่อกลุ่มโรงเรียน" {...form.getInputProps('name')} />
        <DatePickerWithLabel
          className="mt-1"
          label="กำหนดวันเริ่ม - วันหมดอายุ membership"
          placeholder="กำหนดวันเริ่ม - วันหมดอายุ"
          value={[form.getInputProps('membershipStartAt').value, form.getInputProps('membershipEndAt').value]}
          onChange={setValue}
        ></DatePickerWithLabel>
        {/* <Select className="mt-1" label="เลือกคูปอง" placeholder="เลือกคูปอง" data={[]}></Select> */}
        <Select
          className="mt-1"
          label="เลือกสังกัด"
          placeholder="เลือกสังกัด"
          data={affiliations}
          {...form.getInputProps('affilition')}
        ></Select>
        <Select
          className="mt-1"
          label="เลือกจังหวัดของกลุ่มโรงเรียน"
          placeholder="เลือกจังหวัด"
          data={provinces}
          {...form.getInputProps('province')}
        ></Select>
        {/* <h2 className="text-center my-2">Code</h2>
        <TextInput label="code" placeholder="ใส่ Code" {...form.getInputProps("code")} />
        <TextInput label="จำนวน" placeholder="ใส่เลข" {...form.getInputProps("maxMember")} />
        <DatePicker
          label="กำหนดวันหมดอายุ"
          placeholder="กำหนดวันเวลาหมดอายุ"
          defaultValue={null}
          {...form.getInputProps("expiredAt")}
        ></DatePicker> */}
      </form>
    </Dialog>
  );
}
