// import useRequest from "../services/index";
import userFetcher from '../services/api-user';
import useSWR from 'swr';

export default function useUser() {
  const { data, mutate, error } = useSWR('api_user', userFetcher, {
    refreshInterval: 1000,
  });

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    loading,
    loggedOut,
    user: data,
    mutate,
  };
}
