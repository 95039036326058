import { Button, Menu } from '@mantine/core';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Chevrondown } from '../../../../assets/icon/caretdown.svg';
export default function BuddyKruDropDown() {
  return (
    <Fragment>
      <Menu radius="md">
        <Menu.Target>
          <Button rightIcon={<Chevrondown />} className="z-40 bg-transparent px-4 text-xl font-normal text-black hover:bg-transparent">
            BuddyKru
          </Button>
        </Menu.Target>
        {/* Menu items */}
        <Menu.Dropdown>
          <Link to="/logicmsg">
            <Menu.Item className="text-lg">Logic & Message</Menu.Item>
          </Link>
          <Link to="/membermanage">
            <Menu.Item className="text-lg">จัดการบัญชี BuddyKru</Menu.Item>
          </Link>
          <Link to="/school/schoolmanage">
            <Menu.Item className="text-lg">กลุ่มโรงเรียน</Menu.Item>
          </Link>
          <Link to="/couponsetting">
            <Menu.Item className="text-lg">ตั้งค่าคูปอง</Menu.Item>
          </Link>
          <Link to="/setting/package">
            <Menu.Item className="text-lg">ตั้งค่าแพคเกจ</Menu.Item>
          </Link>
        </Menu.Dropdown>
      </Menu>
    </Fragment>
  );
}
