import { Button, Group, MultiSelect, Paper, Popover } from '@mantine/core';
import React, { useState } from 'react';

import { useForm } from '@mantine/form';
import { useHiddenTagActions } from '../../api/presenter/useIdeaDataTable';
import { ReactComponent as PlusCircle } from '../../assets/icon/add.svg';
import { useLoader } from '../../context/LoadingOverlay';
import TagFormList from '../UI/DemoUI/TagFormList';

export default function TagAdd({ taglist, idx, value = {}, refetchData, raw }) {
  const [opened, setOpened] = useState(false);

  const form = useForm({
    initialValues: {
      employees: [],
    },
  });

  const { actions } = useHiddenTagActions();
  const { setLoading } = useLoader();

  const closed = () => {
    setOpened(false);
  };

  return (
    <Group>
      <Popover
        opened={opened}
        classNames={{ header: 'mb-0 border-b-0' }}
        onClose={() => setOpened(false)}
        position="bottom"
        placement="center"
        transition="pop-top-right"
      >
        <Popover.Target>
          <Button
            classNames={{
              root: 'z-40 border rounded-full bg-primary-100  hover:bg-primary-100 border-outline text-black',
              label: '',
              icon: 'mr-0',
            }}
            styles={{
              leftIcon: {
                marginLeft: 5,
              },
            }}
            onClick={() => setOpened((o) => !o)}
            leftIcon={<PlusCircle />}
          ></Button>
        </Popover.Target>
        <Popover.Dropdown>
          <Paper>
            <Group noWrap>
              <PlusCircle />
              <MultiSelect
                valueComponent={blankSelect}
                data={taglist}
                value={value}
                creatable
                searchable
                getCreateLabel={(query) => `+ สร้าง ${query}`}
                onCreate={(query) => form.setFieldValue('employees', [...form.getInputProps('employees').value, { name: query }])}
                onChange={(ev) => {
                  const shouldCreate = ev.filter((i) => !value.includes(i))[0];
                  if (shouldCreate) {
                    setLoading(true);
                    actions('create', {
                      ideaID: idx,
                      hiddenTag: shouldCreate,
                    })
                      .then(() => {
                        refetchData();
                        return new Promise((resolve) => {
                          setTimeout(() => resolve(), 200);
                        }).then(() => {
                          setLoading(false);
                        });
                      })
                      .catch((err) => {
                        setLoading(false);
                        console.log(err);
                      });
                  }
                  setOpened(false);
                }}
                styles={{ value: { display: 'none' }, root: { width: '100%' } }}
              />
            </Group>
            <div>Select option or create one</div>
            <div className="mt-2">
              <TagFormList form={form} value={value} idx={idx} raw={raw} refetchData={refetchData} closed={closed} />
            </div>
          </Paper>
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
}

const blankSelect = () => {
  return <div></div>;
};
