import { IdeaDetailFile } from '@models/IdeaDatabase.type';
import {
  IconFileInfo,
  IconFileSpreadsheet,
  IconFileTypeDoc,
  IconFileTypeDocx,
  IconFileTypePpt,
  IconPdf,
  IconTrash,
} from '@tabler/icons-react';
import React from 'react';

interface FilePreviewProps {
  file: IdeaDetailFile;
  onRemoveFile: (file: IdeaDetailFile) => void;
}

const iconElement = (file: IdeaDetailFile): JSX.Element => {
  const fileType = file.name.split('.')?.pop()?.toLowerCase();
  switch (fileType) {
    case 'pdf':
      return <IconPdf className="size-12" color="gray" />;
    case 'doc':
      return <IconFileTypeDoc className="size-12" color="gray" />;
    case 'docx':
      return <IconFileTypeDocx className="size-12" color="gray" />;
    case 'xls':
    case 'xlsx':
      return <IconFileSpreadsheet className="size-12" color="gray" />;
    case 'ppt':
    case 'pptx':
      return <IconFileTypePpt className="size-12" color="gray" />;
    default:
      return <IconFileInfo className="size-12" color="gray" />;
  }
};

const FilePreview = ({ file, onRemoveFile }: FilePreviewProps): React.JSX.Element => {
  if (!file) {
    return <></>;
  }

  return (
    <div className="relative rounded-2xl border border-dashed bg-primary-100 p-2">
      <div className="absolute right-2 top-2 z-20 flex gap-2">
        <div className="cursor-pointer" onClick={() => onRemoveFile(file)}>
          <IconTrash className="size-8 rounded-full bg-error p-2" color="white" />
        </div>
      </div>

      <div className="flex size-32 flex-col items-center truncate pt-10">
        {iconElement(file)}
        <p className="mt-auto line-clamp-1 text-sm text-disabled2">{file.name}</p>
      </div>
    </div>
  );
};

export default FilePreview;
