import { Image } from '@mantine/core';
import React from 'react';
import userIcon from '../../../assets/icon/userIcon.svg';

export default function UserId({ userId }) {
  return (
    <div className="static flex">
      <div className="absolute -ml-3 self-center">
        <Image src={userIcon} />
      </div>
      <div className="h-7 max-w-fit rounded-full border px-5 pt-0.5 text-center text-black">{userId}</div>
    </div>
  );
}
