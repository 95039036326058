import ideaCollectionService from '@api/idea-collection/idea-collection.service';
import { Button, Pagination, Table } from '@mantine/core';
import { APIResponseError } from '@models/Error';
import { CollectionCategory, CollectionPagination } from '@models/IdeaCollection';
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import ModalEditCollectionCategory from './ModalEditCollectionCategory';
import { Toast } from '@components/Toast/Toast';

interface PageAndTotal {
  pages: number;
  totalPages: number;
}

interface CollectionCategorySectionProps {
  callback?: () => void;
}

const CollectionCategorySection = ({ callback }: CollectionCategorySectionProps): JSX.Element => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [currentCollection, setCurrentCollection] = useState<CollectionCategory | undefined>();
  const [pageAndTotal, setPageAndTotal] = useState<PageAndTotal>({ pages: 1, totalPages: 0 });
  const [categories, setCategories] = useState<CollectionCategory[]>([]);

  const fetchCollection = useCallback((currentPage: number) => {
    ideaCollectionService.getCategories(currentPage, 5).subscribe((resp: CollectionPagination<CollectionCategory>) => {
      setCategories(resp.data);
      setPageAndTotal({ pages: resp.pages, totalPages: resp.totalPages });
    });
  }, []);

  useEffect(() => {
    fetchCollection(pageAndTotal.pages);
  }, []);

  const handleNew = (): void => {
    setCurrentCollection(undefined);
    setOpenEdit(true);
  };

  const handleEdit = (value: CollectionCategory): void => {
    setCurrentCollection(value);
    setOpenEdit(true);
  };

  const handleConfirmModal = (): void => {
    fetchCollection(pageAndTotal.pages);
    setOpenEdit(false);

    if (callback) {
      callback();
    }
  };

  const handleDelete = (value: CollectionCategory): void => {
    Swal.fire({
      title: 'ลบ Collection หมวดหมู่ นี้?',
      text: value?.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF4E00',
      confirmButtonText: 'ลบ collection นี้',
      cancelButtonText: 'ไม่',
      customClass: {
        title: 'text-error',
      },
    }).then((result) => {
      if (result.isConfirmed && value.id) {
        ideaCollectionService.deleteCategory(value.id).subscribe({
          next: () => {
            fetchCollection(pageAndTotal.pages);
            Toast.fire({
              icon: 'success',
              title: 'ลบสำเร็จ',
            });
          },
          error: (err: AxiosError<APIResponseError>) => {
            if (err.response?.data?.error?.message) {
              let text = err.response?.data?.error?.message;
              if (text === 'id has been used') {
                text = 'Collection หมวดหมู่นี้ถูกใช้งานอยู่ หากต้องการลบ ต้องนำหมวดหมู่นี้ออกจาก Collection ในปีที่ถูกใช้ก่อน';
              }

              Swal.fire({
                icon: 'error',
                title: 'ไม่สามารถลบได้',
                text,
              });
            }
          },
        });
      }
    });
  };

  const onChangePage = (pages: number): void => {
    fetchCollection(pages);
  };

  return (
    <div className="grid gap-4">
      <div>
        <h2 className="text-2xl font-semibold">จัดการชื่อหมวดหมู่ Collection</h2>
        <p className="text-disabled2">กรณีที่มีหมวดหมู่ Collection ใหม่ๆ ไม่ซ้ำกับปีก่อนหน้า ให้เพิ่มชื่อหมวดหมู่ในตารางนี้ก่อน</p>
      </div>
      <div>
        <Button
          leftIcon={<IconPlus size={14} />}
          radius="xl"
          variant="outline"
          color="gray"
          className="font-pridi text-lg font-normal text-black"
          onClick={handleNew}
        >
          เพิ่มชื่อหมวดหมู่ Collection
        </Button>
        {openEdit && (
          <ModalEditCollectionCategory collection={currentCollection} onCancel={() => setOpenEdit(false)} onConfirm={handleConfirmModal} />
        )}
      </div>

      <div className="flex h-[286px] w-[780px] flex-col justify-between gap-1">
        <Table highlightOnHover withBorder fontSize="md">
          <thead>
            <tr className="bg-primary-100">
              <th className="w-[130px]"></th>
              <th>ชื่อหมวดหมู่ Collection</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.id}>
                <td>
                  <div className="flex items-end gap-2">
                    <div className="cursor-pointer rounded-2xl border bg-primary-100 px-2" onClick={() => handleEdit(category)}>
                      <IconPencil color="#0C2B66" className="size-6" />
                    </div>
                    <div className="cursor-pointer rounded-2xl border bg-primary-100 px-2" onClick={() => handleDelete(category)}>
                      <IconTrash color="red" className="size-6" />
                    </div>
                  </div>
                </td>
                <td>{category.name}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="flex justify-end">
          <Pagination total={pageAndTotal.totalPages} onChange={onChangePage} />
        </div>
      </div>
    </div>
  );
};

export default CollectionCategorySection;
