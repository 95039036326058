import DonationPlanFilter from '@components/Filter/DonationPlanFilter';
import SchoolSelect from '@components/Filter/SchoolSeclect';
import NewSearch from '@components/UI/Search/NewSearch';
import { APPLICATION_CHANNELS } from '@constants/Category';
import OCCUPATIONS from '@constants/Occupations';
import provinces from '@constants/provinces';
import { useUserDatabaseDispatch, useUserDatabaseState } from '@context/UserDatabase.context';
import { Button, MultiSelect, Select } from '@mantine/core';
import { ApplicationChannelType, SortType, UserDatabaseActionType } from '@models/UserDatabase.type';
import React from 'react';

const Search = () => {
  const { sort, textSearch, lineName, email, applicationChannel, occupation, province } = useUserDatabaseState();
  const dispatch = useUserDatabaseDispatch();

  const handleChangeSort = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_SORT, payload: value as SortType });
  };

  const handleClickSearchIcon = (): void => {
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeTextSearch = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_TEXT_SEARCH, payload: value });
  };

  const handleChangeLineName = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_LINE_NAME, payload: value });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleEmailSearch = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_EMAIL_SEARCH, payload: value });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeApplicationChannel = (value: string): void => {
    dispatch({ type: UserDatabaseActionType.SET_APPLICATION_CHANNEL, payload: value as ApplicationChannelType });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeOccupation = (value: string[]): void => {
    dispatch({ type: UserDatabaseActionType.SET_OCCUPATION, payload: value.map((v) => v) });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeProvince = (value: string[]): void => {
    dispatch({ type: UserDatabaseActionType.SET_PROVINCE, payload: value.map((v) => v) });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleClickClearSearch = (): void => {
    dispatch({ type: UserDatabaseActionType.CLEAR });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <h2 className="w-fit">เรียงลำดับจาก : </h2>
        <Select
          value={sort}
          onChange={handleChangeSort}
          data={[
            { value: 'USER_ID', label: 'เลขสมาชิก' },
            { value: 'TOP_DONATE', label: 'Top Donate' },
          ]}
        />
      </div>

      <div className="flex w-full gap-2">
        <div className="w-6/12">
          <NewSearch
            placeholder="ค้นหาชื่อ นามปากกา อีเมลติดต่อ userid หรือเบอร์ติดต่อ"
            value={textSearch}
            onChange={(e) => handleChangeTextSearch(e.currentTarget.value)}
            onClickSearch={handleClickSearchIcon}
          />
        </div>
        <div className="w-3/12">
          <NewSearch
            placeholder="ค้นหาอีเมลที่ใช้สมัคร"
            value={email}
            onChange={(e) => handleEmailSearch(e.currentTarget.value)}
            onClickSearch={handleClickSearchIcon}
          />
        </div>
        <div className="w-3/12">
          <NewSearch
            placeholder="ค้นหาไลน์"
            value={lineName}
            onChange={(e) => handleChangeLineName(e.currentTarget.value)}
            onClickSearch={handleClickSearchIcon}
          />
        </div>
      </div>

      <div className="flex w-full items-center gap-4">
        <div className="w-3/12">
          <DonationPlanFilter />
        </div>

        <div className="w-3/12">
          <Select
            placeholder="สมัครด้วย"
            clearable
            nothingFound="No options"
            value={applicationChannel ? applicationChannel.toString() : ''}
            data={APPLICATION_CHANNELS}
            onChange={handleChangeApplicationChannel}
          />
        </div>

        <div className="w-3/12">
          <MultiSelect
            placeholder="อาชีพ"
            clearable
            value={occupation ? occupation : []}
            data={OCCUPATIONS}
            onChange={handleChangeOccupation}
          />
        </div>

        <div className="w-3/12">
          <MultiSelect
            placeholder="จังหวัด"
            clearable
            value={province ? province : []}
            searchable
            nothingFound="ไม่พบจังหวัดที่คุณค้นหา"
            data={provinces}
            onChange={handleChangeProvince}
          />
        </div>
        <div className="w-3/12">
          <SchoolSelect />
        </div>
      </div>
      <div className="float-right w-full">
        <Button className="float-right w-fit" variant="subtle" onClick={handleClickClearSearch}>
          ล้างตัวกรอง
        </Button>
      </div>
    </div>
  );
};

export default Search;
