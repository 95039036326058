import { Container, Group, Pagination, useMantineTheme } from '@mantine/core';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';

const CustomPagination = ({ currentPage, rowCount, rowsPerPage, onChangePage, onChangeRowsPerPage }) => {
  return (
    <>
      <Group sx={{ justifyContent: 'flex-end', padding: '1rem' }} pb={0}>
        <Pagination
          page={currentPage}
          onChange={onChangePage}
          radius="xl"
          size="lg"
          total={Math.ceil(rowCount / rowsPerPage)}
          classNames={{
            item: 'text-black border-0',
            active: 'bg-secondaryPage',
            root: '!text-black',
          }}
        />
      </Group>
    </>
  );
};

const TableGlobal = ({ columns, data }) => {
  const theme = useMantineTheme();
  return (
    <>
      <Container
        sx={{
          border: `2px solid ${theme.colors.gray[4]}`,
          maxWidth: '100%',
          borderRadius: theme.radius.xl,
          [`> div`]: {
            borderRadius: '32px 32px 0 0',
          },
        }}
        classNames="!text-black"
        px={0}
        py={24}
      >
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10]}
          customStyles={{
            headRow: {
              style: {
                borderBottom: 'none',
                color: '#34353580',
                fontWeight: 'bold',
                fontSize: '14px',
              },
            },
          }}
          conditionalRowStyles={[
            {
              when: (row) => row.index % 2 === 1,
              style: { backgroundColor: '#E5F3FA' },
            },
          ]}
        />
      </Container>
    </>
  );
};

export default TableGlobal;
