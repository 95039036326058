import { Button, Group, MultiSelect, Popover, TextInput } from '@mantine/core';
import React, { useEffect } from 'react';

import { API } from '../../../services/API';
import DatePicker from '../../../components/UI/DatePickerRange/DatePicker';
import Select from '../../UI/Selector/Select';
import SelectCheckbox from './SelectCheckbox';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { mutate } from 'swr';
import { useCouponList } from '../../../api/presenter/useCoupon';
import { useForm } from '@mantine/form';
import withReactContent from 'sweetalert2-react-content';

// import DialogCard from "../../UI/Card/DaialogCardTable";
// import { ReactComponent as Pencil } from "../../../assets/icon/pencil.svg";
// import { ReactComponent as Plus } from "../../../assets/icon/Plus.svg";

export default function AddCuponForm({ values, Dialog, buttonTitle, modalTitile, buttonIcon, fetch }) {
  const couponDiscountTypeOptions = [
    { label: 'ส่วนลด (%)', value: '1' },
    { label: 'ส่วนลด (บาท)', value: '2' },
  ];

  const form = useForm({
    initialValues: {
      couponID: values?.couponID || '',
      code: values?.code || '',
      couponDiscountTypeID: String(values?.couponDiscountTypeID) || 0,
      discountValue:
        values?.couponDiscountTypeID === 1
          ? parseInt(values.discountPercent) || 0
          : values?.couponDiscountTypeID === 2
          ? parseInt(values.discountValue) || 0
          : 0,
      expiredAt: values?.expiredAt || dayjs(),
      maxMember: values?.maxMember || '',
      packageIDs: values?.packageIDs?.map((i) => `${i}`) || [],
    },

    validate: {
      code: (v) => (v ? null : true),
      couponDiscountTypeID: (v) => (v ? null : true),
      discountValue: (v) => (v ? null : true),

      expiredAt: (v) => (v ? null : true),
      maxMember: (v) => (v && v > 0 ? null : true),
    },
  });

  useEffect(() => {
    if (values?.couponID) {
      form.setValues({
        couponID: values?.couponID || '',
        code: values?.code || '',
        couponDiscountTypeID: String(values?.couponDiscountTypeID) || 0,
        discountValue:
          values?.couponDiscountTypeID === 1
            ? parseInt(values.discountPercent) || 0
            : values?.couponDiscountTypeID === 2
            ? parseInt(values.discountValue) || 0
            : 0,
        expiredAt: values?.expiredAt || dayjs(),
        maxMember: values?.maxMember || '',
        packageIDs: values?.packageIDs?.map((i) => `${i}`) || [],
      });
    } else {
      form.setValues({
        couponID: '',
        code: '',
        couponDiscountTypeID: 0,
        discountValue: 0,
        expiredAt: dayjs(),
        maxMember: '',
        packageIDs: [],
      });
    }
  }, [values]);

  const handleOnSubmit = (setOpened) => {
    const { hasErrors } = form.validate();
    if (!hasErrors) {
      if (!form.values.couponID) {
        handleAddCupon(form.values, setOpened);
      } else {
        handleEditCupon(form.values, setOpened);
      }
    }
  };

  const { dispatch, packages, mutate } = useCouponList();

  const MySwal = withReactContent(Swal);

  const handleAddCupon = (data, setOpened) => {
    dispatch('create', {
      code: data.code,
      discountValue: parseFloat(data.discountValue),
      maxMember: parseInt(data.maxMember),
      expiredAt: '2022-10-22T12:00:00Z' || dayjs(data.expiredAt).format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
      couponDiscountTypeID: parseInt(data.couponDiscountTypeID),
      packageIDs: data.packageIDs.map((item) => parseInt(item)),
    })
      .then((res) => {
        setOpened(false);
        mutate();
        MySwal.fire({
          icon: 'success',
          title: 'สร้าง คูปอง สำเร็จ!',
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(res);
      })
      .catch((err) => {
        setOpened(false);
        MySwal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(err);
      });
  };

  const handleEditCupon = (data, setOpened) => {
    dispatch('update', {
      code: data.code,
      discountValue: parseFloat(data.discountValue),
      maxMember: parseInt(data.maxMember),
      expiredAt: '2022-10-22T12:00:00Z' || dayjs(data.expiredAt).format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
      couponDiscountTypeID: parseInt(data.couponDiscountTypeID),
      packageIDs: data.packageIDs.map((item) => parseInt(item)),
      couponID: values.couponID,
    })
      .then((res) => {
        setOpened(false);
        mutate();
        MySwal.fire({
          icon: 'success',
          title: 'แก้ไข คูปอง สำเร็จ!',
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(res);
      })
      .catch((err) => {
        setOpened(false);
        MySwal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(err);
      });
  };

  return (
    <Dialog
      buttonTitle={buttonTitle}
      modalTitile={modalTitile}
      buttonIcon={buttonIcon}
      onSubmit={handleOnSubmit}
      onCloseModal={() =>
        form.setValues({
          couponID: '',
          code: '',
          couponDiscountTypeID: 0,
          discountValue: 0,
          expiredAt: dayjs(),
          maxMember: '',
          packageIDs: [],
        })
      }
    >
      <form>
        <TextInput label="รหัสคูปอง" placeholder="ใส่รหัส coupon" {...form.getInputProps('code')} />
        <SelectCheckbox data={packages || []} {...form.getInputProps('packageIDs')} />
        <Select
          data={couponDiscountTypeOptions}
          defaultValue={form.values.couponDiscountTypeID}
          label="เลือกวิธีลดราคา"
          {...form.getInputProps('couponDiscountTypeID')}
        ></Select>
        <TextInput className="mt-1" label="" placeholder="ใส่เลข" min={0} type="Number" {...form.getInputProps('discountValue')} />
        {/* <div>{form.values.expiredAt}</div> */}

        <DatePicker
          label="กำหนดวันหมดอายุ"
          placeholder="กำหนดวันเวลาหมดอายุ"
          defaultValue={new Date(form.values.expiredAt)}
          {...form.getInputProps('expiredAt')}
        ></DatePicker>
        <TextInput
          className="mt-1"
          label="จำนวนคูปองที่แจก"
          placeholder="ใส่เลข"
          min={0}
          type="Number"
          {...form.getInputProps('maxMember')}
        />
      </form>
    </Dialog>
  );
}
