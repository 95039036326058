import axios from '../axios';
import useSWR from 'swr';
import { ommitNull, paramsBuilder } from '../../utils/utils';

const shouldImplement = () => {
  return new Promise((resolve) => {
    resolve();
  });
};

export const useManageMember = ({
  alias,
  occupation,
  school,
  province,
  schoolRoleID,
  packageGroupID,
  startExpirationDate,
  endExpirationDate,
  tag,
  levelTag,
  subjectTag,
}) => {
  const { data, mutate } = useSWR(
    `${paramsBuilder('/member/list', {
      _perPage: null,
      alias: alias || null,
      occupation: occupation || null,
      school: school || null,
      province: province || null,
      schoolRoleID,
      packageGroupID,
      startExpirationDate,
      endExpirationDate,
      tag,
      levelTag,
      subjectTag,
    })}`,
    (url) =>
      axios
        .post(
          '/member/list',
          ommitNull({
            _perPage: null,
            alias: alias || null,
            occupation: occupation || null,
            school: school || null,
            province: province || null,
            schoolRoleID,
            packageGroupID,
            startExpirationDate,
            endExpirationDate,
            tag,
            levelTag,
            subjectTag,
          })
        )
        .then((res) => res.data)
  );

  /**
   *
   * @param {"delete"|"create"} action
   * @param {*} payload
   * @returns
   */
  const dispatch = (action, payload) => {
    switch (action) {
      case 'delete':
        return axios.patch('/member/deactive', payload);

      case 'create':
        return axios.post('/member/package/create', payload);

      default:
        return shouldImplement();
    }
  };
  return {
    data: data?.data?.items || [],
    length: data?.data?.items?.length || 0,
    dispatch,
    mutate,
  };
};

export const useSchoolList = () => {
  const { data } = useSWR('/school/list', (url) => axios.get(url).then((res) => res.data));

  return { data: data?.data?.items?.map((item) => item.name) || [] };
};
