import axios from '../axios';
import dayjs from 'dayjs';
import useSWR from 'swr';

const shouldImplement = () =>
  new Promise((resolve) => {
    resolve();
  });

export const useCouponList = () => {
  const { data, mutate } = useSWR('/coupon/list', (url) => axios.get(url).then((res) => res.data.data));

  const { data: packages } = useSWR('/package/list', (url) => axios.get(url).then((res) => res.data));

  /**
   *
   * @param {"create"|"update"|"delete"} action
   * @param {*} payload
   */
  const dispatch = (action, payload) => {
    switch (action) {
      case 'create':
        return axios.post('/coupon/create', payload).then((res) => res.data);
      case 'update':
        return axios.put('/coupon/update', payload).then((res) => res.data);
      case 'delete':
        return axios.post('/coupon/delete', payload).then((res) => res.data);
      default:
        return shouldImplement();
    }
  };

  return {
    data:
      data?.items?.map((item) => ({
        expiredAt: dayjs(item.expiredAt),
        code: item.code,
        couponDiscountTypeID: item.couponDiscountTypeID,
        discountPercent: item.couponDiscountTypeID === 1 ? `${item.discountValue}%` : '-',
        discountValue: item.couponDiscountTypeID === 2 ? item.discountValue : '-',
        maxMember: item.maxMember,
        id: item.couponID,
        couponID: item.couponID,
        packageIDs: item?.packageIDs?.map((item) => `${item}`) || [],
        currentMember: item?.currentMember,
      })) || [],
    packages:
      packages?.data?.items?.map((item) => ({
        value: String(item.packageID),
        label: item.name,
      })) || [],
    dispatch,
    mutate,
  };
};
