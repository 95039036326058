import React from 'react';
import { Badge, Box, Group, ScrollArea, Space } from '@mantine/core';

import { ReactComponent as Trash } from '../../../assets/icon/trash.svg';
import { ReactComponent as Pencil } from '../../../assets/icon/pencil.svg';
import { ReactComponent as Plus } from '../../../assets/icon/Plus.svg';

import { useLoader } from '../../../context/LoadingOverlay';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import AddLogicForm from './AddLogicForm';
import DelLogicForm from './DelLogicForm';
import TableGlobal from '../../Table/TableGlobal';
import { useLogic } from '../../../api/presenter/useLogic';
import { useSWRConfig } from 'swr';
export default function AllLogic() {
  const MySwal = withReactContent(Swal);
  const { data, dispatch } = useLogic();
  const { setLoading } = useLoader();

  const { mutate } = useSWRConfig();

  const refetchData = () => {
    mutate('/logic/list');
    mutate('/idea/hidden-tag/list');
  };

  const columns = [
    {
      name: 'ชื่อ Logic',
      selector: (row) => row.name,
    },
    // {
    //   name: "Link Paperform",
    //   selector: (row) => row.links,
    // },
    {
      name: 'Hidden Tag',
      height: '52px',
      width: '920px',
      selector: (row) => (
        <>
          <Space h={6} />
          <ScrollArea sx={{ maxHeight: 64, width: '65vw' }} type="always" scrollbarSize={2} styles={{ corner: { borderRadius: '50%' } }}>
            <Group pr={16} spacing={8} noWrap>
              {row?.hiddenTags?.map((item, idx) => {
                return (
                  <Badge
                    variant="filled"
                    size="lg"
                    className="bg-secondary py-4 text-[12px] font-normal text-white"
                    key={`${row.name}-${idx}`}
                  >
                    {item}
                  </Badge>
                );
              })}
            </Group>

            <Space h={4} />
          </ScrollArea>
          <Space h={4} />
        </>
      ),
    },
    {
      name: '',
      right: true,
      width: '150px',
      selector: (row) => (
        <div className="flex justify-end space-x-2">
          <AddLogicForm
            buttonIcon={<Plus />}
            modalTitile="แก้ไข Logic"
            triggerIcon={<Pencil />}
            triggerType="icon"
            value={row}
            onCreate={(payload) => {
              try {
                dispatch('update', payload).then((res) => {
                  setLoading(false);
                  refetchData();
                  MySwal.fire({
                    icon: 'success',
                    title: 'แก้ไข Logic สำเร็จ!',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                });
              } catch (err) {
                MySwal.fire({
                  icon: 'error',
                  title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            }}
          />
          <DelLogicForm
            label={`ต้องการลบ Logic : ${row.name}`}
            icon={<Trash />}
            onConfirm={() => {
              try {
                setLoading(true);
                dispatch('delete', { logicID: parseInt(row.id) }).then((res) => {
                  setLoading(false);
                  refetchData();
                });
              } catch (err) {
                console.log(err);
              }
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="mt-16 text-xl tracking-wide md:text-xl lg:text-2xl">Logic ทั้งหมด</div>
      <div className="mt-8">
        <AddLogicForm
          buttonIcon={<Plus />}
          modalTitile="เพิ่ม Logic"
          onCreate={(payload) => {
            try {
              dispatch('create', payload).then((res) => {
                setLoading(false);
                refetchData();
                MySwal.fire({
                  icon: 'success',
                  title: 'สร้าง Logic สำเร็จ!',
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
            } catch (err) {
              MySwal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }}
        />
      </div>
      <Box className="mt-8">
        <TableGlobal columns={columns} data={data} />
      </Box>
    </div>
  );
}
