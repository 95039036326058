import { Container, Group } from '@mantine/core';
import React from 'react';
import { ReactComponent as Usericon } from '../../../assets/icon/userIcon.svg';

export default function UserName({ username }) {
  return (
    <div className="static flex">
      <Group spacing={16} noWrap>
        <div className="absolute self-center">
          <Usericon />
        </div>
        <Container>
          <div className="ml-5 w-20 text-center text-black">{username}</div>
        </Container>
      </Group>
    </div>
  );
}
