import axios from '../axios';

export const shouldImplement = () => {
  return new Promise((resolve) => {
    resolve();
  });
};

export const useSubSchoolMember = () => {
  /**
   *
   * @param {"create"|"update"} action
   * @param {*} payload
   * @returns
   */
  const dispatch = (action, payload) => {
    switch (action) {
      case 'create':
        return axios.post('/member/school/create', {
          oldID: parseInt(payload.oldID),
          schoolRoleID: parseInt(payload.schoolRoleID),
          schoolGroupID: parseInt(payload.schoolGroupID),
        });

      case 'update':
        return axios.patch('/member/school-role/update', {
          schoolRoleID: parseInt(payload.schoolRoleID),
          oldID: parseInt(payload.oldID),
        });

      default:
        return shouldImplement;
    }
  };
  return { dispatch };
};
