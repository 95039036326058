import React, { useEffect, useState } from 'react';

import { ReactComponent as Trash } from '../../../assets/icon/trash.svg';
import { ReactComponent as Pencil } from '../../../assets/icon/pencil.svg';
import { ReactComponent as Plus } from '../../../assets/icon/Plus.svg';

import { Box, Button, Modal, Text } from '@mantine/core';

import AddMsgForm from './AddMsgForm';
import TableGlobal from '../../Table/TableGlobal';
import { useLogicMessage, useLogicName } from '../../../api/presenter/useLogicMessage';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DelLogicForm from '../AllLogic/DelLogicForm';

const LogicTitle = (id) => {
  const { title } = useLogicName({ id: id.id });
  return <Text className="text">{title}</Text>;
};

export default function AllLMsg() {
  const { data, dispatch, mutate } = useLogicMessage();
  const MySwal = withReactContent(Swal);
  const [modal, setmodal] = useState(false);

  const [activeModalData, setactiveModalData] = useState({});

  const columns = [
    { name: 'ข้อความ', selector: (row) => row.value },
    {
      name: 'ชื่อ Logic',
      selector: (row) => {
        return <LogicTitle id={row.logicID} />;
      },
    },
    {
      name: '',
      width: '180px',
      right: true,
      selector: (row) => (
        <>
          <div className="flex justify-end space-x-2">
            <Button
              classNames={{
                root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
                label: '',
                icon: 'mr-0',
              }}
              styles={{
                leftIcon: {
                  marginRight: 15,
                },
              }}
              leftIcon={<Pencil />}
              onClick={() => {
                setactiveModalData(row);
                setmodal(true);
              }}
            />
            <DelLogicForm
              label={`ต้องการลบ Message : ${row.value}`}
              icon={<Trash />}
              onConfirm={() => {
                dispatch('delete', { id: row.logicMessageID })
                  .then(() => {
                    mutate();
                    MySwal.fire({
                      icon: 'success',
                      title: 'ลบ Logic Message สำเร็จ!',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch((err) => {
                    MySwal.fire({
                      icon: 'error',
                      title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  });
              }}
            />
          </div>
        </>
      ),
    },
  ];
  return (
    <div>
      <div className="mt-16 text-xl tracking-wide md:text-xl lg:text-2xl">Message ทั้งหมด</div>
      <div className="mt-8">
        <Modal centered opened={modal} onClose={() => setmodal(false)} closeOnClickOutside={false}>
          <AddMsgForm
            actions={{
              cancel: () => setmodal(false),
              create: (ev) => {
                dispatch('create', ev)
                  .then(() => {
                    mutate();
                    setmodal(false);
                    MySwal.fire({
                      icon: 'success',
                      title: 'สร้าง Logic Message สำเร็จ!',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch((err) => {
                    MySwal.fire({
                      icon: 'error',
                      title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  });
              },
              update: (ev) => {
                dispatch('update', ev)
                  .then(() => {
                    mutate();
                    setmodal(false);
                    MySwal.fire({
                      icon: 'success',
                      title: 'อัพเดท Logic Message สำเร็จ!',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch((err) => {
                    MySwal.fire({
                      icon: 'error',
                      title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  });
              },
            }}
            row={activeModalData}
            reset={() => setactiveModalData({})}
          />
        </Modal>
      </div>
      <Button
        classNames={{
          root: 'z-40 bg-primary hover:bg-primary-600 active:bg-primary rounded-full text-black',
          label: 'text',
          icon: 'mr-0',
        }}
        styles={{
          leftIcon: {
            marginRight: 15,
          },
        }}
        leftIcon={<Plus />}
        onClick={() => setmodal(true)}
      >
        &nbsp;&nbsp;เพิ่มข้อความ
      </Button>
      <Box className="mt-8">
        <TableGlobal columns={columns} data={data} />
      </Box>
    </div>
  );
}
