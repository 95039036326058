import axiosGolang from '@api/axiosGolang';
import { IdeaDatabaseSubmit, IdeaDetailRes, IdeaInskruSelectedReq, IdeaResult, UserSuggestResponse } from '@models/IdeaDatabase.type';
import { Pagination } from '@models/Pagination.type';
import { Observable, from, map } from 'rxjs';

const url = '/backoffice/ideas';

export const searchIdeas = (search: IdeaDatabaseSubmit): Observable<Pagination<IdeaResult>> => {
  return from(axiosGolang.post(`${url}/search`, search)).pipe(map((d) => d.data));
};

export const recommendIdea = (ideaId: string): Observable<void> => {
  return from(axiosGolang.patch(`${url}/recommend/${ideaId}`)).pipe(map((d) => d.data));
};

export const deleteIdea = (ideaId: string): Observable<void> => {
  return from(axiosGolang.delete(`${url}/${ideaId}`)).pipe(map((d) => d.data));
};

export const inskruSelectedIdea = (ideaId: string, selectedType: IdeaInskruSelectedReq): Observable<void> => {
  return from(axiosGolang.patch(`${url}/selected/${ideaId}`, selectedType)).pipe(map((d) => d.data));
};

export const getIdeaById = (ideaId: string): Observable<IdeaDetailRes> => {
  return from(axiosGolang.get(`${url}/${ideaId}`)).pipe(map((d) => d.data));
};

export const exportIdeas = (search: IdeaDatabaseSubmit) => {
  return from(axiosGolang.post(`${url}/export/excel`, search, { responseType: 'blob' })).pipe(map((d) => d.data));
};

export const restoreIdea = (ideaId: string) => {
  return from(axiosGolang.patch(`${url}/restore/${ideaId}`)).pipe(map((d) => d.data));
};

export const permanentDeleteIdea = (ideaId: string): Observable<void> => {
  return from(axiosGolang.delete(`${url}/permanent/${ideaId}`)).pipe(map((d) => d.data));
};

export const editIdea = (ideaId: string, idea: IdeaDetailRes): Observable<void> => {
  return from(axiosGolang.put(`${url}/${ideaId}`, idea)).pipe(map((d) => d.data));
};

export const getUserSuggest = (text: string): Observable<UserSuggestResponse[]> => {
  return from(axiosGolang.get(`${url}/credit-suggestion?text=${text}`)).pipe(map((d) => d.data));
};
