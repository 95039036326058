export enum PlanType {
  PLAN = 'PLAN',
  TIPS = 'TIPS',
}

export interface Plan {
  id: number;
  picture: string;
  advertisement?: string;
  name: string;
  stripeProductId: string;
  stripePriceId: string;
  ageOfPlan: number;
  price: number;
  pricePerMonth: number;
  description: string;
  isRecommend: boolean;
  isDefault: boolean;
  planType: PlanType;
  status: string;
  sequenceOfPlan: number;
  priority: number;
}
