import { Box, Checkbox, Group, MultiSelect, Popover, ScrollArea, Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';

const SelectCheckbox = ({ data = [], value = [], onChange = () => {} }) => {
  const [opened, setOpened] = useState(false);

  const dataSlice = () => {
    const dataSlice = {};

    data?.map((i) => {
      dataSlice[i.value] = i.label;
      return null;
    });
    const tmp = value.map((item) => {
      return dataSlice[item];
    });

    return tmp;
  };
  return (
    <>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        target={
          <MultiSelect
            onClick={() => setOpened((o) => !o)}
            data={[]}
            placeholder={value?.length ? dataSlice().join(' , ') : 'ประเภท'}
            label="ประเภท"
            // classNames={{ input: classes.input }}
            styles={{
              root: {
                [`input`]: {
                  [`::placeholder`]: {
                    color: '#000',
                  },
                },
              },
            }}
          />
        }
        position="bottom"
        width={400}
        sx={{ width: '100%' }}
      >
        <ScrollArea style={{ height: 250 }}>
          <Stack>
            {data.map((item, index) => {
              return (
                <Box key={`select-dropdown-checkbox-item-${index}`}>
                  <Group
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (!value.includes(item.value)) {
                        value.push(item.value);
                        onChange(value);
                        setOpened(false);
                        return;
                      }
                      const index = value.indexOf(item.value);
                      if (index > -1) {
                        // only splice array when item is found
                        value.splice(index, 1); //
                        onChange(value);
                        setOpened(false);
                      }
                    }}
                  >
                    <Checkbox checked={value.includes(item.value)} readOnly /> <Text>{item.label}</Text>
                  </Group>
                </Box>
              );
            })}
          </Stack>
        </ScrollArea>
      </Popover>
    </>
  );
};

export default SelectCheckbox;
