import React, { useState } from 'react';
import { Box, Button, Container, Image, Modal, Text } from '@mantine/core';
import { createTable } from '@tanstack/react-table';

import { ReactComponent as Trash } from '../../../assets/icon/trash.svg';
import { ReactComponent as Pencil } from '../../../assets/icon/pencil.svg';
import { ReactComponent as Plus } from '../../../assets/icon/Plus.svg';

import DialogCardTable from '../../UI/Card/DaialogCardTable';
import UploadPic, { getImageName } from './UploadPic';
import { useLogicImage } from '../../../api/presenter/useLogicImage';
import TableGlobal from '../../Table/TableGlobal';
import { useLogicName } from '../../../api/presenter/useLogicMessage';
import DelLogicForm from '../AllLogic/DelLogicForm';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const LogicTitle = ({ id, ...props }) => {
  const { title } = useLogicName({ id });
  return (
    <Text className="text" {...props}>
      {title}
    </Text>
  );
};

export default function Allpic() {
  const [modal, setmodal] = useState(false);
  const [imageModal, setimageModal] = useState(false);

  const [imageData, setimageData] = useState('');

  const { data, dispatch, mutate } = useLogicImage();

  const MySwal = withReactContent(Swal);
  const [activeModalData, setactiveModalData] = useState({});

  const columns = [
    {
      name: 'รูปภาพ',

      width: '360px',
      selector: (row) => (
        <Text
          px={0}
          onClick={() => {
            setimageData(row?.value);
            setimageModal(true);
          }}
          sx={{ cursor: 'pointer', userSelect: 'none' }}
        >
          {getImageName(row.value) || ''}
        </Text>
      ),
    },
    {
      name: 'ชื่อ Logic',

      width: '960px',
      selector: (row) => <LogicTitle id={row.logicID} />,
    },
    {
      name: '',
      right: true,
      selector: (row) => (
        <>
          <div className="flex justify-end space-x-2">
            <Button
              classNames={{
                root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
                label: '',
                icon: 'mr-0',
              }}
              styles={{
                leftIcon: {
                  marginRight: 15,
                },
              }}
              leftIcon={<Pencil />}
              onClick={() => {
                setactiveModalData(row);
                setmodal(true);
              }}
            />
            <DelLogicForm
              label={`ต้องการลบ Message : ${getImageName(row.value)}`}
              icon={<Trash />}
              onConfirm={() => {
                dispatch('delete', { id: row.logicMessageID })
                  .then(() => {
                    mutate();
                    MySwal.fire({
                      icon: 'success',
                      title: 'ลบ Logic Message สำเร็จ!',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch((err) => {
                    MySwal.fire({
                      icon: 'error',
                      title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  });
              }}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="mt-8">
        <Modal centered opened={modal} onClose={() => setmodal(false)} closeOnClickOutside={false}>
          <UploadPic
            actions={{
              cancel: () => setmodal(false),
              create: (ev) => {
                dispatch('create', ev)
                  .then(() => {
                    mutate();
                    setmodal(false);
                    MySwal.fire({
                      icon: 'success',
                      title: 'สร้าง Logic Message สำเร็จ!',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch((err) => {
                    MySwal.fire({
                      icon: 'error',
                      title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  });
              },
              update: (ev) => {
                dispatch('update', ev)
                  .then(() => {
                    mutate();
                    setmodal(false);
                    MySwal.fire({
                      icon: 'success',
                      title: 'อัพเดท Logic Message สำเร็จ!',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch((err) => {
                    MySwal.fire({
                      icon: 'error',
                      title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  });
              },
            }}
            row={activeModalData}
            reset={() => setactiveModalData({})}
          />
        </Modal>
      </div>
      <Button
        classNames={{
          root: 'z-40 bg-primary hover:bg-primary-600 active:bg-primary rounded-full text-black',
          label: 'text',
          icon: 'mr-0',
        }}
        styles={{
          leftIcon: {
            marginRight: 15,
          },
        }}
        leftIcon={<Plus />}
        onClick={() => setmodal(true)}
      >
        &nbsp;&nbsp;เพิ่มรูปภาพ
      </Button>
      <Box className="mt-8">
        <TableGlobal columns={columns} data={data} />
      </Box>
      <Modal
        opened={imageModal}
        onClose={() => setimageModal(false)}
        withCloseButton={false}
        centered
        overlayOpacity={0.65}
        styles={{ modal: { padding: '0 !important', borderRadius: 8 } }}
      >
        <Image src={imageData} radius="md" />
      </Modal>
    </div>
  );
}
