import { editIdea } from '@api/idea/idea.service';
import { Button, Modal } from '@mantine/core';
import { IdeaDetailRes } from '@models/IdeaDatabase.type';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EditIdeaForm from './components/EditIdeaForm';

const MySwal = withReactContent(Swal);

const EditIdeaPage = () => {
  const { id } = useParams<{ id: string }>();
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [formValues, setFormValues] = useState<IdeaDetailRes | null>(null);

  const initialValues: IdeaDetailRes = {
    _id: undefined,
    ownerId: undefined,
    title: '',
    description: '',
    requestOpinion: false,
    tags: [],
    images: [],
    files: [],
    templateType: null,
    content: '',
    isDraft: false,
    type: '',
    creditTo: null,
    purposes: '',
    process: '',
    consequence: '',
    suggestion: '',
    ideaCollection: null,
  };

  const handleEditIdea = (values: IdeaDetailRes, successMessage: string) => {
    const { _id } = values;
    if (_id) {
      editIdea(_id, values).subscribe({
        next: () => {
          MySwal.fire({
            icon: 'success',
            title: successMessage,
            showConfirmButton: false,
            timer: 2000,
          });
        },
        error: () => {
          MySwal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
            showConfirmButton: false,
            timer: 1500,
          });
        },
      });
    }
  };

  const handleSubmit = (values: IdeaDetailRes) => {
    setFormValues(values);
    setOpenModalSubmit(true);
  };

  const confirmSubmit = () => {
    if (formValues) {
      handleEditIdea(formValues, 'แก้ไอเดียสำเร็จแล้ว!');
      setOpenModalSubmit(false);
    }
  };

  return (
    <div className="container mx-auto my-8">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <EditIdeaForm ideaId={id} />
          </Form>
        )}
      </Formik>

      <Modal
        centered
        opened={openModalSubmit}
        radius="md"
        onClose={() => setOpenModalSubmit(false)}
        title={<h1 className="text-3xl text-error">ยืนยันการแก้ไขไอเดีย?</h1>}
        styles={{ title: { display: 'flex', justifyContent: 'center', width: '100%' } }}
      >
        <div className="mt-4 flex justify-end">
          <Button variant="outline" className="mr-4 rounded-full px-4 text-lg font-normal text-black no-underline" onClick={confirmSubmit}>
            ตกลง
          </Button>
          <Button
            variant="outline"
            color="red"
            className="rounded-full px-4 text-lg font-normal text-black no-underline hover:bg-red-100"
            onClick={() => setOpenModalSubmit(false)}
          >
            ยกเลิก
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default EditIdeaPage;
