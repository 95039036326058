import InsUserDatabaseFilter, {
  InsUserDatabaseDispatch,
  InsUserDatabaseAction,
  InsUserDatabaseActionType,
} from '@models/InsUserDatabase.type';
import React, { useReducer } from 'react';

interface InsUserDatabaseProviderProps {
  children: React.ReactNode;
}

const InsUserDatabaseContext = React.createContext<InsUserDatabaseFilter | undefined>(undefined);
const InsUserDatabaseDispatchContext = React.createContext<InsUserDatabaseDispatch | undefined>(undefined);

const reducer = (state: InsUserDatabaseFilter, { type, payload }: InsUserDatabaseAction): InsUserDatabaseFilter => {
  switch (type) {
    case InsUserDatabaseActionType.SET_TEXT_SEARCH: {
      return { ...state, textSearch: payload };
    }

    case InsUserDatabaseActionType.SET_EMAIL: {
      return { ...state, email: payload };
    }

    case InsUserDatabaseActionType.SET_SORT: {
      return { ...state, sort: payload };
    }

    case InsUserDatabaseActionType.SET_CURRENT_PACKAGE: {
      return { ...state, currentPackage: payload };
    }

    case InsUserDatabaseActionType.SET_EVER_BOUGHT_PACKAGE: {
      return { ...state, everBoughtPackage: payload };
    }

    case InsUserDatabaseActionType.SET_STATUS: {
      return { ...state, status: payload };
    }

    case InsUserDatabaseActionType.SET_OCCUPATION: {
      return { ...state, occupation: payload };
    }

    case InsUserDatabaseActionType.SET_START_DATE: {
      return { ...state, startDate: payload };
    }

    case InsUserDatabaseActionType.SET_END_DATE: {
      return { ...state, endDate: payload };
    }

    case InsUserDatabaseActionType.SET_PAGE: {
      return { ...state, pages: payload };
    }

    case InsUserDatabaseActionType.CLICK_SEARCH: {
      return { ...state, isSearch: payload };
    }

    case InsUserDatabaseActionType.DATES: {
      return { ...state, dates: payload };
    }

    case InsUserDatabaseActionType.CLEAR: {
      return new InsUserDatabaseFilter();
    }

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const InsDatabaseProvider: React.FC<InsUserDatabaseProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, new InsUserDatabaseFilter());

  return (
    <InsUserDatabaseContext.Provider value={state}>
      <InsUserDatabaseDispatchContext.Provider value={dispatch}>{children}</InsUserDatabaseDispatchContext.Provider>
    </InsUserDatabaseContext.Provider>
  );
};

const useInsUserDatabaseState = (): InsUserDatabaseFilter => {
  const context = React.useContext(InsUserDatabaseContext);
  if (context === undefined) {
    throw new Error('useInsUserDatabaseState must be used within a InsUserDatabaseProvider');
  }
  return context;
};

const useInsUserDatabaseDispatch = (): InsUserDatabaseDispatch => {
  const context = React.useContext(InsUserDatabaseDispatchContext);
  if (context === undefined) {
    throw new Error('useInsUserDatabaseDispatch must be used within a InsUserDatabaseProvider');
  }
  return context;
};

export { InsDatabaseProvider, useInsUserDatabaseState, useInsUserDatabaseDispatch };
