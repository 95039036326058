import { API } from '../../services/API';
import AddCuponForm from './CuponForm/AddCuponForm';
import { Box } from '@mantine/core';
import DelCuponForm from './CuponForm/DelCuponForm';
import DialogCard from '../UI/Card/DaialogCard';
import DialogCardTable from '../UI/Card/DaialogCardTable';
import { ReactComponent as Pencil } from '../../assets/icon/pencil.svg';
import { ReactComponent as Plus } from '../../assets/icon/Plus.svg';
import React from 'react';
import Swal from 'sweetalert2';
import Table from '../UI/Table/Table';
import { ReactComponent as Trash } from '../../assets/icon/trash.svg';
import { createTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCouponList } from '../../api/presenter/useCoupon';
import withReactContent from 'sweetalert2-react-content';
// import { API } from "../../services/API";
export default function CuponSetting() {
  const [elements, setElements] = React.useState([]);

  const MySwal = withReactContent(Swal);

  const { data, packages, dispatch, mutate } = useCouponList();

  const packageMap =
    (() => {
      const tmp = {};
      packages.map((i) => {
        tmp[i.value] = i.label;
        return null;
      });
      return tmp;
    })() || {};

  const cuponListFetch = () => {
    API.cupon.list().then((res) => {
      setElements(res);
    });
  };

  const table = createTable().setRowType();
  const defaultColumns = table.createColumns([
    table.createDataColumn((row) => row.expiredAt, {
      id: 'expiredAt',
      cell: (info) => dayjs(info.value).format('DD/MM/YY | HH:mm'),
      header: () => <span>หมดอายุ</span>,
    }),
    table.createDataColumn((row) => row.code, {
      id: 'code',
      cell: (info) => info.value,
      header: () => <span>คูปอง</span>,
    }),
    table.createDataColumn((row) => row.packageIDs, {
      id: 'packageIDs',
      cell: (info) => <Box>{info.value.map((i) => packageMap[i]).join(' , ')}</Box>,
      header: () => <span>ประเภท</span>,
    }),
    table.createDataColumn((row) => `${row.discountPercent}`, {
      id: 'discountPercent',
      cell: (info) => info.value,
      header: () => <span>ส่วนลด (%)</span>,
    }),
    table.createDataColumn((row) => row.discountValue, {
      id: 'discountValue',
      cell: (info) => info.value,
      header: () => <span>ส่วนลด (บาท)</span>,
    }),
    table.createDataColumn((row) => row.maxMember, {
      id: 'maxMember',
      cell: (info) => (
        <>
          {info.row.original.currentMember || 0} / {info.value}
        </>
      ),
      header: () => <span>จำนวนคูปอง</span>,
    }),
    table.createDataColumn((row) => row.id, {
      id: 'id',
      cell: (info) => (
        <div className="flex justify-end space-x-2">
          {/* <DialogCardTable
            buttonIcon={<Pencil />}
            modalTitile="แก้ไขคูปอง"
            setOpened={setOpenedEdit}
            opened={openedEdit && info.row.index === openedRowEdit}
            row={info.row}
            setOpenedRow={setOpenedRowEdit}
          >
            <AddCuponForm
              values={info.row.values}
              handleSubmit={handleEditCupon}
              setOpened={() => {
                setOpenedEdit(false);
              }}
            ></AddCuponForm>
          </DialogCardTable> */}
          {/* <AddCuponForm values={info.row.values}></AddCuponForm> */}
          <AddCuponForm
            values={info.row.original}
            Dialog={DialogCardTable}
            buttonIcon={<Pencil />}
            modalTitile="แก้ไขคูปอง"
            handleSubmit={handleEditCupon}
            fetch={cuponListFetch}
            isUpdate
          ></AddCuponForm>

          <DelCuponForm
            values={info.row.original}
            handleSubmit={() => {
              dispatch('delete', { couponID: info.row.original.couponID })
                .then((res) => {
                  MySwal.fire({
                    icon: 'success',
                    title: 'แก้ไข Logic สำเร็จ!',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  mutate();
                })
                .catch((err) => {
                  MySwal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                });
            }}
          ></DelCuponForm>
        </div>
      ),
      header: () => <span></span>,
    }),
  ]);

  return (
    <>
      <div className="text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">ตั้งค่าคูปอง</div>
      <div className="mt-8">
        {/* <DialogCard setOpened={setOpenedAdd} opened={openedAdd}> */}
        <AddCuponForm Dialog={DialogCard} buttonTitle="เพิ่มคูปอง" modalTitile="เพิ่มคูปอง" buttonIcon={<Plus />} fetch={cuponListFetch} />
        {/* </DialogCard> */}
      </div>
      <Box className="mt-8">
        <Table elements={data} defaultColumns={defaultColumns} />
      </Box>
    </>
  );
}

function handleDelCupon(value) {
  console.log({ value });
}

function handleEditCupon(value) {
  console.log({ value });
}
