import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { ommitNull, paramsBuilder } from '../../utils/utils';
import axios from '../axios';

const fetcher = (url, data = {}) => axios.post(url, data).then((res) => res.data);

const getData = (data, items) => {
  return axios.post('/idea/list', data).then((res) => {
    items = [...items, ...res?.data?.data?.items];

    if (res?.data?.data?.items?.length === 9000) {
      return getData({ ...data, page: data.page + 1 }, items);
    } else {
      return items;
    }
  });
};

export const useIdeaDataTable = (options) => {
  const [isLoading, setisLoading] = useState(false);

  const query = ommitNull({
    page: 1,
    perPage: 20,
    startCreatedAt: options?.startCreatedAt || null,
    endCreatedAt: options?.endCreatedAt || null,
    startUpdatedAt: options?.startUpdatedAt || null,
    endUpdatedAt: options?.endUpdatedAt || null,
    ideaHiddenTags: options?.hiddenTagFilter?.length ? options?.hiddenTagFilter : null,
    ideaUserTags: options?.userTagFilter,
    searchText: options?.search?.length ? options?.search : null,
    isHighlight: options?.isHighlight,
    inskruSelected: options?.InskruSelected,
    orderby: options?.orderby,
  });

  const { data, mutate } = useSWR(
    paramsBuilder('/idea/list', query),
    async (url) => {
      setisLoading(true);
      let items = [];
      const result = await getData(
        {
          ...query,
          page: 1,
          perPage: 9000,
        },
        items
      );
      setisLoading(false);
      return result?.filter((item) => !item.isDraft);
    },
    {
      fallbackData: {},
      revalidateOnFocus: false,
    }
  );

  const mutation = () => {
    mutate();
    setisLoading(false);
  };

  return {
    data: data || [],
    mutate: mutation,
    isLoading,
  };
};
export const useHiddenTagList = () => {
  const { data, mutate } = useSWR('/idea/hidden-tag/list', (url) => axios.get(url).then((res) => res.data), {
    fallbackData: {},
  });
  return {
    data:
      data?.data?.items?.map((item) => ({
        value: item.hiddenTag,
        label: item.hiddenTag,
      })) || [],
    mutate,
  };
};

export const useHiddenTagActions = () => {
  /**
   *
   * @param {"create"|"delete"} action
   * @param {*} payload
   * @returns {Promise}
   */
  const actions = (action, payload) => {
    switch (action) {
      case 'create':
        return axios.post('/idea/hidden-tag/create', payload).then((res) => res.data);
      case 'delete':
        return axios.post('/idea/hidden-tag/delete', payload).then((res) => res.data);
      default:
        return new Promise((resolve) => {
          resolve();
        });
    }
  };
  return { actions };
};
