import React, { useEffect, useState } from 'react';
import BannerForm from './components/BannerForm';
import { useNavigate, useParams } from 'react-router-dom';
import { Banner } from '@models/Banner';
import bannersService from '@api/banners/banners.service';

export default function EditBanner(): React.JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [banner, setBanner] = useState<Banner>();

  useEffect(() => {
    if (!id) {
      navigate(`/banners/create`);
    } else {
      const bannerId = parseInt(id);
      bannersService.byId(bannerId).subscribe((resp: Banner) => {
        setBanner(resp);
      });
    }
  }, [id]);

  return <>{banner && <BannerForm data={banner} />}</>;
}
