import { getAccessToken } from '@services/token.service';
import axios from 'axios';

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/backoffice',
});

axiosPrivate.interceptors.request.use(async (config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export default axiosPrivate;
