import { DateRangePicker } from '@mantine/dates';
import { ReactComponent as Calendar } from '../../../assets/icon/calendar.svg';

export default function DateRange({ label, placeholder, ...props }) {
  return (
    <DateRangePicker
      label={label}
      styles={{
        calendarBase: { color: 'red' },
        calendarHeader: { color: 'black' },
        calendarHeaderControl: { color: 'gray' },
        calendarHeaderLevel: {
          color: 'black',
          fontSize: 'large',
        },
        day: {
          borderRadius: 20,
          ':hover': {
            backgroundColor: '#FFCF25 !important',
            borderRadius: 20,
          },
          ':active': {
            backgroundColor: '#FFCF25 !important',
            borderRadius: 20,
          },
          ':focus': {
            backgroundColor: '#FFCF25 !important',
            borderRadius: 20,
            color: 'black  ',
          },
        },
      }}
      placeholder={placeholder}
      firstDayOfWeek="sunday"
      initiallyOpened={false}
      amountOfMonths={2}
      icon={<Calendar />}
      {...props}
    />
  );
}
