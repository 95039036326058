import { Box, Button, Group, Modal } from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';

import AddAdminForm from './AdminUI/AdminForm/AddAdminForm';
import DelAdminForm from './AdminUI/AdminForm/DelAdminForm';
import DialogCard from '../../UI/Card/DaialogCard';
import DialogCardTable from '../../UI/Card/DaialogCardTable';
import { ReactComponent as Pencil } from '../../../assets/icon/pencil.svg';
import { ReactComponent as Plus } from '../../../assets/icon/Plus.svg';
import Swal from 'sweetalert2';
import Table from '../../UI/Table/Table';
import { ReactComponent as Trash } from '../../../assets/icon/trash.svg';
import { createTable } from '@tanstack/react-table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useForm } from '@mantine/form';
import { useManageAdmin } from '../../../api/presenter/useManageAdmin';
import useUser from '../../../hooks/use-user';
import withReactContent from 'sweetalert2-react-content';

const ADMIN_LIST_URL = 'admin/list';

export default function AccountnManage() {
  const { data, mutate, dispatch } = useManageAdmin();
  const [opened, setopened] = useState(false);
  const [password, setpassword] = useState('');
  const [isUpdate, setisUpdate] = useState(false);

  const MySwal = withReactContent(Swal);

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      password2: '',
      imageURL: '',
      isSuperAdmin: 'admin',
      adminID: 0,
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (v) => (v ? null : true),
      password2: (v) => (v === password ? null : 'Password Do not match'),
    },
  });

  const resetForm = () => {
    form.setValues({
      email: '',
      password: '',
      password2: '',
      imageURL: '',
      isSuperAdmin: 'admin',
      adminID: 0,
    });
  };

  const axiosPrivate = useAxiosPrivate();

  const { user, loading, loggedOut } = useUser();
  // const [data, setData] = useState(() => []);
  // useEffect(() => {
  //   if (user && !loggedOut) {
  //     fetch();
  //   }
  // }, [user, loggedOut]);

  // const fetch = async () => {
  //   const response = await axiosPrivate.get(ADMIN_LIST_URL);
  //   setData(response.data.data.items);
  // };

  let profile = null;
  if (loading) {
    profile = 'loading...';
    console.log(profile);
  }

  const table = createTable().setRowType().setTableMetaType();
  // Get our table generics

  const defaultColumns = table.createColumns([
    table.createDataColumn((row) => row.email, {
      id: 'email',
      cell: (info) => info.value,
      header: () => <span>Email</span>,
    }),
    table.createDataColumn((row) => row.isSuperAdmin, {
      id: 'position',
      cell: (info) => <>{info.value ? 'Super Admin' : 'Admin'}</>,
      header: () => <span>ตำแหน่ง</span>,
    }),
    table.createDataColumn((row) => row.id, {
      id: 'id',
      cell: (info) => (
        <div className="flex justify-end space-x-2">
          <Button
            classNames={{
              root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
              label: '',
              icon: 'mr-0',
            }}
            styles={{
              leftIcon: {
                marginRight: 15,
              },
            }}
            disabled={user.role !== 1 ? true : false}
            leftIcon={<Pencil />}
            onClick={() => {
              const values = info.row.original;
              form.setValues({
                ...form.values,
                email: values.email,
                isSuperAdmin: values.isSuperAdmin ? 'superadmin' : 'admin',
                adminID: values.adminID,
              });
              setisUpdate(true);
              setopened(true);
            }}
          ></Button>
          <DialogCardTable
            buttonIcon={<Trash />}
            modalTitile="แก้ไขบัญชีผู้ดูแลระบบ"
            status={user.role !== 1 ? true : false}
            onSubmit={(closeModal) => {
              dispatch('delete', { adminID: info.row.original.adminID })
                .then((res) => {
                  mutate();
                  setopened(false);
                  MySwal.fire({
                    icon: 'success',
                    title: 'ลบ บัญชีผู้ดูแลระบบ สำเร็จ!',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                })
                .catch((err) => {
                  setopened(false);
                  MySwal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                });
              closeModal(false);
            }}
          >
            <DelAdminForm value={info.row.original}></DelAdminForm>
          </DialogCardTable>
        </div>
      ),
      header: () => <span></span>,
    }),
  ]);
  return (
    <div>
      <div className="text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">จัดการบัญชีผู้ดูแลระบบ</div>
      {/* <AdminTagSearch></AdminTagSearch> */}
      <div className="mt-8">
        <Modal
          classNames={{
            title: 'text-base font-semibold text-black-900 lg:text-xl dark:text-black',
            header: 'relative flex justify-center content-center text-center text-black dark:border-gray-600',
            close: 'absolute inset-y-0 right-0 mt-1',
          }}
          closeButtonLabel="Close authentication modal"
          opened={opened}
          onClose={() => {
            resetForm();
            setisUpdate(false);
            setopened(false);
          }}
          title={'เพิ่มบัญชีผู้ดูแลระบบ'}
          radius="md"
        >
          <AddAdminForm form={form} setpassword={setpassword}></AddAdminForm>
          <Group position="right" mt="xl">
            <Button
              classNames={{
                root: 'z-40 border rounded-full bg-transparent  hover:bg-transparent border-black text-black',
                label: '',
              }}
              type="button"
              onClick={() => setopened(false)}
            >
              ยกเลิก
            </Button>
            <Button
              classNames={{
                root: 'z-40 border rounded-full bg-primary hover:bg-primary-600 active:bg-primary text-black',
                label: '',
              }}
              type="button"
              onClick={() => {
                const { hasErrors } = form.validate();
                const values = form.values;
                if (!hasErrors) {
                  if (isUpdate) {
                    dispatch('update', {
                      adminID: values.adminID,
                      email: values.email,
                      password: values.password,
                      imageURL: '',
                      isSuperAdmin: values.isSuperAdmin === 'superadmin' ? true : false,
                    })
                      .then((res) => {
                        mutate();
                        resetForm();
                        setopened(false);
                        MySwal.fire({
                          icon: 'success',
                          title: 'สร้าง บัญชีผู้ดูแลระบบ สำเร็จ!',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      })
                      .catch((err) => {
                        resetForm();
                        setopened(false);
                        MySwal.fire({
                          icon: 'error',
                          title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      });
                  } else {
                    dispatch('create', {
                      email: values.email,
                      password: values.password,
                      imageURL: '',
                      isSuperAdmin: values.isSuperAdmin === 'superadmin' ? true : false,
                    })
                      .then((res) => {
                        mutate();
                        resetForm();
                        setopened(false);
                        MySwal.fire({
                          icon: 'success',
                          title: 'สร้าง บัญชีผู้ดูแลระบบ สำเร็จ!',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      })
                      .catch((err) => {
                        resetForm();
                        setopened(false);
                        MySwal.fire({
                          icon: 'error',
                          title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      });
                  }
                }
              }}
            >
              ยืนยัน
            </Button>
          </Group>
        </Modal>
        <Group position="start">
          <Button
            classNames={{
              root: 'z-40 bg-primary hover:bg-primary-600 active:bg-primary rounded-full text-black',
              label: '',
              icon: 'mr-0',
            }}
            styles={{
              leftIcon: {
                marginRight: 15,
              },
            }}
            disabled={user.role !== 1 ? true : false}
            leftIcon={<Plus />}
            onClick={setopened}
          >
            เพิ่มบัญชีผู้ดูแลระบบ
          </Button>
        </Group>
        {/* <DialogCard
          buttonTitle="เพิ่มบัญชีผู้ดูแลระบบ"
          modalTitile="เพิ่มบัญชีผู้ดูแลระบบ"
          buttonIcon={<Plus />}
          disabled={user.role !== 1 ? true : false}
          onSubmit={() => {}}
          onCloseModal={() => {
            form.setValues({
              email: "",
              password: "",
              password2: "",
              imageURL: "",
              isSuperAdmin: "admin",
            });
          }}
        ></DialogCard> */}
      </div>
      <Box className="mt-8">
        <Table elements={data} defaultColumns={defaultColumns} />
      </Box>
    </div>
  );
}
