import { Badge, Button, Group, Modal, MultiSelect, Select, Space, Stack, Text, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useCondition, useHiddenTags, useReflection } from '../../../api/presenter/useLogic';

import Swal from 'sweetalert2';
import { ReactComponent as Trash } from '../../../assets/icon/trash.svg';
import produce from 'immer';
import { useForm } from '@mantine/form';
import withReactContent from 'sweetalert2-react-content';

export default function AddLogicForm({ value, buttonIcon, modalTitile, triggerType = 'button', triggerIcon = <></>, onCreate = () => {} }) {
  const { hiddentag } = useHiddenTags();
  const { reflection } = useReflection();
  const { condition } = useCondition();
  const [opened, setopened] = useState(false);

  const form = useForm({
    initialValues: {
      name: '',
      logicTypeID: 0,
      logicHiddenTags: [],
      logicKeys: [],
      logicGateID: 1,
    },
  });

  const MySwal = withReactContent(Swal);

  const addLogic = () => {
    return form.setFieldValue(
      'logicKeys',
      produce(form.getInputProps('logicKeys').value, (draft) => {
        draft.push({
          key: '',
          value: '',
          conditionTypeID: 1,
        });
      })
    );
  };

  const onFieldChange = (id, key, value, initial) => {
    const tmp = produce(initial || form.getInputProps('logicKeys').value, (draft) => {
      draft[id][key] = value;
    });
    return form.setFieldValue('logicKeys', tmp);
  };

  const removeLogic = (id) => {
    const tmp = produce(form.getInputProps('logicKeys').value, (draft) => {
      draft.splice(id, 1);
    });
    return form.setFieldValue('logicKeys', tmp);
  };

  const [formErrors, setformErrors] = useState({
    name: false,
    logicTypeID: false,
  });

  const shoudCheckValue = (type) => {
    console.log(type);
    if (type === 3 || type === '3') return false;
    if (type === 4 || type === '3') return false;
    return true;
  };

  const validator = (value) => {
    return new Promise((resolve, reject) => {
      let isErr = false;
      const errors = {
        logicKeys: value.logicKeys.map((item) => ({
          key: false,
          value: false,
        })),
      };
      if (!value?.name?.length) {
        errors.name = true;
        isErr = true;
      }
      if (!value?.logicTypeID) {
        errors.logicTypeID = true;
        isErr = true;
      }
      // if (!value?.logicHiddenTags?.length) {
      //   errors.logicHiddenTags = true;
      //   isErr = true;
      // }
      if (!value?.logicKeys?.length) {
        MySwal.fire({
          icon: 'error',
          title: 'กรุณาเลือก Condition',
          showConfirmButton: false,
          timer: 1500,
        });
        isErr = true;
      }
      value?.logicKeys?.map((condition, idx) => {
        if (!condition?.key?.length) {
          errors.logicKeys[idx].key = true;
          isErr = true;
        }
        if (!shoudCheckValue(condition?.conditionTypeID)) return null;
        if (!condition?.value?.length) {
          errors.logicKeys[idx].value = true;
          isErr = true;
        }
        return null;
      });
      if (isErr) {
        reject(errors);
      } else {
        resolve();
      }
    });
  };

  useEffect(() => {
    if (!opened) {
      form.setValues({
        name: value?.name || '',
        logicTypeID: value?.logicTypeID || 0,
        logicHiddenTags: value?.hiddenTags?.map((item) => ({ hiddenTag: item })) || [],
        logicKeys:
          value?.logicKeys?.map((item) => ({
            logicKeyID: item?.logicKeyID,
            key: item?.key,
            value: item?.value,
            conditionTypeID: item?.conditionTypeID,
          })) || [],
        logicGateID: value?.logicGateID || 1,
        ...(() => {
          if (value?.logicID) return { logicID: value?.logicID };
        })(),
      });
    }
  }, [opened, value]);

  return (
    <>
      {triggerType === 'button' && (
        <Button
          classNames={{
            root: 'z-40 bg-primary hover:bg-primary-600 active:bg-primary rounded-full text-black',
            label: '',
            icon: 'mr-0',
          }}
          styles={{
            leftIcon: {
              marginRight: 15,
            },
          }}
          leftIcon={buttonIcon}
          onClick={setopened}
        >
          &#160;&#160;เพิ่ม Logic
        </Button>
      )}
      {triggerType === 'icon' && (
        <Button
          classNames={{
            root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
            label: '',
            icon: 'mr-0',
          }}
          styles={{
            leftIcon: {
              marginRight: 15,
            },
          }}
          leftIcon={triggerIcon}
          onClick={setopened}
        />
      )}
      <Modal
        opened={opened}
        onClose={() => setopened(false)}
        title={modalTitile}
        centered
        classNames={{
          title: 'text-base font-semibold text-black-900 lg:text-xl dark:text-black',
          header: 'relative flex justify-center content-center text-center text-black dark:border-gray-600',
          close: 'absolute inset-y-0 right-0 mt-1',
        }}
        radius="md"
        closeButtonLabel="Close authentication modal"
        closeOnClickOutside={false}
      >
        <form
          className=""
          onSubmit={form.onSubmit((value) => {
            validator(value)
              .then(() => {
                onCreate(value);
                setopened(false);
                form.setValues({
                  name: '',
                  logicTypeID: 1,
                  logicHiddenTags: value?.hiddentag?.map((item) => ({ hiddenTag: item })) || [],
                  logicKeys: [],
                });
              })
              .catch((err) => {
                setformErrors(err);
              });
          })}
        >
          <TextInput
            label="ชื่อ Logic"
            placeholder="ตั้งชื่อ logic"
            {...form.getInputProps('name')}
            radius={8}
            onChange={(ev) => {
              if (formErrors?.name) {
                setformErrors(
                  produce(formErrors, (draft) => {
                    draft.name = false;
                  })
                );
              }
              form.setFieldValue('name', ev.target.value);
            }}
            classNames={{
              label: `tracking-wide ${formErrors?.name ? 'text-red-500' : ''}`,
            }}
            error={formErrors?.name}
          />
          <Select
            label="Reflection"
            data={reflection}
            value={form.getInputProps('logicTypeID')?.value?.toString()}
            onChange={(ev) => {
              if (formErrors?.logicTypeID) {
                setformErrors(
                  produce(formErrors, (draft) => {
                    draft.logicTypeID = false;
                  })
                );
              }
              form.setFieldValue('logicTypeID', parseInt(ev));
            }}
            radius={8}
            classNames={{
              label: `tracking-wide ${formErrors?.logicTypeID ? 'text-red-500' : ''}`,
            }}
            placeholder="เลือก"
            error={formErrors?.logicTypeID}
          />
          <MultiSelect
            data={hiddentag}
            value={form.getInputProps('logicHiddenTags')?.value?.map((item) => item.hiddenTag)}
            onChange={(ev) => {
              if (formErrors?.logicHiddenTags) {
                setformErrors(
                  produce(formErrors, (draft) => {
                    draft.logicHiddenTags = false;
                  })
                );
              }
              form.setFieldValue(
                'logicHiddenTags',
                ev?.map((item) => ({ hiddenTag: item }))
              );
            }}
            label="Hidden Tag"
            placeholder="เลือก Hidden Tag ที่ใช้ผูกกับ Logic นี้"
            radius={8}
            classNames={{
              label: `tracking-wide ${formErrors?.logicHiddenTags ? 'text-red-500' : ''}`,
            }}
            error={formErrors?.logicHiddenTags}
            searchable
          />
          <Text className="mt-1">Condition</Text>

          <Group noWrap sx={{ justifyContent: 'center' }}>
            <Badge
              color={form.getInputProps('logicGateID').value === 1 ? 'yellow' : 'dark'}
              size="lg"
              variant="outline"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                form.setFieldValue('logicGateID', 1);
              }}
            >
              And
            </Badge>
            <Badge
              color={form.getInputProps('logicGateID').value === 2 ? 'yellow' : 'dark'}
              size="lg"
              variant="outline"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                form.setFieldValue('logicGateID', 2);
              }}
            >
              Or
            </Badge>
          </Group>
          <Space h={8} />
          <Stack>
            {form?.getInputProps('logicKeys')?.value.map((item, idx) => {
              return (
                <React.Fragment key={`react-logic-${idx}`}>
                  <Group spacing={4} noWrap>
                    <TextInput
                      label=""
                      value={item.key}
                      onChange={(ev) => {
                        if (formErrors?.logicKeys?.[idx]?.key) {
                          setformErrors(
                            produce(formErrors, (draft) => {
                              draft.logicKeys[idx].key = false;
                            })
                          );
                        }
                        onFieldChange(idx, 'key', ev.target.value);
                      }}
                      placeholder="กรอก Key จาก Paperform"
                      radius={8}
                      error={formErrors?.logicKeys?.[idx]?.key || false}
                    />
                    <Select
                      className=""
                      label=""
                      placeholder="is"
                      data={condition}
                      value={item.conditionTypeID.toString()}
                      onChange={(ev) => {
                        if (!shoudCheckValue(ev.toString())) {
                          const tmp = produce(form.getInputProps('logicKeys').value, (draft) => {
                            draft[idx].value = '';
                          });
                          return onFieldChange(idx, 'conditionTypeID', parseInt(ev), tmp);
                        }
                        return onFieldChange(idx, 'conditionTypeID', parseInt(ev));
                      }}
                      radius={8}
                    />
                    <TextInput
                      label=""
                      placeholder="Answer ..."
                      value={item.value}
                      onChange={(ev) => {
                        if (formErrors?.logicKeys?.[idx]?.value) {
                          setformErrors(
                            produce(formErrors, (draft) => {
                              draft.logicKeys[idx].value = false;
                            })
                          );
                        }
                        onFieldChange(idx, 'value', ev.target.value);
                      }}
                      error={
                        (formErrors?.logicKeys?.[idx]?.value &&
                          shoudCheckValue(form.getInputProps('logicKeys').value[idx].conditionTypeID)) ||
                        false
                      }
                      disabled={!shoudCheckValue(form.getInputProps('logicKeys').value[idx].conditionTypeID)}
                      radius={8}
                    />
                    <Button
                      classNames={{
                        root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
                        label: '',
                        icon: 'mr-0',
                      }}
                      styles={{
                        leftIcon: {
                          marginRight: 15,
                        },
                      }}
                      leftIcon={<Trash />}
                      onClick={() => {
                        removeLogic(idx);
                      }}
                    />
                  </Group>
                </React.Fragment>
              );
            })}
          </Stack>
          <Button
            classNames={{
              root: 'z-40 mt-2 border rounded-full bg-transparent  hover:bg-transparent border-black text-black border-blue',
              label: 'font-light',
            }}
            type="button"
            onClick={() => addLogic()}
          >
            + เพิ่ม Condition
          </Button>
          <Space h={16} />
          <Group sx={{ justifyContent: 'flex-end' }}>
            <Button
              classNames={{
                root: 'z-40 border rounded-full bg-transparent  hover:bg-transparent border-black text-black',
                label: '',
              }}
              type="button"
              onClick={() => setopened(false)}
            >
              ยกเลิก
            </Button>
            <Button
              classNames={{
                root: 'z-40 border rounded-full bg-primary hover:bg-primary-600 active:bg-primary text-black',
                label: '',
              }}
              type="submit"
            >
              ยืนยัน
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
}
