import React, { useEffect, useState } from 'react';
import FormNotification from '../components/form';
import { getNotificationById } from '@api/notification/notification.service';
import { useParams } from 'react-router-dom';
import { NotificationInsRes } from '@models/NotificationReq';

const EditNotification = (): React.JSX.Element => {
  const { id } = useParams();
  const [notification, setNotification] = useState<NotificationInsRes>();

  useEffect(() => {
    if (id) {
      const notiId = parseInt(id);
      getNotificationById(notiId).subscribe((resp: NotificationInsRes) => setNotification(resp));
    }
  }, [id]);

  return <>{notification && <FormNotification noti={notification} />}</>;
};

export default EditNotification;
