import React from 'react';
import { Anchor, Breadcrumbs, Box, Button } from '@mantine/core';
import { createTable } from '@tanstack/react-table';

import { ReactComponent as Trash } from '../../../../assets/icon/trash.svg';
import { ReactComponent as Pencil } from '../../../../assets/icon/pencil.svg';
import { ReactComponent as Plus } from '../../../../assets/icon/Plus.svg';

import Table from '../../../UI/Table/Table';
import DialogCard from '../../../UI/Card/DaialogCard';
import DialogCardTable from '../../../UI/Card/DaialogCardTable';
import UserIdUI from '../../../UI/User/UserId';
import SchoolUpload from '../../SchoolManage/SchoolUpload';
import AddSubSchoolForm from '../../SchoolManage/SubSchool/SubSchoolForm/AddSubSchoolForm';
import DelSubSchoolForm from '../../SchoolManage/SubSchool/SubSchoolForm/DelSubSchoolForm';
import SubSchoolMember from './SubSchoolMember';
import SubSchoolCodes from './SubSchoolCodes';

import LinkUI from '../../../UI/Link/LinkUI';
import { API } from '../../../../services/API';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

const SCHOOL_GROUP_DETAIL_URL = '/school-group/detail';

export default function Schoolmanage() {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const [schoolGroupDeail, setSchoolGroupDeail] = React.useState({
    affilition: '',
    name: '',
    province: '',
    schoolGroupID: '',
  });
  const items = [
    { title: 'แพ็คเกจกลุ่มโรงเรียน', href: 'schoolmanage' },
    { title: `โรงเรียน ${schoolGroupDeail.name}`, href: '#' },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  React.useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    try {
      const response = await axiosPrivate.post(SCHOOL_GROUP_DETAIL_URL, {
        schoolGroupID: +id,
      });
      setSchoolGroupDeail(response.data.data.item);
    } catch (error) {}
  };

  const table = createTable().setRowType();

  const defaultColumns = table.createColumns([
    table.createDataColumn((row) => row.startDate, {
      id: 'startDate',
      cell: (info) => info.value,
      header: () => <span>เริ่ม</span>,
    }),
    table.createDataColumn((row) => row.endDate, {
      id: 'endDate',
      cell: (info) => info.value,
      header: () => <span>หมดอายุ</span>,
    }),
    table.createDataColumn((row) => row.position, {
      id: 'position',
      cell: (info) => info.value,
      header: () => <span>ตำแหน่ง</span>,
    }),
    table.createDataColumn((row) => row.userid, {
      id: 'userid',
      cell: (info) => <UserIdUI userId={info.value} />,
      header: () => <span>userid</span>,
    }),
    table.createDataColumn((row) => row.userName, {
      id: 'userName',
      cell: (info) => info.value,
      header: () => <span>ชื่อ-นามสกุล</span>,
    }),
    table.createDataColumn((row) => row.userGoal, {
      id: 'userGoal',
      cell: (info) => <LinkUI href={`${process.env.REACT_APP_OLDSITE_URL}/profile/${info.value}`} />,
      header: () => <span>หน้าเป้าหมาย</span>,
    }),
    table.createDataColumn((row) => row.userJob, {
      id: 'userJob',
      cell: (info) => info.value,
      header: () => <span>อาชีพ</span>,
    }),
    table.createDataColumn((row) => row.userSchool, {
      id: 'userSchool',
      cell: (info) => info.value,
      header: () => <span>โรงเรียน</span>,
    }),
    table.createDataColumn((row) => row.provinces, {
      id: 'provinces',
      cell: (info) => info.value,
      header: () => <span>จังหวัด</span>,
    }),
    table.createDataColumn((row) => row.userSubject, {
      id: 'userSubject',
      cell: (info) => (
        <>
          <div className="flex max-w-xs flex-nowrap">
            <div>
              <Button className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline">
                {info.value}
              </Button>
            </div>
            <div>
              <Button className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline">
                {info.value}
              </Button>
            </div>
          </div>
        </>
      ),
      header: () => <span>วิชาที่สอน</span>,
    }),
    table.createDataColumn((row) => row.userClass, {
      id: 'userClass',
      cell: (info) => (
        <>
          <div className="flex max-w-xs flex-nowrap">
            <div>
              <Button className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline">
                {info.value}
              </Button>
            </div>
            <div>
              <Button className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline">
                {info.value}
              </Button>
            </div>
          </div>
        </>
      ),
      header: () => <span>ระดับชั้น</span>,
    }),
    table.createDataColumn((row) => row.id, {
      id: 'id',
      cell: (info) => (
        <div className="flex justify-end space-x-2">
          <DialogCardTable buttonIcon={<Pencil />} modalTitile="แก้ไขตำแหน่ง">
            <AddSubSchoolForm></AddSubSchoolForm>
          </DialogCardTable>
          <DialogCardTable buttonIcon={<Trash />} modalTitile="ลบ Membership">
            <DelSubSchoolForm></DelSubSchoolForm>
          </DialogCardTable>
        </div>
      ),
      header: () => <span></span>,
    }),
  ]);

  return (
    <div>
      <div className="mb-2">
        <Breadcrumbs separator=">">{items}</Breadcrumbs>
      </div>
      <div className="text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">โรงเรียน {schoolGroupDeail.name}</div>
      <p className="mt-4 max-w-2xl text-sm text-gray-500">
        สังกัด : {schoolGroupDeail.affilition} จังหวัด : {schoolGroupDeail.province}
      </p>
      {/* <div className="mt-8 inline-flex space-x-6">
        <DialogCard buttonTitle="เพิ่มบัญชีในกลุ่มนี้" modalTitile="เพิ่มบัญชีในกลุ่มนี้" buttonIcon={<Plus />}>
          <AddSubSchoolForm></AddSubSchoolForm>
        </DialogCard>
        <Button className="border rounded-full bg-transparent  hover:bg-transparent border-black text-black">
          ดูแดชบอร์ด
        </Button>
        <SchoolUpload></SchoolUpload>
      </div>
      <div className="mt-8 text-2xl">ผอ. / ฝ่ายวิชาการ</div>
      <div className="mt-2 mb-4 text-sm">จำนวน {elements.length} account</div> */}
      {/* Table */}
      {/* <Box className="mt-8">
        <Table elements={elements} defaultColumns={defaultColumns} />
      </Box> */}

      {/* All Member of school */}
      <SubSchoolMember></SubSchoolMember>
      <SubSchoolCodes></SubSchoolCodes>
    </div>
  );
}
