import React, { useEffect, useState } from 'react';
import { TextInput, Group, Button, Modal, Text } from '@mantine/core';

import Select from '../../../UI/Selector/Select';
import DatePickerWithLabel from '../../../UI/DatePickerRange/DatePickerWithLabel';

import { useForm } from '@mantine/form';
import { usePackageList } from '../../../../api/presenter/usePackage';

export default function AddMemberForm({ values, setOpened, handleSubmit, ...props }) {
  const form = useForm({
    initialValues: {
      id: values?.id || 0,
      oldID: values?.userid || 0,
      packageID: values?.package || 0,
    },

    validate: {
      oldID: (v) => (v ? null : true),
      packageID: (v) => (v ? null : true),
    },
  });

  useEffect(() => {
    if (!props.opened) {
      form.setValues({
        id: 0,
        oldID: 0,
        packageID: 0,
      });
    }
  }, [props.opened]);

  const { data: packageList } = usePackageList();

  return (
    <Modal {...props}>
      <Text align="center" component="h1" sx={{ fontSize: '1.25rem', lineHeight: '1.75rem', fontWeight: 'bold' }}>
        เพิ่ม Membership
      </Text>
      <form className="" onSubmit={form.onSubmit((v) => handleSubmit(v))}>
        <TextInput label="Userid" placeholder="ใส่เลข (ดูได้จากลิงก์หน้าโปรไฟล์)" {...form.getInputProps('oldID')} />
        {/* <DatePickerWithLabel
          label="กำหนดวันเริ่ม - วันหมดอายุ membership"
          placeholder="กำหนดวันเริ่ม - วันหมดอายุ"
        ></DatePickerWithLabel> */}
        <Select
          className="mt-1"
          label="เลือกแพ็คเกจ"
          placeholder="เลือกแพ็คเกจ"
          data={
            packageList.map((i) => ({
              label: i.name,
              value: `${i.packageID}`,
            })) || []
          }
          {...form.getInputProps('packageID')}
        ></Select>
        <Group position="right" mt="xl">
          <Button
            classNames={{
              root: 'z-40 border rounded-full bg-transparent  hover:bg-transparent border-black text-black',
              label: '',
            }}
            type="button"
            onClick={props.onClose}
          >
            ยกเลิก
          </Button>
          <Button
            classNames={{
              root: 'z-40 border rounded-full bg-primary hover:bg-primary-600 active:bg-primary text-black',
              label: '',
            }}
            type="submit"
          >
            ยืนยัน
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
