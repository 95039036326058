import React from 'react';
import { Menu, UnstyledButton } from '@mantine/core';
import useUser from '../../../../hooks/use-user';
import { logout } from '../../../../services/auth';
import { useNavigate } from 'react-router-dom';

import userIconSource from '../../../../assets/icon/userIcon.svg';

export const UserButton = React.forwardRef((props, ref) => (
  <UnstyledButton
    ref={ref}
    className="w-1/2 lg:w-8/12"
    sx={(theme) => ({
      display: 'flex',
      width: '100%',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
      },
    })}
    {...props}
  >
    <div className="avatar">
      <div className="w-10 rounded-full">
        <img src={userIconSource || props.userprofile.avatar} />
      </div>
    </div>
  </UnstyledButton>
));

const UserDropDown = ({ userprofile }) => {
  const nav = useNavigate();
  const { mutate } = useUser();

  return (
    <div>
      <Menu
        position="bottom"
        placement="end"
        gutter={10}
        closeOnItemClick={true}
        // zIndex={0}
      >
        <Menu.Target>
          <UserButton userprofile={userprofile} />
        </Menu.Target>
        {/* ...menu items */}
        <Menu.Dropdown>
          <Menu.Item
            onClick={() => {
              logout();
              mutate(null); // optimistically update the data and revalidate
              nav('/');
            }}
          >
            ออกจากระบบ
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default UserDropDown;
