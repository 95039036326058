import bannersService from '@api/banners/banners.service';
import { Button, FileButton, Group } from '@mantine/core';
import { UploadFileResp } from '@models/Banner';
import { IdeaDetailFile, IdeaDetailRes } from '@models/IdeaDatabase.type';
import { useFormikContext } from 'formik';
import _, { clone } from 'lodash';
import React, { useRef } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FilePreview from './FilePreview';

const MySwal = withReactContent(Swal);

const UPLOAD_MAX_SIZE_20MB = 2e7;

const isFileSizeGreaterThan20MB = (fileSize: number): boolean => {
  return _.gt(fileSize, UPLOAD_MAX_SIZE_20MB);
};

const FileUpload = (): JSX.Element => {
  const resetRef = useRef<() => void>(null);
  const { values, setFieldValue } = useFormikContext<IdeaDetailRes>();

  const handleChangeFile = (file: File): void => {
    if (isFileSizeGreaterThan20MB(file.size)) {
      Swal.fire({
        icon: 'error',
        title: 'ควรย่อไฟล์ให้ขนาดเล็กกว่า 20 MB',
      });
      return;
    }

    bannersService.uploadFile(file).subscribe({
      next: (resp: UploadFileResp) => {
        const ideaFile: IdeaDetailFile = {
          type: resp.fileType,
          size: resp.fileSize,
          name: file.name,
          url: resp.fileName,
          downloaded: 0,
        };

        const files = clone(values.files);
        files.push(ideaFile);
        setFieldValue('files', files);
        resetRef.current?.();
      },
      error: () => {
        MySwal.fire({
          icon: 'error',
          title: 'อัปโหลดไฟล์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          showConfirmButton: false,
          timer: 1500,
        });
        resetRef.current?.();
      },
    });
  };

  const onRemoveFile = (file: IdeaDetailFile): void => {
    const removed = values.files.filter((f) => f.url !== file.url);
    setFieldValue('files', removed);
  };

  return (
    <>
      <Group>
        <FileButton
          resetRef={resetRef}
          onChange={handleChangeFile}
          accept="application/pdf, image/png, image/jpeg, xls, xlsx, doc, docx, ppt, pptx"
        >
          {(props) => (
            <Button {...props} className="rounded-full bg-primary px-4 text-xl font-normal text-black no-underline hover:bg-primary-300">
              เลือกไฟล์
            </Button>
          )}
        </FileButton>
      </Group>

      <Group className="flex flex-wrap rounded-2xl border-2 border-dashed p-6">
        {values?.files?.map((file, index) => <FilePreview key={`preview-${file.name}-${index}`} file={file} onRemoveFile={onRemoveFile} />)}
      </Group>
    </>
  );
};

export default FileUpload;
