import { Button, Menu } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as MainLogo } from '../../../assets/icon/Group.svg';
import useUser from '../../../hooks/use-user';
import BuddyKruDropDown from './DropDown/BuddyKruDropDown';
import DatabaseDropDown from './DropDown/DatabaseDropDown';
import StatsDropDown from './DropDown/StatsDropDown';
import UserDropDown from './DropDown/UserDropDown';

interface MenuBurgerProps {
  menuOpen: boolean;
}

const MenuBurger = ({ menuOpen }: MenuBurgerProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`ease size-8 transition duration-100 ${menuOpen ? 'rotate-90' : ''}`}
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M3.75 12H20.25" stroke="#2D2E2E" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.75 6H20.25" stroke="#2D2E2E" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.75 18H20.25" stroke="#2D2E2E" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const MainNavbars = () => {
  const { user } = useUser();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div className="container mx-auto mb-4 flex items-center justify-between p-4">
        {/* Button */}
        <button
          type="button"
          aria-label="Toggle mobile menu"
          onClick={() => setMenuOpen(!menuOpen)}
          className="rounded focus:outline-none focus:ring focus:ring-gray-500 focus:ring-offset-gray-500 md:hidden"
        >
          <MenuBurger menuOpen={menuOpen} />
        </button>

        {/* App header */}
        <div className="flex gap-6">
          <div className="cursor-pointer">
            <Link to="/home">
              <MainLogo />
            </Link>
          </div>
          <nav className="hidden md:block">
            {/* {navLinks} */}
            <DatabaseDropDown />
            <StatsDropDown />
            <BuddyKruDropDown />

            <Menu radius="md">
              <Menu.Target>
                <Button
                  rightIcon={<IconChevronDown />}
                  className="z-40 bg-transparent px-4 py-2 text-xl font-normal text-black hover:bg-transparent"
                >
                  สำหรับ Admin
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Link to="/adminmanage">
                  <Menu.Item className="text-lg">จัดการบัญชีผู้ดูแลระบบ</Menu.Item>
                </Link>
                <Link to="notification/ins">
                  <Menu.Item className="text-lg">ตั้ง Noti ชาวอินส์ล่วงหน้า</Menu.Item>
                </Link>
                <Link to="notification/user">
                  <Menu.Item className="text-lg">ส่ง Noti ให้ Website’s User</Menu.Item>
                </Link>
                <Link to="banners">
                  <Menu.Item className="text-lg">จัดการ Banner</Menu.Item>
                </Link>
                <Link to="idea-collections">
                  <Menu.Item className="text-lg">จัดการ Idea Collection</Menu.Item>
                </Link>
              </Menu.Dropdown>
            </Menu>
          </nav>
        </div>

        {/* Right Items */}
        <div className="">
          <nav className="hidden text-lg md:block">
            <UserDropDown userprofile={user} />
          </nav>
        </div>
      </div>
    </>
  );
};
export default MainNavbars;
