import axiosGolang from '@api/axiosGolang';
import { CollectionCategory, CollectionPagination, IdeaCollection, IdeaCollectionSave } from '@models/IdeaCollection';
import { from, map, Observable } from 'rxjs';

const URL = '/backoffice/idea-collections';

const getCategories = (pages: number, sizes: number): Observable<CollectionPagination<CollectionCategory>> => {
  return from(axiosGolang.get(`${URL}/categories?pages=${pages}&sizes=${sizes}`)).pipe(map((d) => d.data));
};

const createCategory = (value: CollectionCategory): Observable<void> => {
  return from(axiosGolang.post(`${URL}/category`, value)).pipe(map((d) => d.data));
};

const updateCategory = (value: CollectionCategory): Observable<void> => {
  return from(axiosGolang.put(`${URL}/category/${value.id}`, value)).pipe(map((d) => d.data));
};

const deleteCategory = (id: number): Observable<void> => {
  return from(axiosGolang.delete(`${URL}/category/${id}`)).pipe(map((d) => d.data));
};

const getAllIdeaCollection = (): Observable<IdeaCollection[]> => {
  return from(axiosGolang.get(URL)).pipe(map((d) => d.data));
};

const saveIdeaCollection = (req: IdeaCollectionSave) => {
  return from(axiosGolang.post(URL, req)).pipe(map((d) => d.data));
};

const deleteIdeaCollection = (year: number): Observable<void> => {
  return from(axiosGolang.delete(`${URL}/${year}`)).pipe(map((d) => d.data));
};

export default {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  getAllIdeaCollection,
  saveIdeaCollection,
  deleteIdeaCollection,
};
