import { Button, Group, Text, TextInput, form } from '@mantine/core';

import { API } from '../../../services/API';
import DialogCardTable from '../../UI/Card/DaialogCardTable';
import React from 'react';
import { ReactComponent as Trash } from '../../../assets/icon/trash.svg';
import dayjs from 'dayjs';
import { useForm } from '@mantine/form';

export default function DelCuponForm({ values, fetch, handleSubmit }) {
  const form = useForm({
    initialValues: {
      id: values.id,
    },
  });
  return (
    <DialogCardTable buttonIcon={<Trash />} modalTitile="ลบคูปอง" onSubmit={handleSubmit}>
      <form>
        <div>
          การลบคูปองจะไม่มีผลกับผู้ที่ใช้คูปองนี้ในการสมัครก่อนหน้า หากลบแล้วจะไม่สามารถใช้รหัสคูปองนี้ในการสมัคร membership
          ในครั้งต่อไปได้อีก
        </div>
        <Text className="mt-4">รหัสคูปอง : {values.code}</Text>
        <Text className="mt-4">
          ส่วนลด :{' '}
          {values.couponDiscountTypeID === 1
            ? parseFloat(values.discountPercent)
              ? `${parseFloat(values.discountPercent)}%`
              : 0
            : values.couponDiscountTypeID === 2
            ? parseFloat(values.discountValue)
              ? `${parseFloat(values.discountValue)}%`
              : 0
            : 0}
        </Text>
        <Text className="mt-4">วันเริ่ม - วันหมดอายุ : {dayjs(values.expiredAt).format('DD/MM/YY | HH:mm')}</Text>
        <Text className="mt-4">จำนวนคูปอง : {values.maxMember}</Text>
        {/* <Group position="right" mt="xl">
          <Button
            classNames={{
              root: "z-40 border rounded-full bg-transparent  hover:bg-transparent border-black text-black",
              label: "",
            }}
            type="button"
            onClick={() => {
              setOpened(false);
            }}
          >
            ยกเลิก
          </Button>
          <Button
            classNames={{
              root: "z-40 border rounded-full bg-primary hover:bg-primary-600 active:bg-primary text-black",
              label: "",
            }}
            type="submit"
          >
            ยืนยัน
          </Button>
        </Group> */}
      </form>
    </DialogCardTable>
  );
}
