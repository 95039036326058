import React, { useState, useEffect, useRef } from 'react';

import useUser from '../../hooks/use-user';
import { login } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import { PasswordInput, TextInput, Button, Box } from '@mantine/core';
import useAuth from '../../hooks/useAuth';

import styled from 'styled-components';

import axios from '../../api/axios';
import { setAccessToken } from '@services/token.service';
const LOGIN_URL = '/admin/login';

const StyledLogin = styled(Button)`
  color: black;
`;

function Login() {
  const { setAuth, setCookie } = useAuth();

  const userRef = useRef();
  const errRef = useRef();

  const nav = useNavigate();
  const { user, mutate, loggedOut } = useUser();
  const [errMsg, setErrMsg] = useState('');
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const userChangeHandler = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(LOGIN_URL, userData);
      if (response?.data?.error?.message) {
        setErrMsg(response?.data?.error?.message);
        errRef.current.focus();
      } else {
        console.log(response.data.data.item);
        const accessToken = response?.data?.data?.item?.accessToken;
        const refreshToken = response?.data?.data?.item?.refreshToken;
        setAuth({ email: userData.email, refreshToken, accessToken });
        setCookie('accessToken', accessToken, { path: '/' });
        setCookie('refreshToken', refreshToken, { path: '/' });

        setAccessToken(accessToken);

        mutate(); // after logging in, we revalidate the SWR
        nav('../home', { replace: true });
        login();

        // nav("../home", { replace: true });
      }
      // setUser("");
      // setPwd("");
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400 || err.response?.status === 500) {
        setErrMsg('Missing Email or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    } finally {
      setLoading(false);
    }
  };

  // if logged in, redirect to the dashboard
  useEffect(() => {
    if (user && !loggedOut) {
      nav('../home', { replace: true });
    }
  }, [user, loggedOut, nav]);

  return (
    <div className="flex h-screen">
      <Box className="border-primaryBorder shadow-default m-auto w-96 rounded-lg border bg-white px-1 py-10">
        <div className="mb-1 text-center text-2xl text-black">ลงชื่อเข้าใช้</div>
        <form onSubmit={submitHandler} className="space-y-6 px-10">
          <TextInput
            name="email"
            type="email"
            value={userData.email}
            onChange={userChangeHandler}
            placeholder="กรอกอีเมล"
            label="อีเมล"
            required
          />
          <PasswordInput
            placeholder="กรอกรหัสผ่าน"
            label="รหัสผ่าน"
            name="password"
            required
            value={userData.password}
            onChange={userChangeHandler}
          />
          <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">
            {errMsg}
          </p>
          <div className="mt-3 flex items-center justify-center">
            <StyledLogin
              className="h-10 w-36 rounded-full bg-primary p-2 text-xl font-light hover:bg-primary-600 active:bg-primary"
              type="submit"
              value="login"
              loading={loading}
            >
              เข้าสู่ระบบ
            </StyledLogin>
          </div>
        </form>
      </Box>
    </div>
  );
}

export default Login;
