import ideaCollectionService from '@api/idea-collection/idea-collection.service';
import { Button, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { APIResponseError } from '@models/Error';
import { CollectionCategory } from '@models/IdeaCollection';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { finalize } from 'rxjs';
import Swal from 'sweetalert2';

interface ModalEditCollectionCategoryProps {
  collection: CollectionCategory | undefined;
  onCancel: () => void;
  onConfirm: () => void;
}

const ModalEditCollectionCategory = ({ collection, onCancel, onConfirm }: ModalEditCollectionCategoryProps): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const form = useForm<CollectionCategory>({
    initialValues: collection,
  });

  const handleSubmit = (value: CollectionCategory): void => {
    if (form.isValid()) {
      setSubmitting(true);

      if (value.id) {
        ideaCollectionService
          .updateCategory(value)
          .pipe(finalize(() => setSubmitting(false)))
          .subscribe({
            next: () => onConfirm(),
            error: (err: AxiosError<APIResponseError>) => {
              Swal.fire({
                icon: 'error',
                title: 'ไม่สามารถบันทึกได้',
                text: err.response?.data?.error?.message,
              });
            },
          });
      } else {
        ideaCollectionService
          .createCategory(value)
          .pipe(finalize(() => setSubmitting(false)))
          .subscribe({
            next: () => onConfirm(),
            error: (err: AxiosError<APIResponseError>) => {
              if (err.response?.data?.error?.message) {
                let text = err.response?.data?.error?.message;
                if (text.indexOf('duplicate key value violates unique constraint') >= 0) {
                  text = `${value.name} : มีอยู่แล้ว`;
                }
                Swal.fire({
                  icon: 'error',
                  title: 'ไม่สามารถบันทึกได้',
                  text,
                });
              }
            },
          });
      }
    }
  };

  return (
    <Modal
      centered
      opened={true}
      closeOnClickOutside={false}
      withCloseButton={false}
      onClose={() => onCancel()}
      title="จัดการหมวดหมู่ Collection"
    >
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))} className="grid gap-4">
        <TextInput
          withAsterisk
          label="ชื่อหมวดหมู่ Collection"
          placeholder="ชื่อ Collection ไม่ต้องใส่ปี คศ."
          {...form.getInputProps('name')}
        />

        <div className="flex justify-between">
          <Button
            variant="outline"
            color="red"
            className="rounded-full px-4 text-lg font-normal text-error no-underline hover:bg-red-100"
            onClick={() => onCancel()}
          >
            ยกเลิก
          </Button>
          <Button
            type="submit"
            className="rounded-full bg-primary px-4 text-lg font-normal text-black no-underline hover:bg-primary-300"
            disabled={!form.values.name || submitting}
          >
            ยืนยันข้อมูล
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalEditCollectionCategory;
