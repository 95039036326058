import IdeaDatabaseFilter, { IdeaDatabaseAction, IdeaDatabaseActionType, IdeaDatabaseDispatch } from '@models/IdeaDatabase.type';
import React, { useReducer } from 'react';

interface IdeaDatabaseProviderProps {
  children: React.ReactNode;
}

const IdeaDatabaseContext = React.createContext<IdeaDatabaseFilter | undefined>(undefined);
const IdeaDatabaseDispatchContext = React.createContext<IdeaDatabaseDispatch | undefined>(undefined);

const reducer = (state: IdeaDatabaseFilter, { type, payload }: IdeaDatabaseAction): IdeaDatabaseFilter => {
  switch (type) {
    case IdeaDatabaseActionType.SET_IDEA_TITLE: {
      return { ...state, ideaTitle: payload };
    }
    case IdeaDatabaseActionType.SET_ALIAS: {
      return { ...state, alias: payload };
    }
    case IdeaDatabaseActionType.SET_SORT: {
      return { ...state, sort: payload };
    }
    case IdeaDatabaseActionType.SET_OCCUPATION: {
      return { ...state, occupation: payload };
    }
    case IdeaDatabaseActionType.SET_PUBLISHED_DATE_START: {
      return { ...state, publishedDateStart: payload };
    }
    case IdeaDatabaseActionType.SET_PUBLISHED_DATE_END: {
      return { ...state, publishedDateEnd: payload };
    }
    case IdeaDatabaseActionType.SET_PAGE: {
      return { ...state, pages: payload };
    }
    case IdeaDatabaseActionType.SET_TEMPLATE_TYPE: {
      return { ...state, templateType: payload };
    }
    case IdeaDatabaseActionType.SET_INSKRU_SELECTED: {
      return { ...state, inskruSelected: payload };
    }
    case IdeaDatabaseActionType.SET_IS_RECOMMEND: {
      return { ...state, isRecommend: payload };
    }
    case IdeaDatabaseActionType.SET_USER_TAGS: {
      return { ...state, userTag: payload };
    }
    case IdeaDatabaseActionType.CLICK_SEARCH: {
      return { ...state, isSearch: payload };
    }
    case IdeaDatabaseActionType.DATES: {
      return { ...state, dates: payload };
    }
    case IdeaDatabaseActionType.CLEAR: {
      return new IdeaDatabaseFilter();
    }
    case IdeaDatabaseActionType.SET_TYPE_OF_IDEA: {
      return { ...state, typeOfIdea: payload };
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const IdeaDatabaseProvider: React.FC<IdeaDatabaseProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, new IdeaDatabaseFilter());

  return (
    <IdeaDatabaseContext.Provider value={state}>
      <IdeaDatabaseDispatchContext.Provider value={dispatch}>{children}</IdeaDatabaseDispatchContext.Provider>
    </IdeaDatabaseContext.Provider>
  );
};

const useIdeaDatabaseState = (): IdeaDatabaseFilter => {
  const context = React.useContext(IdeaDatabaseContext);
  if (context === undefined) {
    throw new Error('useIdeaDatabaseState must be used within a IdeaDatabaseProvider');
  }
  return context;
};

const useIdeaDatabaseDispatch = (): IdeaDatabaseDispatch => {
  const context = React.useContext(IdeaDatabaseDispatchContext);
  if (context === undefined) {
    throw new Error('useIdeaDatabaseDispatch must be used within a IdeaDatabaseProvider');
  }
  return context;
};

export { IdeaDatabaseProvider, useIdeaDatabaseDispatch, useIdeaDatabaseState };
