import React from 'react';
import { Text } from '@mantine/core';

export default function DelAdminForm({ value }) {
  console.log(value);
  return (
    <form>
      <div>ลบบัญชีนี้ ออกจากการเป็น Membership ?</div>
      <Text className="mt-4">อีเมล : {value?.email}</Text>
      <Text className="mt-4">ตำแหน่ง : {value?.isSuperAdmin ? 'Super Admin' : 'Admin'}</Text>
    </form>
  );
}
