import { ReactComponent as Pencil } from '@assets/icon/pencil.svg';
import { ReactComponent as Trash } from '@assets/icon/trash.svg';
import { Group, Image, Text } from '@mantine/core';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload } from '@tabler/icons-react';
import React, { useRef, useState } from 'react';

interface UploadImageProps {
  image?: string;
  onChangeFile: (f: FileWithPath) => void;
}

interface CoverProps {
  file: FileWithPath | string | undefined;
  setFile: React.Dispatch<React.SetStateAction<string | FileWithPath | undefined>>;
  openRef: React.RefObject<() => void>;
}

const Cover = ({ file, setFile, openRef }: CoverProps): React.JSX.Element => {
  if (!file) {
    return <></>;
  }

  let imageUrl: string = '';
  if (typeof file === 'object') {
    imageUrl = URL.createObjectURL(file);
  } else {
    imageUrl = file as string;
  }

  return (
    <div className="relative rounded-2xl border border-dashed bg-primary-100">
      <div className="absolute right-2 top-2 z-20 flex gap-2">
        <div className="cursor-pointer" onClick={() => openRef.current && openRef.current()}>
          <Pencil className="size-10 rounded-full border-outline bg-white p-2" />
        </div>
        <div className="cursor-pointer" onClick={() => setFile(undefined)}>
          <Trash className="size-10 rounded-full bg-error p-2" />
        </div>
      </div>

      <Image src={imageUrl} imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }} radius="lg" fit="cover" height={332} width={584} />
    </div>
  );
};

export default function UploadImage({ image, onChangeFile }: UploadImageProps): React.JSX.Element {
  const openRef = useRef<() => void>(null);
  const [file, setFile] = useState<FileWithPath | string | undefined>(image as string);

  const handleChangeFile = (files: FileWithPath[]): void => {
    if (files?.length > 0) {
      const f = files[0];
      setFile(f);
      onChangeFile(f);
    }
  };

  return (
    <div className="h-[332px] w-[584px]">
      <Dropzone
        openRef={openRef}
        multiple={false}
        onDrop={handleChangeFile}
        accept={IMAGE_MIME_TYPE}
        hidden={!!file}
        className="rounded-2xl p-0"
      >
        <Group position="center" className="h-full rounded-2xl bg-primary-100" style={{ height: 328 }}>
          <div className="text-center">
            <Dropzone.Accept>
              <IconUpload size={50} stroke={1.5} color="green" />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <Text size="xl" color="dimmed" inline>
                ไฟล์นี้ไม่ได้นะ
              </Text>
            </Dropzone.Reject>
            <Dropzone.Idle>
              <Text size="xl" color="dimmed" inline>
                อัปโหลดภาพ Banner
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                คำแนะนำ: 72 dpi ขนาด 584*332 px .jpg
              </Text>
            </Dropzone.Idle>
          </div>
        </Group>
      </Dropzone>

      <Cover file={file} setFile={setFile} openRef={openRef} />
    </div>
  );
}
