import { IdeaDatabaseProvider } from '@context/IdeaDatabase.context';
import React from 'react';
import IdeaFilter from './components/IdeaFilter';
import IdeaTable from './components/IdeaTable';

const IdeaDatabase = (): React.JSX.Element => {
  return (
    <div className="container mx-auto">
      <article className="prose">
        <h1>Idea Database</h1>
      </article>
      <IdeaFilter />
      <IdeaTable />
    </div>
  );
};

const IdeaDatabasePage = (): React.JSX.Element => {
  return (
    <IdeaDatabaseProvider>
      <IdeaDatabase />
    </IdeaDatabaseProvider>
  );
};

export default IdeaDatabasePage;
