import useSWR from 'swr';
import axios from '../axios';

export const useLogicMessage = () => {
  const { data, mutate } = useSWR('/logic-message/type-text/list', (url) => axios.get(url).then((res) => res.data));

  /**
   *
   * @param {"create"|"update"|"delete"} actions
   * @param {*} payload
   * @returns
   */
  const dispatch = (actions, payload) => {
    switch (actions) {
      case 'create':
        return axios.post('/logic-message/create', {
          type: 'text',
          ...payload,
        });
      case 'update':
        return axios.put('/logic-message/update', {
          type: 'text',
          ...payload,
        });
      case 'delete':
        return axios.post('/logic-message/delete', {
          logicMessageID: payload.id,
        });
      default:
        return new Promise((resolve) => {
          resolve();
        });
    }
  };

  return {
    data: data?.data?.items.map((item, idx) => ({ ...item, index: idx })) || [],
    dispatch,
    mutate,
  };
};

export const useLogicName = (options) => {
  const { data } = useSWR(options?.id ? `/logic/detail/${options?.id}` : null, (url) =>
    axios.post('/logic/detail', { logicID: options.id || 0 }).then((res) => res.data)
  );
  return { title: data?.data?.item?.name || '' };
};

export const useLogicList = () => {
  const { data } = useSWR('/logic/list', (url) => axios.get(url).then((res) => res.data));
  return {
    logics:
      data?.data?.items?.map((item) => ({
        label: item.name,
        value: `${item.logicID}`,
      })) || [],
  };
};
