import React from 'react';
import { TextInput, Group, Button } from '@mantine/core';

import Select from '../../../../UI/Selector/Select';
import DatePickerWithLabel from '../../../../UI/DatePickerRange/DatePickerWithLabel';

import { useForm } from '@mantine/form';
import provinces from '../../../../../constants/provinces';
import affiliations from '../../../../../constants/affiliations';
import { API } from '../../../../../services/API';
import DatePicker from '../../../../UI/DatePickerRange/DatePicker';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';

const SCHOOL_GROUP_CODE_ADD_URL = '/school-group/code/create';
// const SCHOOL_GROUP_UPDATE_URL = "/school-group/update";

export default function AddSchoolForm({ Dialog, buttonTitle, modalTitile, buttonIcon, values, fetch }) {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const form = useForm({
    initialValues: {
      //   schoolGroupID: values?.schoolGroupID || "",
      //   name: values?.name || "",
      //   affilition: values?.affilition || "",
      code: values?.code || '',
      expiredAt: values?.expiredAt ? new Date(values.expiredAt) : '',
      maxMember: values?.maxMember || '',
      // membershipEndAt: values?.membershipEndAt ? new Date(values.membershipEndAt) : null,
      // membershipStartAt: values?.membershipStartAt ? new Date(values.membershipStartAt) : null,
      //   province: values?.province || "",
    },
    validate: {
      code: (v) => (v ? null : true),
      expiredAt: (v) => (v ? null : true),
      maxMember: (v) => (v && v > 1 ? null : true),
      // membershipEndAt: (v) => (v ? null : true),
      // membershipStartAt: (v) => (v ? null : true),
    },
  });

  const handleOnSubmit = (setOpened) => {
    const { hasErrors } = form.validate();
    console.log({ hasErrors });
    console.log(form.values);

    if (!hasErrors) {
      //   if (!form.values.schoolGroupID) {
      handleAddSchoolGroupCode(form.values, setOpened);
      //   } else {
      //     handleEditSchoolGroup(form.values, setOpened);
      //   }
    }
  };
  const handleAddSchoolGroupCode = async ({ code, maxMember, expiredAt }, setOpened) => {
    const response = await axiosPrivate.post(SCHOOL_GROUP_CODE_ADD_URL, {
      schoolGroupID: +id,
      code,
      maxMember: Number(maxMember),
      expiredAt,
    });
    form.reset();
    fetch();
    setOpened(false);
  };
  // const setValue = (e) => {
  //   if (e[0] && e[1]) {
  //     form.setFieldValue("membershipStartAt", e[0]);
  //     form.setFieldValue("membershipEndAt", e[1]);
  //   }
  // };
  return (
    <Dialog buttonTitle={buttonTitle} modalTitile={modalTitile} buttonIcon={buttonIcon} onSubmit={handleOnSubmit}>
      <form>
        {/* <h1>{values?.id ? "แก้ไขกลุ่มโรงเรียน" : "เพิ่มกลุ่มโรงเรียน"}</h1> */}
        {/* <TextInput label="ตั้งชื่อกลุ่มโรงเรียน" placeholder="ใส่ชื่อกลุ่มโรงเรียน" {...form.getInputProps("name")} /> */}
        {/* <DatePickerWithLabel
          label="กำหนดวันเริ่ม - วันหมดอายุ membership"
          placeholder="กำหนดวันเริ่ม - วันหมดอายุ"
          defaultValue={[form.values.membershipEndAt, form.values.membershipEndAt]}
          onChange={setValue}
        ></DatePickerWithLabel> */}
        {/* <Select className="mt-1" label="เลือกคูปอง" placeholder="เลือกคูปอง" data={[]}></Select> */}
        {/* <Select
          className="mt-1"
          label="เลือกสังกัด"
          placeholder="เลือกสังกัด"
          data={affiliations}
          {...form.getInputProps("affilition")}
        ></Select>
        <Select
          className="mt-1"
          label="เลือกจังหวัดของกลุ่มโรงเรียน"
          placeholder="เลือกจังหวัด"
          data={provinces}
          {...form.getInputProps("province")}
        ></Select> */}
        <TextInput label="code" placeholder="ใส่ Code" {...form.getInputProps('code')} />
        <TextInput label="จำนวน" placeholder="ใส่เลข" {...form.getInputProps('maxMember')} />
        <DatePicker
          label="กำหนดวันหมดอายุ"
          placeholder="กำหนดวันเวลาหมดอายุ"
          defaultValue={null}
          {...form.getInputProps('expiredAt')}
        ></DatePicker>
      </form>
    </Dialog>
  );
}
