import { Menu, Button } from '@mantine/core';
import { ReactComponent as Chevrondown } from '../../../../assets/icon/caretdown.svg';
import React from 'react';
import { Link } from 'react-router-dom';
export default function StatsDropDown() {
  return (
    <>
      <Menu radius="md">
        <Menu.Target>
          <Button rightIcon={<Chevrondown />} className="z-40 bg-transparent px-4 py-2 text-xl font-normal text-black hover:bg-transparent">
            Stat
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Link to="">
            <Menu.Item className="text-lg">ชาวอินส์ Stat</Menu.Item>
          </Link>
          <Link to="">
            <Menu.Item className="text-lg">Website Stat</Menu.Item>
          </Link>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
