import { Button, Select, SelectItem } from '@mantine/core';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { editIdea, getIdeaById, getUserSuggest } from '@api/idea/idea.service';
import { IdeaDetailRes, UserSuggestResponse } from '@models/IdeaDatabase.type';

import ideaCollectionService from '@api/idea-collection/idea-collection.service';
import { IdeaCollection } from '@models/IdeaCollection';
import FileUpload from './FileUpload';
import IdeaDetails from './IdeaDetails';
import IdeaTemplate from './IdeaTemplate';
import ImageUploader from './ImageUploader';

const MySwal = withReactContent(Swal);

const EditIdeaForm = ({ ideaId }: { ideaId?: string }) => {
  const { values, setFieldValue } = useFormikContext<IdeaDetailRes>();
  const [preview, setPreview] = useState<string>('');
  const [userOptions, setUserOptions] = useState<UserSuggestResponse[]>([]);
  const [editIdeaData, setEditIdeaData] = useState<IdeaDetailRes>({
    _id: undefined,
    ownerId: undefined,
    title: '',
    description: '',
    requestOpinion: false,
    tags: [],
    images: [],
    files: [],
    templateType: null,
    content: '',
    isDraft: Boolean(values.isDraft),
    type: '',
    creditTo: null,
    purposes: '',
    process: '',
    consequence: '',
    suggestion: '',
    ideaCollection: null,
  });
  const [ideaGroup, setIdeaGroup] = useState<SelectItem[]>([]);

  useEffect(() => {
    ideaCollectionService.getAllIdeaCollection().subscribe((resp: IdeaCollection[]) => {
      const items: SelectItem[] = resp.map((ideaCollection) => ({
        group: `${ideaCollection.year}`,
        value: `${ideaCollection.id}`,
        label: ideaCollection.collectionCategory.name,
      }));
      setIdeaGroup(items);
    });
  }, []);

  useEffect(() => {
    const cover = values.images?.[0]?.image || '';
    setPreview(cover);
  }, [values.images]);

  useEffect(() => {
    if (ideaId) {
      getIdeaById(ideaId).subscribe((res: IdeaDetailRes) => {
        const processedContent = res.content.replace(/data-src=/g, 'src=');
        setEditIdeaData({ ...res, content: processedContent });
        Object.entries(res).forEach(([key, value]) => {
          setFieldValue(key, value);
        });
      });
    }
  }, [ideaId, setFieldValue]);

  const debouncedFetchSuggestUser = useCallback(
    debounce((keyword: string) => {
      getUserSuggest(keyword).subscribe((res: UserSuggestResponse[]) => {
        setUserOptions(res);
      });
    }, 500),
    []
  );

  const handleChange =
    <K extends keyof IdeaDetailRes>(key: K) =>
    (value: IdeaDetailRes[K]) => {
      setEditIdeaData((prev) => ({ ...prev, [key]: value }));
      setFieldValue(key, value);
    };

  const handleEditIdea = (values: IdeaDetailRes, successMessage: string) => {
    const { _id } = values;
    if (_id) {
      editIdea(_id, values).subscribe({
        next: () => {
          MySwal.fire({
            icon: 'success',
            title: successMessage,
            showConfirmButton: false,
            timer: 2000,
          });
        },
        error: () => {
          MySwal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
            showConfirmButton: false,
            timer: 1500,
          });
        },
      });
    }
  };

  const handleToggleDraft = () => {
    const isDraft = !editIdeaData.isDraft;
    const updatedValues = { ...values, isDraft };
    setEditIdeaData((prev) => ({ ...prev, isDraft }));
    handleEditIdea(updatedValues, isDraft ? 'เปลี่ยนเป็นแบบร่างสำเร็จแล้ว!' : 'เปลี่ยนเป็นเผยแพร่สำเร็จแล้ว!');
  };

  return (
    <>
      <div className="mb-4 flex justify-end gap-4">
        <Button
          type="button"
          className="w-fit rounded-full border border-black bg-transparent px-4 text-xl font-normal text-black no-underline hover:bg-white"
          onClick={handleToggleDraft}
        >
          {editIdeaData.isDraft ? 'เผยแพร่' : 'เปลี่ยนเป็นแบบร่าง'}
        </Button>
        <Button type="submit" className="rounded-full bg-primary px-4 text-xl font-normal text-black no-underline hover:bg-primary-300">
          บันทึก
        </Button>
      </div>
      <div className="flex justify-end">
        <Select
          searchable
          label="จัดให้อยู่ใน Collection"
          placeholder="เลือก collection"
          size="md"
          className="w-96"
          data={ideaGroup}
          value={editIdeaData.ideaCollection ? `${editIdeaData.ideaCollection}` : null}
          onChange={(value: string) => handleChange('ideaCollection')(parseInt(value))}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex gap-8">
          <ImageUploader preview={preview} setPreview={setPreview} setFieldValue={setFieldValue} />
          <IdeaDetails
            editIdeaData={editIdeaData}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            userOptions={userOptions}
            debouncedFetchSuggestUser={debouncedFetchSuggestUser}
            setEditIdeaData={setEditIdeaData}
          />
        </div>

        <div className="grid gap-2">
          <label htmlFor="">แบ่งปันไฟล์ที่เกี่ยวข้อง</label>
          <FileUpload />
        </div>

        <IdeaTemplate editIdeaData={editIdeaData} handleChange={handleChange} />
      </div>
    </>
  );
};

export default EditIdeaForm;
