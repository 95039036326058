import { createNotification } from '@api/notification/notification.service';
import { ReactComponent as CorrectCircle } from '@assets/icon/correctCircle.svg';
import { useLoader } from '@context/LoadingOverlay';
import { Button, Checkbox, Modal, Select, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { APIResponseError } from '@models/Error';
import {
  NotificationType,
  NotificationUserReq,
  NotificationUserType,
  notificationTargetProps,
  notificationTypeProps,
} from '@models/NotificationReq';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { finalize } from 'rxjs';

const UserNotification = (): React.JSX.Element => {
  const { setLoading } = useLoader();

  const form = useForm<NotificationUserReq>({
    initialValues: {
      type: NotificationType.NORMAL,
      target: NotificationUserType.BROADCAST,
      link: '',
      externalSite: false,
      message: '',
    },
    validate: {
      message: (value) => (value ? null : 'พิมพ์ข้อความที่จะขึ้นในแจ้งเตือน'),
      link: (value) => (value ? null : 'กรุณาแนบลิงก์'),
    },
  });
  const [err, setErr] = useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [createCount, setCreateCount] = useState<number>(0);
  const [openModalSuccess, setOpenModalSuccess] = useState<boolean>(false);

  const handleSubmitForm = (values: NotificationUserReq): void => {
    if (form.isValid()) {
      setLoading(true);
      setSubmitting(true);
      createNotification(values)
        .pipe(
          finalize(() => {
            setSubmitting(false);
            setLoading(false);
          })
        )
        .subscribe({
          next: ({ count }) => {
            setCreateCount(count);
            setOpenModalSuccess(true);
            form.reset();
          },
          error: (err: AxiosError<APIResponseError>) => {
            setErr(err.response?.data?.error?.message);
          },
        });
    }
  };

  return (
    <>
      <div className="mb-10">
        <article className="prose">
          <h1 className="mb-0">ส่ง Noti ให้ Website’s User</h1>
        </article>
        เมื่อส่งแล้วจะส่งอัตโนมัติ ไม่มีย้อนกลับ เช็คดีๆ น้า
      </div>

      <div className="flex justify-center">
        <div className="grid w-2/5 gap-4">
          <form className="grid gap-4" onSubmit={form.onSubmit((v) => handleSubmitForm(v))}>
            <Select
              withAsterisk
              label="ประเภทแจ้งเตือน"
              placeholder="เลือกประเภทแจ้งเตือน"
              data={notificationTypeProps}
              error={form.errors.type}
              {...form.getInputProps('type')}
            />

            <Select
              withAsterisk
              label="Target"
              placeholder="ส่งหาใคร"
              data={notificationTargetProps}
              error={form.errors.type}
              {...form.getInputProps('target')}
            />

            <Textarea
              withAsterisk
              minRows={4}
              placeholder="พิมพ์ข้อความที่จะขึ้นในแจ้งเตือน"
              label="ข้อความแจ้งเตือน"
              error={form.errors.message}
              {...form.getInputProps('message')}
            />

            <TextInput
              withAsterisk
              label="ลิงก์ที่ต้องการให้คลิกแล้วพาไป"
              placeholder="แนบลิงก์"
              type="url"
              error={form.errors.link}
              {...form.getInputProps('link')}
            />

            <Checkbox label="ลิ้งนอกระบบ" error={form.errors.externalSite} {...form.getInputProps('externalSite', { type: 'checkbox' })} />

            {err && <div className="text-right text-error">{err}</div>}

            <div className="flex justify-end">
              <Button
                type="submit"
                className="rounded-full bg-primary px-4 text-lg font-normal text-black no-underline hover:bg-primary-300"
                disabled={submitting}
              >
                ส่งแจ้งเตือน
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Modal centered opened={openModalSuccess} onClose={() => setOpenModalSuccess(false)}>
        <div className="flex flex-col items-center gap-4 text-success-300">
          <CorrectCircle />
          ส่ง Noti สำเร็จแล้ว จำนวน {createCount?.toLocaleString()} คน
        </div>
      </Modal>
    </>
  );
};

export default UserNotification;
