import { IdeaDatabaseUserTagsItems } from '@constants/IdeaDatabase';
import { MultiSelect, Select, TextInput } from '@mantine/core';
import { IdeaDetailRes, UserSuggestResponse } from '@models/IdeaDatabase.type';
import React from 'react';

interface IdeaDetailsProps {
  editIdeaData: IdeaDetailRes;
  handleChange: <K extends keyof IdeaDetailRes>(key: K) => (value: IdeaDetailRes[K]) => void;
  setFieldValue: (field: string, value: unknown) => void;
  userOptions: UserSuggestResponse[];
  debouncedFetchSuggestUser: (keyword: string) => void;
  setEditIdeaData: React.Dispatch<React.SetStateAction<IdeaDetailRes>>;
}

const IdeaDetails: React.FC<IdeaDetailsProps> = ({
  editIdeaData,
  handleChange,
  setFieldValue,
  userOptions,
  debouncedFetchSuggestUser,
  setEditIdeaData,
}) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <TextInput
        size="md"
        radius="md"
        label="ชื่อไอเดีย"
        value={editIdeaData.title}
        onChange={(e) => handleChange('title')(e.currentTarget.value)}
      />
      <MultiSelect
        label="ติดแท็กให้ไอเดีย"
        placeholder="User Tag"
        radius="md"
        size="md"
        clearable
        searchable
        creatable
        getCreateLabel={(query) => `+ Create "${query}"`}
        onCreate={(query) => {
          const newTag = { value: query, label: query };
          setEditIdeaData((prev) => ({
            ...prev,
            tags: [...prev.tags, query],
          }));
          setFieldValue('tags', [...editIdeaData.tags, query]);
          return newTag;
        }}
        value={editIdeaData.tags}
        onChange={(value) => handleChange('tags')(value)}
        data={IdeaDatabaseUserTagsItems.concat(editIdeaData.tags.map((tag) => ({ value: tag, label: tag })))}
      />

      <Select
        searchable
        clearable
        label="ให้เครดิตเจ้าของไอเดีย"
        radius="md"
        size="md"
        data={userOptions.map((user) => ({ value: user.userId.toString(), label: user.name }))}
        value={editIdeaData.creditTo !== null ? String(editIdeaData.creditTo) : undefined}
        onChange={(value) => handleChange('creditTo')(value ? Number(value) : null)}
        onSearchChange={debouncedFetchSuggestUser}
      />
    </div>
  );
};

export default IdeaDetails;
