import axios from '../axios';
import useSWR from 'swr';

const shouldImplement = () =>
  new Promise((resolve) => {
    resolve();
  });
export const usePackageList = () => {
  const { data, mutate } = useSWR('/package/list', (url) => axios.get(url).then((res) => res.data));
  /**
   *
   * @param {"create"|"delete"|"active"} action
   * @param {*} payload
   * @returns
   */
  const dispatch = (action, payload) => {
    switch (action) {
      case 'create':
        return axios.post('/package/create', payload);
      case 'update':
        return axios.put('/package/update', payload);
      case 'delete':
        return axios.post('/package/delete', payload);
      case 'active':
        return axios.patch('/package/active', payload);
      default:
        return shouldImplement();
    }
  };
  return {
    data:
      data?.data?.items?.map((item) => ({
        name: item.name,
        packageID: item.packageID,
        packageTypeID: item.packageTypeID,
        price: item.price,
        duration: item.duration,
        isActive: item.isActive,
      })) || [],
    dispatch,
    mutate,
  };
};
