import React, { useEffect, useState } from 'react';
import { Select, Button, Container, Group, Text, Space, Modal, Image } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as Trash } from '../../../assets/icon/trash.svg';
import { ReactComponent as Pencil } from '../../../assets/icon/pencil.svg';
import { useLogicList } from '../../../api/presenter/useLogicMessage';
import axios from 'axios';

import { v4 as uuid } from 'uuid';
import { useId } from '@mantine/hooks';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const uploadImage = (path, data) => {
  return axios.post(`https://storage.googleapis.com/upload/storage/v1/b/inskru-public-bucket/o?name=ideadatabase/logic/${path}`, data);
};

export const getImageName = (url) => {
  const res = url.split('?')[0].split('%2F');
  return res[res.length - 1];
};

/**
 *
 * @param {{actions:{create:function,update:function,cancel:function}}} param0
 * @returns
 */
export default function UploadPic({ actions = {}, row = {}, reset = () => {} }) {
  const uniqueId = useId('', () => {
    return uuid().replace(/-/g, '');
  });

  const MySwal = withReactContent(Swal);

  const [imgData, setimgData] = useState(null);
  const [modal, setmodal] = useState(false);

  const [imageName, setimageName] = useState('');

  const { logics } = useLogicList();

  const form = useForm({
    initialValues: { value: '', logicID: 0 },
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (ev) => {
      form.setFieldValue('value', ev[0].path);
      const reader = new FileReader();
      reader.readAsDataURL(ev[0]);
      reader.onload = () => {
        console.log(reader.result);
        setimgData(reader.result);
      };

      const reader2 = new FileReader();
      reader2.readAsBinaryString(ev[0]);
      reader.onload = () => {
        uploadImage(`${uniqueId}/${ev[0].path}`, ev[0])
          .then((res) => {
            form.setFieldValue('value', res.data.mediaLink);
            setimageName(getImageName(res.data.mediaLink));
            setimgData(res.data.mediaLink);
          })
          .catch((err) => {
            console.log(err);
          });
      };
    },
  });

  useEffect(() => {
    if (row?.logicMessageID) {
      form.setFieldValue('value', row?.value);
      form.setFieldValue('logicID', `${row?.logicID}`);
      setimageName(getImageName(row?.value));
      setimgData(row?.value);
    }
  }, [row]);

  useEffect(() => {
    return () => {
      console.log('unmount');

      reset();
      form.setFieldValue('value', '');
      form.setFieldValue('logicID', '');
      setimageName('');
    };
  }, []);

  return (
    <form className="">
      <Select required label="เลือก Logic" data={logics} placeholder="เลือก Logic ที่เคยสร้างไว้" {...form.getInputProps('logicID')} />
      <Space h={12} />
      <input accept="image/png, image/jpeg" {...getInputProps()} />
      <Button
        classNames={{
          root: 'z-40 mt-1 border rounded-full bg-transparent  hover:bg-transparent border-black text-black border-blue',
          label: '',
        }}
        sx={{ display: form.values.value.length ? 'none' : 'unset' }}
        type="button"
        {...getRootProps()}
      >
        + อัพโหลดรูปภาพ
      </Button>
      {form.values.value.length > 0 && (
        <Container px={0} py={14}>
          <Group sx={{ justifyContent: 'space-between' }} noWrap>
            <Text
              component="a"
              className="text"
              sx={{
                textDecoration: 'underline',
                color: 'rgba(12.43.102,0.1) !important',
                fontSize: '16px',
                fontWeight: '275',
              }}
              onClick={() => {
                setmodal(true);
              }}
            >
              {imageName || ''}
            </Text>
            <Group noWrap>
              <Button
                classNames={{
                  root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
                  label: '',
                  icon: 'mr-0',
                }}
                styles={{
                  leftIcon: {
                    marginRight: 15,
                  },
                  root: {
                    height: '24px',
                  },
                }}
                leftIcon={<Pencil />}
                {...getRootProps()}
              />

              <Button
                classNames={{
                  root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
                  label: '',
                  icon: 'mr-0',
                }}
                styles={{
                  leftIcon: {
                    marginRight: 15,
                  },
                  root: {
                    height: '24px',
                  },
                }}
                leftIcon={<Trash />}
                onClick={() => {
                  form.setFieldValue('value', '');
                }}
              />
            </Group>
          </Group>
        </Container>
      )}
      <Space h={16} />
      <Group sx={{ justifyContent: 'flex-end' }}>
        <Button
          classNames={{
            root: 'z-40 border rounded-full bg-transparent  hover:bg-transparent border-black text-black',
            label: '',
          }}
          type="button"
          onClick={() => actions.cancel()}
        >
          ยกเลิก
        </Button>
        <Button
          classNames={{
            root: 'z-40 border rounded-full bg-primary hover:bg-primary-600 active:bg-primary text-black',
            label: '',
          }}
          type="button"
          onClick={() => {
            if (!form.values.logicID.length || !form.values.value.length) {
              return MySwal.fire({
                icon: 'error',
                title: 'กรุณากรอกข้อมูลให้ครบ',
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (row?.logicMessageID) {
              return actions.update({
                ...form.values,
                logicID: parseInt(form.values.logicID),
                logicMessageID: row?.logicMessageID,
              });
            } else {
              return actions.create({
                ...form.values,
                logicID: parseInt(form.values.logicID),
              });
            }
          }}
        >
          ยืนยัน
        </Button>
      </Group>

      <Modal
        opened={modal}
        onClose={() => setmodal(false)}
        withCloseButton={false}
        centered
        overlayOpacity={0.25}
        styles={{ modal: { padding: '0 !important', borderRadius: 8 } }}
      >
        <Image src={imgData} radius="md" />
      </Modal>
    </form>
  );
}
