import React from 'react';
import Select from '../UI/Selector/Select';
import DateRange from '../UI/DatePickerRange/DatePickerRange';
import Search from '../UI/Search/Search';
import HiddenTag from '../UI/HiddenTag/HiddenTag';
import { Box, Group, Space, TextInput } from '@mantine/core';

const ClearIcon = ({ ...props }) => (
  <Box {...props}>
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <path
        d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
      ></path>
    </svg>
  </Box>
);
export default function IdeaOption({
  values,
  actions = {
    changeSort: () => {},
    changeHiddenTag: () => {},
    changeUserTag: () => {},
    changeSearch: () => {},
    changeCreateAt: () => {},
    changeUpdateAt: () => {},
    changeIdeaFilter: () => {},
  },
}) {
  return (
    <div className="mt-8">
      <Group sx={{ justifyContent: 'flex-start' }} spacing={24} grow noWrap>
        <Select
          placeholder="เรียงลำดับโดย"
          data={[
            { label: 'ล่าสุด', value: 'DESC' },
            { label: 'ยอดนิยม', value: 'ASC' },
          ]}
          value={values.sortFilter}
          onChange={(value) => actions.changeSort(value)}
          clearable
        />
        <Select
          placeholder="กรองไอเดีย"
          values={values.ideaFilter}
          onChange={(ev) => actions.changeIdeaFilter(ev)}
          data={[
            { label: 'ไอเดียปักหมุด', value: 1 },
            { label: 'insKru Selected', value: 2 },
          ]}
          clearable
        />
        <Group noWrap>
          <label style={{ maxWidth: 80 }}>Created : </label>
          <DateRange placeholder="เลือกช่วงวันที่" value={values?.createAtFilter || 0} onChange={(ev) => actions.changeCreateAt(ev)} />
        </Group>
        <Group noWrap>
          <label style={{ maxWidth: 80 }}>Updated : </label>
          <DateRange placeholder="เลือกช่วงวันที่" value={values?.updateAtFilter || 0} onChange={(ev) => actions.changeUpdateAt(ev)} />
        </Group>
      </Group>
      <Space h={24} />
      <Group grow noWrap>
        <HiddenTag
          data={values?.taglist || []}
          value={values?.hiddenTagFilter || []}
          onChange={(ev) => {
            actions.changeHiddenTag(ev);
          }}
        />
        <TextInput
          value={values?.userTagFilter}
          onChange={(ev) => {
            actions.changeUserTag(ev.target.value);
          }}
          placeholder="ค้นหา User Tag"
          rightSection={<>{values?.userTagFilter?.length > 0 && <ClearIcon onClick={() => actions.changeUserTag('')} />}</>}
        />
        <Search
          value={values?.searchFilter || ''}
          onChange={(ev) => {
            actions.changeSearch(ev.target.value);
          }}
          placeholder="ค้นหาผ่านชื่อไอเดีย นามปากกาคนเขียน "
        ></Search>
      </Group>
    </div>
  );
}
