import { Group, Switch } from '@mantine/core';

import AddPackageForm from '../components/PackageSetting/AddPackageForm';
import DelPackageForm from '../components/PackageSetting/DelPackageForm';
import DialogCard from '../components/UI/Card/DaialogCard';
import DialogCardTable from '../components/UI/Card/DaialogCardTable';
import { ReactComponent as Pencil } from '../assets/icon/pencil.svg';
import { ReactComponent as Plus } from '../assets/icon/Plus.svg';
import React from 'react';
import Swal from 'sweetalert2';
import TableAdmin from '../components/UI/Table/Table';
import { createTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { usePackageList } from '../api/presenter/usePackage';
import withReactContent from 'sweetalert2-react-content';

const PackageSetting = () => {
  const { data, dispatch, mutate } = usePackageList();

  const MySwal = withReactContent(Swal);

  const table = createTable().setRowType();
  const defaultColumns = table.createColumns([
    table.createDataColumn((row) => row.packageTypeID, {
      id: 'packageTypeID',
      cell: (info) => <>{info.value === 1 ? 'Buddy Kru' : info.value === 2 ? 'Workshop' : 'ไม่ระบุ'}</>,
      header: () => <span>ประเภทแพคเกจ</span>,
    }),
    table.createDataColumn((row) => row.name, {
      id: 'name',
      cell: (info) => info.value,
      header: () => <span>ชื่อแพ็กเกจ</span>,
    }),
    table.createDataColumn((row) => row.duration, {
      id: 'duration',
      cell: (info) => `${info.value}`,
      header: () => <span>ระยะเวลา (เดือน)</span>,
    }),
    table.createDataColumn((row) => row.price, {
      id: 'price',
      cell: (info) => `${info.value}`,
      header: () => <span>ราคา</span>,
    }),
    table.createDataColumn((row) => row.isActive, {
      id: 'isActiveText',
      cell: (info) => <>{info.value ? 'กำลังใช้งาน' : 'ปิด'}</>,
      header: () => <span>สถานะ</span>,
    }),
    table.createDataColumn((row) => row.isActive, {
      id: 'isActiveChecked',
      cell: (info) => (
        <>
          <Switch
            checked={info.row.original.isActive}
            readOnly
            size="lg"
            color="yellow"
            onClick={() => {
              dispatch('active', {
                packageID: info.row.original.packageID,
                isActive: !info.row.original.isActive,
              })
                .then((res) => {
                  mutate();
                  MySwal.fire({
                    icon: 'success',
                    title: `${!info.row.original.isActive ? 'เปิดใช้งาน' : 'ปิดใช้งาน'} สำเร็จ!`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                })
                .catch((err) => {
                  MySwal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  console.log(err);
                });
            }}
            sx={{ [`input`]: { cursor: 'pointer' } }}
          />
        </>
      ),
      header: () => <span></span>,
    }),
    table.createDataColumn((row) => row.isActive, {
      id: 'controls',
      cell: (info) => (
        <>
          <Group position="right" noWrap>
            <AddPackageForm
              values={info.row.original}
              Dialog={DialogCardTable}
              buttonIcon={<Pencil />}
              modalTitile="แก้ไขแพคเกจ"
              isUpdate
            ></AddPackageForm>
            {/* <DelPackageForm
              values={info.row.original}
              handleSubmit={() => {
                // console.log({ packageID: info.row.original.packageID });
                dispatch("update", { packageID: info.row.original.packageID });
              }}
            /> */}
          </Group>
        </>
      ),
      header: () => <span></span>,
    }),
  ]);

  return (
    <>
      <div className="text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">ตั้งค่าแพคเกจ</div>
      <div className="mt-8">
        {/* <DialogCard setOpened={setOpenedAdd} opened={openedAdd}> */}
        <AddPackageForm Dialog={DialogCard} buttonTitle="เพิ่มแพคเกจ" modalTitile="เพิ่มแพคเกจ" buttonIcon={<Plus />} />
        {/* </DialogCard> */}
        <TableAdmin elements={data} defaultColumns={defaultColumns} />
      </div>
    </>
  );
};

export default PackageSetting;
