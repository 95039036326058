import { ReactComponent as Link } from '../../../assets/icon/link.svg';
import React from 'react';

export default function LinkUI({ href, name }) {
  return (
    <a href={href} rel="noreferrer" target="_blank" className="flex text-blue-800 underline">
      {name || 'link'}
      <Link className="mx-2 self-center" />
    </a>
  );
}
