import React, { useEffect, useState } from 'react';
import { Badge, Box, Button, Space, Text } from '@mantine/core';
import { createTable } from '@tanstack/react-table';

import { ReactComponent as Trash } from '../../../../assets/icon/trash.svg';
import { ReactComponent as Pencil } from '../../../../assets/icon/pencil.svg';

import Select from '../../../UI/Selector/Select';
import Search from '../../../UI/Search/Search';
import DialogCardTable from '../../../UI/Card/DaialogCardTable';
import Table from '../../../UI/Table/Table';
import UserIdUI from '../../../UI/User/UserId';
import AddSubSchoolForm from '../../SchoolManage/SubSchool/SubSchoolForm/AddSubSchoolForm';
import DelSubSchoolForm from '../../SchoolManage/SubSchool/SubSchoolForm/DelSubSchoolForm';
import LinkUI from '../../../UI/Link/LinkUI';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import SubSchoolMemberControlled from './SubSchoolMemberControlled/SubSchoolMemberControlled';
import axios from 'axios';
const SCHOOL_GROUP_MEMBER_URL = '/school-group/members';

const INIT_MODAL_STATE = {
  oldID: '',
  schoolRoleID: '',
};

export const SCHOOL_ROLE_ID = {
  MEMBERSHIP: 1,
  SCHOOL_DIRECTOR: 2,
};

export default function SubSchoolMember() {
  const [modal, setmodal] = useState(false);
  const [modalState, setmodalState] = useState(INIT_MODAL_STATE);

  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const [elements, setElements] = React.useState([]);
  React.useEffect(() => {
    schoolGroupMemberListFetch();
  }, []);
  const schoolGroupMemberListFetch = async () => {
    const response = await axiosPrivate.post(SCHOOL_GROUP_MEMBER_URL, {
      schoolGroupID: +id,
    });
    setElements(response.data.data.items);
  };

  const tagButton = (items) => {
    return items.map((e) => (
      <div className="snap-center">
        <Badge variant="filled" size="lg" className="bg-secondary py-4 text-[12px] font-normal text-white">
          {e}
        </Badge>
      </div>
    ));
  };

  const removeFromGroupHandler = async (close, data) => {
    try {
      const response = await axiosPrivate
        .patch('/member/remove-school', {
          memberID: data.memberID,
        })
        .then((res) => {
          schoolGroupMemberListFetch();
          close();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const table = createTable().setRowType();

  const defaultColumns = table.createColumns([
    table.createDataColumn((row) => row.codeActiveDate, {
      id: 'codeActiveDate',
      cell: (info) => <>{info.value ? dayjs(info.value).format('DD/MM/YY') : dayjs(info.row.original.createdAt).format('DD/MM/YY')}</>,
      header: () => <span>เริ่ม</span>,
    }),
    table.createDataColumn((row) => row.expirationDate, {
      id: 'endDate',
      cell: (info) => dayjs(info.value).format('DD/MM/YY'),
      header: () => <span>หมดอายุ</span>,
    }),
    // table.createDataColumn((row) => row.occupation, {
    //   id: "occupation",
    //   cell: (info) => info.value,
    //   header: () => <span>ตำแหน่ง</span>,
    // }),
    table.createDataColumn((row) => row.oldID, {
      id: 'oldID',
      cell: (info) => <UserIdUI userId={info.value} />,
      header: () => <span>userid</span>,
    }),
    table.createDataColumn((row) => row.fullName, {
      id: 'userName',
      cell: (info) => info.value,
      header: () => <span>ชื่อ-นามสกุล</span>,
    }),
    table.createDataColumn((row) => row.oldID, {
      id: 'userGoal',
      cell: (info) => <LinkUI href={`${process.env.REACT_APP_OLDSITE_URL}/profile/${info.row.original.oldID}?tab=owngoal`} />,
      header: () => <span>หน้าเป้าหมาย</span>,
    }),
    table.createDataColumn((row) => row.occupation, {
      id: 'userJob',
      cell: (info) => info.value,
      header: () => <span>อาชีพ</span>,
    }),
    table.createDataColumn((row) => row.school, {
      id: 'userSchool',
      cell: (info) => info.value,
      header: () => <span>โรงเรียน</span>,
    }),
    table.createDataColumn((row) => row.province, {
      id: 'provinces',
      cell: (info) => info.value,
      header: () => <span>จังหวัด</span>,
    }),
    table.createDataColumn((row) => row.subjects, {
      id: 'userSubject',
      cell: (info) => (
        <>
          <div className="flex">
            <div className="my-custom-width-sub scrollbarTable mr-1 flex snap-x flex-nowrap items-center space-x-1.5 overflow-x-auto scroll-smooth">
              {tagButton(info.value)}
            </div>
          </div>
        </>
      ),
      header: () => <span>วิชาที่สอน</span>,
    }),
    table.createDataColumn((row) => row.levels, {
      id: 'userClass',
      cell: (info) => (
        <>
          <div className="flex">
            <div className="my-custom-width-sub scrollbarTable mr-1 flex snap-x flex-nowrap items-center space-x-1.5 overflow-x-auto scroll-smooth">
              {tagButton(info.value)}
            </div>
          </div>
        </>
      ),
      header: () => <span>ระดับชั้น</span>,
    }),
    table.createDataColumn((row) => row, {
      id: 'id',
      cell: (info) => (
        <div className="flex justify-end space-x-2">
          {/* <DialogCardTable
            buttonIcon={<Pencil />}
            modalTitile="เพิ่ม Membership"
            data={info}
          >
            <AddSubSchoolForm></AddSubSchoolForm>
          </DialogCardTable> */}

          <Button
            classNames={{
              root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
              label: '',
              icon: 'mr-0',
            }}
            styles={{
              leftIcon: {
                marginRight: 15,
              },
            }}
            leftIcon={<Pencil />}
            onClick={() => {
              const { oldID, schoolRoleID } = info.value;
              setmodal(true);
              setmodalState({
                ...INIT_MODAL_STATE,
                oldID: `${oldID}`,
                schoolRoleID: `${schoolRoleID}`,
              });
            }}
          />
          <DialogCardTable
            buttonIcon={<Trash />}
            modalTitile="ลบ Membership"
            onSubmit={(close) => removeFromGroupHandler(close, info.row.original)}
          >
            <DelSubSchoolForm values={info.row.original}></DelSubSchoolForm>
          </DialogCardTable>
        </div>
      ),
      header: () => <span></span>,
    }),
  ]);

  return (
    <div>
      <div className="mt-8 text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">School Membership</div>
      <Box>
        <SubSchoolMemberControlled
          state={{ modal }}
          actions={{
            setmodal: (value) => {
              if (!value) {
                setmodalState(INIT_MODAL_STATE);
              }
              setmodal(value);
            },
            schoolGroupMemberListFetch,
          }}
          initialValues={modalState}
          data={elements}
        />
      </Box>
      {/* <div className="mt-8">
        <div className="flex flex-row justify-start gap-8">
          <div className="basis-1/6">
            <Select placeholder="อาชีพ" data={[]}></Select>
          </div>
          <div className="basis-1/6">
            <Select placeholder="โรงเรียน" data={[]}></Select>
          </div>
          <div className="basis-1/6">
            <Select placeholder="จังหวัด" data={[]}></Select>
          </div>
          <div className="basis-1/3"></div>
        </div>
        <div className="mt-4 flex flex-row justify-center gap-8">
          <div className="basis-1/3">
            <Select placeholder="พิมพ์แท็ก ระดับชั้น หรือ วิชาที่สอน" data={[]}></Select>
          </div>
          <div className="basis-1/2">
            <Search placeholder="พิมพ์ชื่อ นามปากกา หรือ userid  "></Search>
          </div>
          <div className="basis-1/6"></div>
        </div>
      </div> */}
      <div className="text-md mb-4 mt-8">จำนวน {elements.length} account</div>
      <Text component="h1" className="mt-8 text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">
        ผอ. / ฝ่ายวิชาการ
      </Text>
      <Space h={16} />
      <Box>
        <Table
          elements={elements.filter((item) => (item?.schoolRoleID === SCHOOL_ROLE_ID.SCHOOL_DIRECTOR ? item : null))}
          defaultColumns={defaultColumns}
        />
      </Box>
      <Text component="h1" className="mt-8 text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">
        School Membership
      </Text>
      <Space h={16} />
      <Box>
        <Table
          elements={elements.filter((item) => (item?.schoolRoleID === SCHOOL_ROLE_ID.MEMBERSHIP ? item : null))}
          defaultColumns={defaultColumns}
        />
      </Box>
    </div>
  );
}
