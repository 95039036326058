import { allNotification, deleteNotification } from '@api/notification/notification.service';
import { Badge, Button, Modal } from '@mantine/core';
import { NotificationInsRes, NotificationStatus } from '@models/NotificationReq';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { finalize } from 'rxjs';

const InsNotification = (): React.JSX.Element => {
  const navigate = useNavigate();
  const [items, setItems] = useState<NotificationInsRes[]>();
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<NotificationInsRes | undefined>(undefined);

  const fetchNotification = (): void => {
    allNotification().subscribe((resp: NotificationInsRes[]) => setItems(resp));
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  const handleClickEdit = (id: number): void => {
    navigate(`/notification/ins/edit/${id}`);
  };

  const handleClickDelete = (id: number | undefined): void => {
    if (id) {
      deleteNotification(id)
        .pipe(finalize(() => setModalDelete(false)))
        .subscribe(() => {
          fetchNotification();
        });
    }
  };

  const handleOpenModalConfirmDelete = (item: NotificationInsRes): void => {
    setCurrentItem(item);
    setModalDelete(true);
  };

  return (
    <>
      <div className="mb-10">
        <article className="prose">
          <h1 className="mb-0">แจ้งเตือนชาวอินส์ล่วงหน้า</h1>
        </article>
        ข้อความแจ้งเตือนจะส่งหาชาวอินส์ 1 ปี หรือ ชาวอินส์ทั้งหมด(แล้วแต่กำหนด) อัตโนมัติในวันที่ 27 เวลา 23.59 น. (ตั้งเวลาเองไม่ได้)
      </div>

      <div className="mb-10">
        <Link to="/notification/ins/create">
          <Button className="rounded-full bg-primary px-4 text-xl font-normal text-black no-underline hover:bg-primary-300">
            สร้างแจ้งเตือน
          </Button>
        </Link>
      </div>

      <h2 className="mb-4 text-2xl">แจ้งเตือนรอส่ง</h2>

      <div className="grid gap-4">
        {items?.map((item, index) => {
          return (
            <div key={`items-${index}`} className="flex items-center gap-2 font-light leading-6">
              <div className="flex items-center gap-2">
                <Badge
                  variant="filled"
                  size="xs"
                  className="cursor-pointer border-outline bg-primary-100 p-4 py-3"
                  onClick={() => handleClickEdit(item.id)}
                >
                  <IconPencil color="#0C2B66" className="size-5" />
                </Badge>
                <Badge
                  variant="filled"
                  size="xs"
                  className="cursor-pointer border-outline bg-primary-100 p-4 py-3"
                  onClick={() => handleOpenModalConfirmDelete(item)}
                >
                  <IconTrash color="red" className="size-5" />
                </Badge>
              </div>
              <strong className={item.status === NotificationStatus.PENDING ? 'text-secondary' : 'text-success'}>{item.status}</strong>
              {/* <strong>{notificationInsTypeProps[item.insType]}</strong> */}
              -&nbsp;{item.message}
            </div>
          );
        })}
      </div>

      <Modal
        centered
        opened={modalDelete}
        onClose={() => setModalDelete(false)}
        title={
          <div className="flex w-full flex-row justify-center">
            <h1 className="text-2xl text-error">ลบแจ้งเตือน?</h1>
          </div>
        }
      >
        <div className="flex flex-col items-center">ลบแจ้งเตือน : {currentItem?.message}</div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="outline"
            color="red"
            className="mr-4 rounded-full px-4 text-lg font-normal text-black no-underline hover:bg-red-100"
            onClick={() => handleClickDelete(currentItem?.id)}
          >
            ลบ
          </Button>
          <Button
            variant="outline"
            className="rounded-full px-4 text-lg font-normal text-black no-underline"
            onClick={() => setModalDelete(false)}
          >
            ไม่
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default InsNotification;
