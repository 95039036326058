import CurrentPackagesFilter from '@components/Filter/CurrentPackagesFilter';
import EverBoughtPackagesFilter from '@components/Filter/EverBoughtPackagesFilter';
import NewDatePicker from '@components/UI/DatePickerRange/NewDatePicker';
import NewSearch from '@components/UI/Search/NewSearch';
import OCCUPATIONS from '@constants/Occupations';
import { useInsUserDatabaseDispatch, useInsUserDatabaseState } from '@context/InsUserDatabase.context';
import { Button, MultiSelect, Select } from '@mantine/core';
import { DateRangePickerValue } from '@mantine/dates';
import { InsUserDatabaseActionType, SortType, StatusType } from '@models/InsUserDatabase.type';
import React from 'react';

const Search = () => {
  const { sort, textSearch, email, occupation, status, dates } = useInsUserDatabaseState();
  const dispatch = useInsUserDatabaseDispatch();

  const handleChangeSort = (value: string): void => {
    dispatch({ type: InsUserDatabaseActionType.SET_SORT, payload: value as SortType });
    dispatch({ type: InsUserDatabaseActionType.CLICK_SEARCH, payload: true });
  };

  const handleClickSearchIcon = (): void => {
    dispatch({ type: InsUserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: InsUserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeTextSearch = (value: string): void => {
    dispatch({ type: InsUserDatabaseActionType.SET_TEXT_SEARCH, payload: value });
  };

  const handleChangeEmail = (value: string): void => {
    dispatch({ type: InsUserDatabaseActionType.SET_EMAIL, payload: value });
    dispatch({ type: InsUserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeStatus = (value: string): void => {
    dispatch({ type: InsUserDatabaseActionType.SET_STATUS, payload: value as StatusType });
    dispatch({ type: InsUserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: InsUserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeOccupation = (value: string[]): void => {
    dispatch({ type: InsUserDatabaseActionType.SET_OCCUPATION, payload: value.map((v) => v) });
    dispatch({ type: InsUserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: InsUserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleClickClearSearch = (): void => {
    dispatch({ type: InsUserDatabaseActionType.CLEAR });
  };

  const handleChangeDate = (value: DateRangePickerValue): void => {
    if (value[0] && value[1]) {
      const startDate = new Date(value[0]);
      startDate.setDate(startDate.getDate() + 1);
      const endDate = new Date(value[1]);
      endDate.setDate(endDate.getDate() + 1);
      dispatch({ type: InsUserDatabaseActionType.DATES, payload: value });
      dispatch({ type: InsUserDatabaseActionType.SET_START_DATE, payload: startDate.toISOString() });
      dispatch({ type: InsUserDatabaseActionType.SET_END_DATE, payload: endDate.toISOString() });
      dispatch({ type: InsUserDatabaseActionType.CLICK_SEARCH, payload: true });
      dispatch({ type: InsUserDatabaseActionType.SET_PAGE, payload: 1 });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <h2 className="w-fit">เรียงลำดับจาก : </h2>
        <Select
          radius="md"
          value={sort}
          onChange={handleChangeSort}
          data={[
            { value: 'LATEST_PAYMENT', label: 'ซื้อล่าสุด' },
            { value: 'TOP_DONATE', label: 'Top Donate' },
            { value: 'INS_MEMBER', label: 'ลำดับแรก-สุดท้าย' },
          ]}
        />
      </div>

      <div className="flex w-full gap-2">
        <div className="w-6/12">
          <NewSearch
            placeholder="ค้นหาชื่อ หรือ userid"
            value={textSearch}
            onChange={(e) => handleChangeTextSearch(e.currentTarget.value)}
            onClickSearch={handleClickSearchIcon}
          />
        </div>
        <div className="w-6/12">
          <NewSearch
            placeholder="ค้นหา email"
            value={email}
            onChange={(e) => handleChangeEmail(e.currentTarget.value)}
            onClickSearch={handleClickSearchIcon}
          />
        </div>
      </div>

      <div className="flex w-full items-center gap-4">
        <div className="w-3/12">
          <NewDatePicker value={dates} onChange={handleChangeDate} placeholder="เลือกช่วงวันที่ซื้อล่าสุด" />
        </div>

        <div className="w-3/12">
          <Select
            placeholder="Status"
            value={status}
            radius="md"
            clearable
            onChange={handleChangeStatus}
            data={[
              { value: 'ACTIVE', label: 'Active' },
              { value: 'EXPIRED', label: 'Expired' },
            ]}
          />
        </div>

        <div className="w-3/12">
          <CurrentPackagesFilter />
        </div>
        <div className="w-3/12">
          <EverBoughtPackagesFilter />
        </div>
        <div className="w-3/12">
          <MultiSelect
            radius="md"
            placeholder="อาชีพ"
            clearable
            value={occupation ? occupation : []}
            data={OCCUPATIONS}
            onChange={handleChangeOccupation}
          />
        </div>
      </div>
      <div className="float-right w-full">
        <Button className="float-right w-fit" variant="subtle" onClick={handleClickClearSearch}>
          ล้างตัวกรอง
        </Button>
      </div>
    </div>
  );
};

export default Search;
