import { createNotificationSchedule, updateNotification } from '@api/notification/notification.service';
import { Button, Checkbox, Select, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { APIResponseError } from '@models/Error';
import {
  NotificationInsType,
  NotificationInsReq,
  NotificationInsRes,
  NotificationType,
  notificationInsTypeProps,
  notificationTypeProps,
} from '@models/NotificationReq';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { finalize } from 'rxjs';

export interface FormNotificationProps {
  noti?: NotificationInsRes;
}

const FormNotification = ({ noti }: FormNotificationProps): React.JSX.Element => {
  const navigate = useNavigate();
  const [err, setErr] = useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const form = useForm<NotificationInsReq>({
    initialValues: {
      id: noti?.id,
      link: noti?.link,
      externalSite: noti?.externalSite,
      message: noti?.message,
      type: noti?.type || NotificationType.NORMAL,
      insType: noti?.insType || NotificationInsType.INS_ONE_YEAR,
    },
    validate: {
      message: (value) => (value ? null : 'พิมพ์ข้อความที่จะขึ้นในแจ้งเตือน'),
      link: (value) => (value ? null : 'กรุณาแนบลิงก์'),
    },
  });

  const navigateToIns = () => navigate('/notification/ins');

  const handleSubmitForm = (values: NotificationInsReq): void => {
    if (form.isValid()) {
      setSubmitting(true);
      if (values.id) {
        updateNotification(values)
          .pipe(finalize(() => setSubmitting(false)))
          .subscribe({
            next: () => navigateToIns(),
            error: (err: AxiosError<APIResponseError>) => {
              setErr(err.response?.data?.error?.message);
            },
          });
      } else {
        createNotificationSchedule(values)
          .pipe(finalize(() => setSubmitting(false)))
          .subscribe({
            next: () => navigateToIns(),
            error: (err: AxiosError<APIResponseError>) => {
              setErr(err.response?.data?.error?.message);
            },
          });
      }
    }
  };

  return (
    <>
      <div className="mb-10">
        <article className="prose">
          <h1 className="mb-0">สร้างแจ้งเตือนชาวอินส์ล่วงหน้า</h1>
        </article>
        ข้อความแจ้งเตือนจะส่งหาชาวอินส์ 1 ปี หรือ ชาวอินส์ทั้งหมด(แล้วแต่กำหนด) อัตโนมัติในวันที่ 27 เวลา 23.59 น. (ตั้งเวลาเองไม่ได้)
      </div>

      <div className="flex justify-center">
        <div className="grid w-2/5 gap-4">
          <form className="grid gap-4" onSubmit={form.onSubmit((v) => handleSubmitForm(v))}>
            <Select
              withAsterisk
              label="ประเภทแจ้งเตือน"
              placeholder="เลือกประเภทแจ้งเตือน"
              data={notificationTypeProps}
              error={form.errors.type}
              {...form.getInputProps('type')}
            />

            <Select
              withAsterisk
              label="เลือกประเภทชาวอินส์"
              placeholder="เลือกกลุ่มชาวอินส์"
              data={notificationInsTypeProps}
              error={form.errors.insType}
              {...form.getInputProps('insType')}
            />

            <Textarea
              withAsterisk
              minRows={4}
              placeholder="พิมพ์ข้อความที่จะขึ้นในแจ้งเตือน"
              label="ข้อความแจ้งเตือน"
              error={form.errors.message}
              {...form.getInputProps('message')}
            />

            <TextInput
              withAsterisk
              label="ลิงก์ที่ต้องการให้คลิกแล้วพาไป"
              placeholder="แนบลิงก์"
              type="url"
              error={form.errors.link}
              {...form.getInputProps('link')}
            />

            <Checkbox label="ลิ้งนอกระบบ" error={form.errors.externalSite} {...form.getInputProps('externalSite', { type: 'checkbox' })} />

            {err && <div className="text-right text-error">{err}</div>}

            <div className="flex justify-end">
              <Button
                type="submit"
                className="rounded-full bg-primary px-4 text-lg font-normal text-black no-underline hover:bg-primary-300"
                disabled={submitting}
              >
                ยืนยันแจ้งเตือน
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormNotification;
