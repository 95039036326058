import bannerService from '@api/banners/banners.service';
import { Button, Checkbox, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { Banner, UploadFileResp } from '@models/Banner';
import { IconCalendar } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { clone } from 'lodash';
import React, { useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { finalize } from 'rxjs';
import UploadImage from './UploadImage';

interface BannerFormProps {
  data?: Banner;
}

export default function BannerForm({ data }: BannerFormProps): React.JSX.Element {
  const navigate = useNavigate();
  const [banner] = useState<Banner>(data || new Banner());
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [cover, setCover] = useState<FileWithPath | string>();

  const startDate = banner?.startDate && dayjs(banner?.startDate).toDate();
  const [minExpireDate, setMinExpireDate] = useState<Date | undefined>(startDate || new Date());

  const form = useForm<Banner>({
    initialValues: {
      id: banner?.id,
      image: banner?.image,
      seq: banner?.seq,
      description: banner?.description,
      startDate: startDate,
      expireDate: banner?.expireDate && dayjs(banner?.expireDate).toDate(),
      link: banner?.link,
      externalSite: banner?.externalSite || false,
      gaTrack: banner?.gaTrack,
    },
    validate: {
      link: (value) => (value ? null : 'กรุณาแนบลิงก์'),
      startDate: (value) => (value ? null : 'เลือกวันที่'),
    },
  });

  const handleChangeStartDate = (value: Date): void => {
    form.setValues((prev) => ({
      ...prev,
      expireDate: undefined,
      startDate: value,
    }));
    const minExpDate = dayjs(value).add(1, 'day').toDate();
    setMinExpireDate(minExpDate);
  };

  const handleSaveForm = (values: Banner): void => {
    if (!values.id) {
      bannerService
        .create(values)
        .pipe(finalize(() => setSubmitting(false)))
        .subscribe(() => navigate('/banners'));
    } else {
      bannerService
        .update(values)
        .pipe(finalize(() => setSubmitting(false)))
        .subscribe(() => navigate('/banners'));
    }
  };

  const handleSubmitForm = (values: Banner): void => {
    if (form.isValid()) {
      setSubmitting(true);

      const data: Banner = clone(values);

      setSubmitting(true);
      if (typeof cover === 'object') {
        bannerService.uploadFile(cover).subscribe({
          next: (resp: UploadFileResp) => {
            data.image = resp.fileName;
            handleSaveForm(data);
          },
          error: () => {
            setSubmitting(false);
          },
        });
      } else {
        handleSaveForm(data);
      }
    }
  };

  return (
    <>
      <div className="mb-10">
        <article className="prose">
          <h1 className="mb-0">เพิ่ม Banner</h1>
        </article>
        <span className="text-error">
          **อย่าลืมย่อขนาดไฟล์ให้เล็กที่สุดเท่าที่จะเป็นไปได้เพื่อคุณภาพในการโหลดด้วยน้า ไม่งั้นภาพไม่โหลดจ้า
        </span>
      </div>

      <div className="flex justify-center pb-10">
        <div className="grid w-2/5 gap-4">
          <form className="grid justify-center gap-4" onSubmit={form.onSubmit((v) => handleSubmitForm(v))}>
            <UploadImage onChangeFile={setCover} image={banner.image} />
            {(form.errors.image || !cover) && <span className="text-error">{form.errors.image}</span>}

            <TextInput label="คำอธิบาย" placeholder="คำอธิบาย" error={form.errors.description} {...form.getInputProps('description')} />

            <div className="grid grid-cols-2 gap-4">
              <DatePicker
                withAsterisk
                label="ตั้งวันที่แสดง"
                placeholder="เลือกวันที่"
                minDate={new Date()}
                icon={<IconCalendar size={16} />}
                {...form.getInputProps('startDate')}
                onChange={handleChangeStartDate}
              />

              <DatePicker
                label="วันที่สิ้นสุด"
                placeholder="เลือกวันที่"
                minDate={minExpireDate}
                icon={<IconCalendar size={16} />}
                {...form.getInputProps('expireDate')}
              />
            </div>

            <TextInput
              withAsterisk
              label="ลิงก์ที่ต้องการให้คลิกแล้วพาไป"
              placeholder="แนบลิงก์"
              error={form.errors.link}
              {...form.getInputProps('link')}
            />
            <Checkbox label="ลิ้งนอกระบบ" error={form.errors.externalSite} {...form.getInputProps('externalSite', { type: 'checkbox' })} />

            <TextInput
              label="กำหนดชื่อ event ที่ต้องการ track ใน GA"
              placeholder="ga track"
              error={form.errors.gaTrack}
              {...form.getInputProps('gaTrack')}
            />

            <div className="flex justify-end">
              <Button
                type="submit"
                className="rounded-full bg-primary px-4 text-lg font-normal text-black no-underline hover:bg-primary-300"
                disabled={submitting}
              >
                {data?.id ? 'บันทึก' : 'เพิ่ม Banner'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
