import useSWR from 'swr';
import axios from '../axios';

const fetcher = (url) => axios.get(url).then((res) => res.data);

export const useLogic = () => {
  const { data } = useSWR('/logic/list', fetcher);

  /**
   *
   * @param {"delete"|"create"|"update"} action
   * @param {*} payload
   * @returns
   */
  const dispatch = (action, payload) => {
    switch (action) {
      case 'create':
        return axios.post('/logic/create', payload);

      case 'delete':
        return axios.post('/logic/delete', payload);

      case 'update':
        return axios.put('/logic/update', payload);

      default:
        return new Promise((resolve) => resolve());
    }
  };

  return {
    data:
      data?.data?.items
        ?.sort((a, b) => a.id - b.id)
        ?.map((item, index) => ({
          index,
          id: item.logicID,
          name: item.name,
          logicTypeID: item.logicTypeID,
          hiddenTags: item?.logicHiddenTags?.map((hiddentag) => hiddentag.hiddenTag) || [],
          logicKeys: item.logicKeys,
          logicGateID: item.logicGateID,
          logicID: item?.logicID,
        })) || [],
    dispatch,
  };
};

export const useHiddenTags = () => {
  const { data } = useSWR('/idea/hidden-tag/list', fetcher);
  return { hiddentag: data?.data.items?.map((item) => item.hiddenTag) || [] };
};

export const useReflection = () => {
  const { data } = useSWR('/logic-type/list', fetcher);
  return {
    reflection:
      data?.data.items?.map((item) => ({
        value: item.logicTypeID.toString(),
        label: item.name,
      })) || [],
  };
};

export const useCondition = () => {
  const { data } = useSWR('/condition-type/list', fetcher);
  return {
    condition:
      data?.data.items?.map((item) => ({
        value: item.conditionTypeID.toString(),
        label: item.name,
      })) || [],
  };
};
