import axiosGolang from '@api/axiosGolang';
import { Banner, BannerSeq, UploadFileResp } from '@models/Banner';
import { Observable, from, map } from 'rxjs';

const adminBanner = '/backoffice/banners';

const list = (): Observable<Banner[]> => {
  return from(axiosGolang.get(`${adminBanner}/list`)).pipe(map((d) => d.data));
};

const byId = (id: number): Observable<Banner> => {
  return from(axiosGolang.get(`${adminBanner}/${id}`)).pipe(map((d) => d.data));
};

const create = (data: Banner): Observable<void> => {
  return from(axiosGolang.post(`${adminBanner}`, data)).pipe(map((d) => d.data));
};
const update = (data: Banner): Observable<void> => {
  return from(axiosGolang.patch(`${adminBanner}/${data.id}`, data)).pipe(map((d) => d.data));
};

const remove = (id: number): Observable<void> => {
  return from(axiosGolang.delete(`${adminBanner}/${id}`)).pipe(map((d) => d.data));
};

const uploadFile = (file: File): Observable<UploadFileResp> => {
  const formData = new FormData();
  formData.append('files', file);
  return from(axiosGolang.post('/client/upload/file', formData)).pipe(map((d) => d.data));
};

const updateSeq = (data: BannerSeq[]): Observable<void> => {
  return from(axiosGolang.post(`${adminBanner}/order`, { data })).pipe(map((d) => d.data));
};

export default { list, byId, create, update, remove, uploadFile, updateSeq };
