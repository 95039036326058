import { Button, Group, Modal, Text, Container, Space } from '@mantine/core';
import React, { useState } from 'react';

export default function DelLogicForm({ label, onConfirm = () => {}, triggerType = 'icon', icon }) {
  const [opened, setopened] = useState(false);
  return (
    <>
      {triggerType === 'icon' && (
        <Button
          classNames={{
            root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
            label: '',
            icon: 'mr-0',
          }}
          styles={{
            leftIcon: {
              marginRight: 15,
            },
          }}
          leftIcon={icon}
          onClick={setopened}
        />
      )}
      <Modal opened={opened} onClose={() => setopened(false)}>
        <Container>
          <Text>{label}</Text>
        </Container>
        <Space h={16} />
        <Group sx={{ justifyContent: 'flex-end' }}>
          <Button
            classNames={{
              root: 'z-40 border rounded-full bg-primary hover:bg-primary-600 active:bg-primary text-black',
              label: '',
            }}
            onClick={() => setopened(false)}
          >
            ยกเลิก
          </Button>

          <Button
            classNames={{
              root: 'z-40 border rounded-full bg-transparent border-red-500 hover:bg-transparent border-black text-black',
              label: 'text-red-500',
            }}
            onClick={() => {
              onConfirm();
              setopened(false);
            }}
          >
            ลบ
          </Button>
        </Group>
      </Modal>
    </>
  );
}
