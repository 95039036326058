export const getAccessToken = (): string | null => {
  return localStorage.getItem('insAccessToken');
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem('insAccessToken', token);
};

export const purgeToken = (): void => {
  localStorage.removeItem('insAccessToken');
  localStorage.removeItem('insRefreshToken');
};
