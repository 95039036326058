import { Group, TextInput, Box, Text, Center, Chip, Container, useMantineTheme } from '@mantine/core';
import { useForm, formList } from '@mantine/form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DotSix } from '../../../assets/icon/dotsSix.svg';
import { ReactComponent as X } from '../../../assets/icon/x.svg';
import styled from 'styled-components';
import { useHiddenTagActions } from '../../../api/presenter/useIdeaDataTable';
import { useLoader } from '../../../context/LoadingOverlay';

const StyledGroup = styled(Group)`
  top: auto !important;
  left: auto !important;
`;

export default function Demo({ form, value, idx, raw, refetchData, closed }) {
  const { actions } = useHiddenTagActions();
  const { setLoading } = useLoader();

  const compose = value.map((item) => ({ name: item }));
  const fields = compose.map((item, index) => {
    return (
      <Draggable key={index} index={index} draggableId={index.toString()}>
        {(provided) => {
          const dragringProvided = provided.draggableProps;
          return (
            <StyledGroup mt="xs" {...dragringProvided} ref={provided.innerRef}>
              <Group noWrap sx={{ width: '100%' }}>
                <Center {...provided.dragHandleProps}>
                  <DotSix />
                </Center>
                <Container
                  sx={(theme) => ({
                    border: `1px solid ${theme.colors.gray[4]}`,
                    width: '100%',
                    borderRadius: theme.radius.xl,
                  })}
                >
                  <Group
                    sx={{
                      justifyContent: 'space-between',
                      [`svg`]: {
                        viewBox: '0 0 12 12',
                      },
                    }}
                    noWrap
                  >
                    <Text viewBox="0 0 12 12">{item.name}</Text>
                    <X
                      onClick={() => {
                        setLoading(true);
                        closed();
                        actions('delete', {
                          ideaID: idx,
                          ideaHiddenTagID: raw[index].ideaHiddenTagID,
                        })
                          .then((res) => {
                            refetchData();
                            new Promise((resolve) => {
                              setTimeout(() => resolve(), 200);
                            }).then(() => {
                              setLoading(false);
                            });
                          })
                          .catch((err) => {
                            setLoading(false);
                          });
                      }}
                      className="cursor-pointer"
                    />
                  </Group>
                </Container>
              </Group>
              {/* <TextInput
                radius="xl"
                size="xs"
                {...form.getListInputProps("employees", index, "name")}
              /> */}
            </StyledGroup>
          );
        }}
      </Draggable>
    );
  });

  return (
    <Box sx={{ maxWidth: 500 }} mx="auto">
      {fields.length > 0 ? (
        <Group mb="xs"></Group>
      ) : (
        <Text color="dimmed" align="center">
          No Tag Here..
        </Text>
      )}

      <DragDropContext
        onDragEnd={({ destination, source }) =>
          form.reorderListItem('employees', {
            from: source.index,
            to: destination.index,
          })
        }
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}
