import API from './api';

const exportedObject = {
  list: () => {
    return API.get(`/coupon/list`).then((res) => res.data.data.items);
  },
  add: ({ code, discountValue, maxMember, expiredAt, couponDiscountTypeID }) => {
    return API.post(`/coupon/create`, {
      code,
      discountValue: Number(discountValue),
      maxMember: Number(maxMember),
      expiredAt,
      couponDiscountTypeID: Number(couponDiscountTypeID),
    }).then((res) => res.data.data);
  },
  update: (data) => {
    return API.put(`/coupon/update`, data).then((res) => res.data.data);
  },
  del: (data) => {
    return API.delete(`/coupon`, data).then((res) => res.data.data);
  },
};
export default exportedObject;
