import React, { Fragment } from 'react';

import { ReactComponent as Download } from '../../../assets/icon/download.svg';

export default function SchoolUpload({ downloadHandler }) {
  return (
    <Fragment>
      <div className="flex items-center" onClick={downloadHandler}>
        <label htmlFor="file-upload" className="bg-red flex h-auto w-auto space-x-1 rounded-full border border-black p-1 text-center">
          <Download className="self-center" />

          <span className="self-center pr-1">export csv.</span>
          {/* <input
            id="file-upload"
            name="file-upload"
            type="file"
            className="sr-only"
          /> */}
        </label>
      </div>
    </Fragment>
  );
}
