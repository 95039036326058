import { getAccessToken } from '@services/token.service';
import axios from 'axios';

export const axiosGolang = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
axiosGolang.interceptors.request.use(
  function (config) {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosGolang.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    return Promise.reject(error);
  }
);

export default axiosGolang;
