// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import HRNumbers from 'human-readable-numbers';

function removeUnits(number: string): string {
  return number.replace(/[^0-9,.]+/, '');
}

export default function NumberFormat(number: number, excludeUnits = false): string {
  if (number < 100000) {
    return Intl.NumberFormat('en-US').format(number);
  }
  const result = HRNumbers.toHumanString(number);
  if (excludeUnits) {
    return removeUnits(result);
  }
  return result;
}
