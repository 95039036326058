import { purgeToken } from './token.service';

// mock login and logout
export function login() {
  // add cookie
  document.cookie = 'swr-test-token=swr;';
}
export function logout() {
  // delete cookie
  document.cookie = 'swr-test-token=; expires=Thu, 01 Jan 2022 00:00:01 GMT;';
  document.cookie = 'refreshToken=; expires=Thu, 01 Jan 2022 00:00:01 GMT;';
  document.cookie = 'accessToken=; expires=Thu, 01 Jan 2022 00:00:01 GMT;';

  purgeToken();
}
