import React from 'react';
import { Modal, Text } from '@mantine/core';
import { useForm } from '@mantine/form';

export default function DelAdminForm({ setOpened, handleSubmit, values, ...props }) {
  const form = useForm({
    initialValues: {
      oldID: values?.oldID || -1,
      fullName: values?.fullName || '',
    },
  });

  return (
    <form
      className=""
      onSubmit={form.onSubmit((values) => {
        handleSubmit(values);
      })}
    >
      <div>ลบบัญชีนี้ ออกจากการเป็น Membership ?</div>
      <Text className="mt-4">userid : {values?.oldID}</Text>
      <Text className="mt-4">ชื่อ-นามสกุล : {values?.fullName}</Text>
    </form>
  );
}
