import DialogCardTable from '../UI/Card/DaialogCardTable';
import React from 'react';
import { Text } from '@mantine/core';
import { ReactComponent as Trash } from '../../assets/icon/trash.svg';
import dayjs from 'dayjs';
const DelPackageForm = ({ values, fetch, handleSubmit }) => {
  return (
    <DialogCardTable buttonIcon={<Trash />} modalTitile="ลบแพคเกจ" onSubmit={handleSubmit}>
      <form>
        <div>การลบแพ็กเกจ ผู้ใช้จะยังคงใช้แพ็กเกจนี้ได้อยู่จนกว่าจะหมดรอบบิล แต่จะไม่สามารถใช้แพ็กเกจนี้ได้อีกในรอบบิลถัดไป</div>
        <Text className="mt-4">
          ประเภทแพ็กเกจ : {values.packageTypeID === 1 ? 'Buddy Kru' : values.packageTypeID === 2 ? 'Workshop' : 'ไม่ระบุ'}
        </Text>
        <Text className="mt-4">ชื่อแพ็กเกจ : {values?.name}</Text>
        <Text className="mt-4">ระยะเวลา (เดือน) : {values?.duration}</Text>
        <Text className="mt-4">ราคา : {values?.price}</Text>
      </form>
    </DialogCardTable>
  );
};

export default DelPackageForm;
