import useSWR from 'swr';
import axios from '../axios';

export const useLogicImage = () => {
  const { data, mutate } = useSWR('/logic-message/type-image/list', (url) => axios.get(url).then((res) => res.data));
  /**
   *
   * @param {"create"|"update"|"delete"} actions
   * @param {*} paylaod
   */
  const dispatch = (actions, payload) => {
    switch (actions) {
      case 'create':
        return axios.post('/logic-message/create', {
          type: 'image',
          ...payload,
        });
      case 'update':
        return axios.put('/logic-message/update', {
          type: 'image',
          ...payload,
        });
      case 'delete':
        return axios.post('/logic-message/delete', {
          logicMessageID: payload.id,
        });
      default:
        return new Promise((resolve) => {
          resolve();
        });
    }
  };

  return {
    data: data?.data?.items?.map((item, index) => ({ ...item, index })) || [],
    dispatch,
    mutate,
  };
};
