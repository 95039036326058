import React, { useState, useMemo, useEffect } from 'react';
import { createTable, getCoreRowModelSync, useTableInstance, getPaginationRowModel } from '@tanstack/react-table';
import { Table, Pagination, Button } from '@mantine/core';

//icon
import { ReactComponent as ArrowLeft } from '../../../assets/icon/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icon/ArrowRight.svg';

export default function TableAdmin({ elements, defaultColumns }) {
  const table = createTable().setRowType();
  const data = React.useMemo(
    () =>
      elements.map((elements) => ({
        ...elements,
      })),
    [elements]
  );
  const columns = useMemo(() => [...defaultColumns], [defaultColumns]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
    pageCount: -1, // -1 allows the table to calculate the page count for us via instance.getPageCount()
  });
  const [page, setPage] = useState(1);

  const instance = useTableInstance(table, {
    data: data,
    columns,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModelSync(),
    getPaginationRowModel: getPaginationRowModel(),
    // meta: {
    //   updateData: (rowIndex, columnId, value) => {
    //     // Skip age index reset until after next rerender
    //     setData((old) =>
    //       old.map((row, index) => {
    //         if (index === rowIndex) {
    //           return {
    //             ...old[rowIndex],
    //             [columnId]: value,
    //           };
    //         }
    //         return row;
    //       })
    //     );
    //   },
    // },
  });

  useEffect(() => {
    instance.setPageIndex(page - 1);
  }, [elements]);

  useEffect(() => {}, [instance]);

  return (
    <div className="mt-4 w-fit rounded-2xl border lg:w-full">
      <Table
        striped
        verticalSpacing="sm"
        fontSize="sm"
        sx={{
          borderSpacing: '0',
          thead: {
            '> tr > th': {
              borderBottom: '0px solid #ffffff !important',
              color: '#34353580',
            },
            '> tr:nth-of-type(even) > th:first-of-type': {
              borderTopLeftRadius: 15,
            },
          },
          tbody: {
            '> tr > td': {
              borderBottom: '0px solid #ffffff !important',
            },
            '> tr:nth-of-type(even) > td:first-of-type': {
              backgroundColor: '#E5F3FA',
              borderTopLeftRadius: 15,
              borderBottomLeftRadius: 15,
            },
            '> tr:nth-of-type(even) > td:last-child': {
              backgroundColor: '#E5F3FA',
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15,
            },
            '> tr:nth-of-type(even)': {
              backgroundColor: '#E5F3FA',
            },
            '> tr:nth-of-type(odd)': {
              backgroundColor: '#ffffff !important',
            },
            '> tr:nth-of-type(odd) > td:first-of-type': {
              backgroundColor: '#ffffff !important',
              borderBottomLeftRadius: 15,
            },
            '> tr:nth-of-type(odd) > td:last-child': {
              backgroundColor: '#ffffff !important',
              borderBottomRightRadius: 15,
            },
          },
        }}
        className="mt-5 border-separate bg-white px-2 pb-2"
        {...instance.getTableProps()}
      >
        <thead>
          {instance.getHeaderGroups().map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((header) => (
                <th {...header.getHeaderProps()}>{header.isPlaceholder ? null : header.renderHeader()}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...instance.getTableBodyProps()}>
          {instance.getRowModel().rows.map((row) => (
            <tr {...row.getRowProps()}>
              {row.getVisibleCells().map((cell) => (
                <td {...cell.getCellProps()}>{cell.renderCell()}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {instance.getOverallProgress() < 1 ? (
        <div className="p-2">
          <div>Loading data...</div>
          <div>
            <progress value={instance.getOverallProgress()} />
          </div>
        </div>
      ) : null}
      <div className="mx-4 my-4 flex justify-end">
        <Button
          className="mr-1 h-10 w-10 rounded-full p-1 text-black transition-colors duration-150 hover:bg-indigo-100 focus:shadow-outline"
          onClick={() => {
            setPage((prev) => {
              if (prev >= 1) {
                instance.previousPage();
                return prev - 1;
              }
            });
            // return instance.previousPage();
          }}
          disabled={!instance.getCanPreviousPage()}
        >
          <ArrowLeft />
        </Button>
        <Pagination
          classNames={{
            item: 'text-black border-0',
            active: 'bg-secondaryPage',
            root: '!text-black',
          }}
          withControls={false}
          page={page}
          onChange={(e) => {
            const page = e ? Number(e) - 1 : 0;
            instance.setPageIndex(page);
            setPage(e);
          }}
          total={instance.getPageCount()}
          radius="xl"
          size="lg"
        />
        <Button
          className="ml-1 h-10 w-10 rounded-full p-1 text-black transition-colors duration-150 hover:bg-indigo-100 focus:shadow-outline"
          onClick={() => {
            setPage((prev) => {
              if (prev + 1 >= 1) {
                instance.nextPage();
                return prev + 1;
              }
            });
          }}
          disabled={!instance.getCanNextPage()}
        >
          <ArrowRight />
        </Button>
      </div>
    </div>
  );
}
