import { Text } from '@mantine/core';
import React from 'react';

export default function DelSchoolForm({ values }) {
  return (
    <form>
      <div>ลบบัญชีนี้ ออกจากการเป็น Membership ?</div>
      <Text className="mt-4">ผู้ใช้งาน : {values?.fullName}</Text>
      <Text className="mt-4">ตำแหน่ง : {values?.schoolRoleID === 2 ? 'ผอ. / ฝ่ายวิชาการ' : 'School Membership'}</Text>
    </form>
  );
}
