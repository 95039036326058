import { Button, Group, Modal } from '@mantine/core';

import React from 'react';

export default function DialogCard({
  modalTitile,
  buttonTitle,
  children,
  buttonIcon,
  buttonType,
  buttonType2,
  status,
  hideControls = false,
  onSubmit = () => {},
  data = {},
}) {
  const [opened, setOpened] = React.useState(false);
  const ChildComponent = () => children;
  return (
    <>
      <Modal
        classNames={{
          title: 'text-base font-semibold text-black-900 lg:text-xl dark:text-black',
          header: 'relative flex justify-center content-center text-center text-black dark:border-gray-600',
          close: 'absolute inset-y-0 right-0 mt-1',
        }}
        closeButtonLabel="Close authentication modal"
        opened={opened}
        onClose={() => setOpened(false)}
        title={modalTitile}
        radius="md"
      >
        {/* Modal content */}
        {/* <ChildComponent /> */}
        {children}
        {!hideControls && (
          <Group position="right" mt="xl">
            <Button
              classNames={{
                root: 'z-40 border rounded-full bg-transparent  hover:bg-transparent border-black text-black',
                label: '',
              }}
              type="button"
              onClick={() => setOpened(false)}
            >
              ยกเลิก
            </Button>
            <Button
              classNames={{
                root: 'z-40 border rounded-full bg-primary hover:bg-primary-600 active:bg-primary text-black',
                label: '',
              }}
              type="submit"
              onClick={() => {
                onSubmit(setOpened);
              }}
            >
              ยืนยัน
            </Button>
          </Group>
        )}
      </Modal>

      <Group position="start">
        <Button
          classNames={{
            root: 'z-40 bg-primary-100 hover:bg-primary-100 rounded-full border-outline text-black',
            label: '',
            icon: 'mr-0',
          }}
          styles={{
            leftIcon: {
              marginRight: 15,
            },
          }}
          disabled={status}
          leftIcon={buttonIcon}
          onClick={() => {
            setOpened(true);
          }}
        >
          {buttonTitle}
        </Button>
      </Group>
    </>
  );
}
