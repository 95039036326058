import { configPlan, donationPlan, donatorById } from '@api/donation/donation.service';
import { ReactComponent as CorrectCircle } from '@assets/icon/correctCircle.svg';
import { Button, Modal, NumberInput, Select, SelectItem } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ResponseError } from '@models/Error';
import { AddInsForm, UserDonator } from '@models/InsMember.type';
import { Plan, PlanType } from '@models/Plan.type';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';

export default function AddIns(): React.JSX.Element {
  const [plans, setPlans] = useState<SelectItem[]>([]);
  const [userDonator, setUserDonator] = useState<UserDonator | undefined>(undefined);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);

  const form = useForm<AddInsForm>({
    initialValues: {
      userId: undefined,
      packageId: undefined,
    },
    validate: {
      userId: (value) => (value ? null : 'กรุณากรอก'),
      packageId: (value) => (value ? null : 'กรุณาเลือก'),
    },
  });

  const planSelected = plans?.find((p) => Number(p.value) == form.values.packageId)?.label;

  useEffect(() => {
    donationPlan().subscribe((resp: Plan[]) => {
      const planSelects: SelectItem[] = resp
        .filter((p) => p.planType === PlanType.PLAN)
        .sort((a, b) => b.priority - a.priority)
        .map((p) => {
          return {
            label: p.name,
            value: String(p.id),
          };
        });
      setPlans(planSelects);
    });
  }, []);

  const handleCheckUserDonatorDetail = (): void => {
    if (form.values.userId) {
      donatorById(form.values.userId).subscribe({
        next: (resp: UserDonator) => setUserDonator(resp),
        error: () => setUserDonator(undefined),
      });
    }
  };

  const clearForm = (): void => {
    form.setValues((prev) => ({ ...prev, userId: undefined }));
    setUserDonator(undefined);
  };

  const handleSubmitForm = (values: AddInsForm): void => {
    const req: AddInsForm = {
      userId: Number(values.userId),
      packageId: Number(values.packageId),
    };
    configPlan(req).subscribe({
      next: () => {
        setOpenModalSuccess(true);
        clearForm();
      },
      error: ({ response }: AxiosError<ResponseError>) => {
        if (response?.data?.error?.message === 'The package must be greater than the current package') {
          form.setFieldError('packageId', 'แพ็คเกจต้องมากกว่าแพ็คเกจปัจจุบัน');
        } else {
          console.error(response?.data);
        }
      },
    });
  };

  return (
    <div className="px-4">
      <div className="mb-10">
        <h4 className="text-4xl">เพิ่มชาวอินส์</h4>
        <span className="text-summaryblack">
          สำหรับให้ของขวัญคุณครูโดยการมอบสิทธิ์ในการเป็นชาวอินส์ในเว็บไซต์ให้
          (สำหรับสมาชิกที่เป็นชาวอินส์อยู่แล้วจะเป็นการเพิ่มอายุชาวอินส์ให้)
        </span>
      </div>

      <div className="flex justify-center">
        <div className="grid w-96 gap-4">
          <form className="grid gap-4" onSubmit={form.onSubmit((v) => handleSubmitForm(v))}>
            <NumberInput
              hideControls
              id="input-user-id"
              label="User ID"
              placeholder="user id"
              min={1}
              error={form.errors.userId}
              {...form.getInputProps('userId')}
            />

            <Select
              withAsterisk
              clearable
              label="เลือกแพ็กเกจ"
              placeholder="เลือกแพ็กเกจ"
              data={plans}
              error={form.errors.packageId}
              {...form.getInputProps('packageId')}
            />

            <div className="flex justify-end">
              <Button
                compact
                variant="outline"
                className="mr-4 rounded-full border-black bg-white px-4 text-black"
                onClick={handleCheckUserDonatorDetail}
              >
                ตรวจสอบข้อมูล
              </Button>
              <Button
                type="submit"
                compact
                className="rounded-full bg-primary px-4 text-black hover:bg-primary-300"
                disabled={!userDonator}
              >
                เพิ่มชาวอินส์
              </Button>
            </div>
          </form>

          {userDonator && (
            <div className="text-gray-400">
              <p>Userid : {userDonator.userId}</p>
              <p>Name : {userDonator.name}</p>
              <p>Alias : {userDonator.alias}</p>
              <p>แพ็กเกจที่เลือก : {planSelected}</p>
              <p>
                Status ชาวอินส์ ปัจจุบัน :{' '}
                {userDonator.status && userDonator.planName ? `${userDonator.status} / ${userDonator.planName}` : ''}
              </p>
            </div>
          )}
        </div>
      </div>

      <Modal centered opened={openModalSuccess} withCloseButton={false} onClose={() => setOpenModalSuccess(false)}>
        <div className="flex flex-col items-center gap-4 text-success-300">
          <CorrectCircle />
          เพิ่มชาวอินส์สำเร็จแล้ว
        </div>
      </Modal>
    </div>
  );
}
