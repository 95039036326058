import React from 'react';
import djaadmin from '../../assets/images/djaadmin.png';

export default function HeroSection() {
  return (
    <section id="hero" className="w-full lg:h-screen" data-aos="fade-in">
      <div className="font-quicksand absolute z-10 flex h-full w-full items-center justify-center overflow-hidden text-center text-black lg:items-start lg:pt-32">
        <div className="-mt-16 lg:-mt-20">
          <div className="mb-2 mt-8">{/* <Breadcrumbs separator=">">{items}</Breadcrumbs> */}</div>
          <div className="z-10 mt-10 flex h-full w-full items-center justify-center tracking-wide">
            <img
              className="text-5xl font-bold tracking-wide md:text-6xl lg:text-8xl"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
              src={djaadmin}
              alt="homepic"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
