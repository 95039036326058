import axiosGolang from '@api/axiosGolang';
import { Pagination } from '@models/Pagination.type';
import { SchoolSuggest, SearchPage } from '@models/School.type';
import { Observable, from, map } from 'rxjs';

const url = '/client/schools';

export const Search = (search: SearchPage): Observable<Pagination<SchoolSuggest>> => {
  return from(axiosGolang.get(url, { params: search })).pipe(map((d) => d.data));
};
