import { donationPlan } from '@api/donation/donation.service';
import { useUserDatabaseDispatch, useUserDatabaseState } from '@context/UserDatabase.context';
import { MultiSelect, SelectItem } from '@mantine/core';
import { Plan, PlanType } from '@models/Plan.type';
import { UserDatabaseActionType } from '@models/UserDatabase.type';
import React, { useEffect, useState } from 'react';

const DonationPlanFilter = () => {
  const { donationPackage } = useUserDatabaseState();
  const dispatch = useUserDatabaseDispatch();
  const [options, setOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    donationPlan().subscribe((p: Plan[]) => {
      const plans = p.filter((t) => t.planType !== PlanType.TIPS);
      const op = plans.map((plan) => ({ value: plan.id.toString(), label: plan.name }));
      setOptions(op);
    });
  }, []);

  const handleChangePackage = (value: string[]): void => {
    dispatch({ type: UserDatabaseActionType.SET_DONATION_PACKAGE, payload: value.map((v) => parseInt(v)) });
    dispatch({ type: UserDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: UserDatabaseActionType.SET_PAGE, payload: 1 });
  };

  return (
    <MultiSelect
      onChange={handleChangePackage}
      value={donationPackage?.map((p) => p.toString()) || []}
      nothingFound="No options"
      searchable
      clearable
      placeholder="ชาวอินส์"
      data={options}
    />
  );
};

export default DonationPlanFilter;
