import { SelectItem } from '@mantine/core';

export enum NotificationInsType {
  INS = 'INS',
  INS_ONE_YEAR = 'INS_ONE_YEAR',
}

export enum NotificationType {
  NORMAL = 'NORMAL',
  GIFT = 'GIFT',
}

export enum NotificationStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
}

export enum NotificationUserType {
  BROADCAST = 'broadcast',
  CREATED_IDEA = 'createdIdea',
  INS = 'ins',
  INS_ONE_YEAR = 'insOneYear',
  INS_EXPIRED = 'insExpired',
}

export const notificationTypeProps: SelectItem[] = [
  {
    label: 'แจ้งเตือน',
    value: NotificationType.NORMAL,
  },
  {
    label: 'กล่องของขวัญชาวอินส์',
    value: NotificationType.GIFT,
  },
];

export const notificationInsTypeProps: SelectItem[] = [
  {
    label: 'ชาวอินส์ 1 ปี',
    value: NotificationInsType.INS_ONE_YEAR,
  },
  {
    label: 'ชาวอินส์ทั้งหมด',
    value: NotificationInsType.INS,
  },
];

export const notificationTargetProps: SelectItem[] = [
  {
    label: 'สมาชิกเว็บไซต์ทั้งหมด',
    value: NotificationUserType.BROADCAST,
  },
  {
    label: 'นักเขียน',
    value: NotificationUserType.CREATED_IDEA,
  },
  {
    label: 'ชาวอินส์ 1 ปี',
    value: NotificationUserType.INS_ONE_YEAR,
  },
  {
    label: 'ชาวอินส์ทั้งหมด',
    value: NotificationUserType.INS,
  },
  {
    label: 'ชาวอินส์ที่หมดอายุแล้ว',
    value: NotificationUserType.INS_EXPIRED,
  },
];

export interface NotificationInsReq {
  id: number | undefined;
  link: string | undefined;
  externalSite: boolean | undefined;
  message: string | undefined;
  type: NotificationType | undefined;
  insType: NotificationInsType | undefined;
}

export interface NotificationInsRes {
  id: number;
  message: string;
  link: string;
  externalSite: boolean;
  type: NotificationType;
  insType: NotificationInsType;
  createdAt: Date;
  updatedAt: Date;
  status: NotificationStatus;
}

export interface NotificationUserReq {
  type: NotificationType;
  target: NotificationUserType;
  message: string | undefined;
  link: string | undefined;
  externalSite: boolean | undefined;
}
