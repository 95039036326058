import { SelectItem } from '@mantine/core';
import { ApplicationChannelType } from '@models/UserDatabase.type';

export const APPLICATION_CHANNELS: SelectItem[] = [
  {
    value: ApplicationChannelType.FACEBOOK,
    label: 'Facebook',
  },
  {
    value: ApplicationChannelType.EMAIL,
    label: 'Email',
  },
];
