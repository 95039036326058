/**
 *
 * @param {string} url baseurl
 * @param {{any}} paramObject params object Can be null or undefined
 * @returns {string} url
 */
export const paramsBuilder = (url, paramObject) => {
  const tmp = paramObject;
  Object.keys(tmp).forEach((k) => {
    switch (typeof tmp[k]) {
      case 'number':
        if (tmp[k] < 0) delete tmp[k];
        break;

      case 'string':
        if (!tmp[k].length) delete tmp[k];
        break;

      case 'boolean':
        break;

      default:
        if (tmp[k] == null) delete tmp[k];
        break;
    }
  });
  const params = new URLSearchParams({
    ...{ ...tmp },
  }).toString();

  return `${url}?${params}`;
};

export const ommitNull = (paramObject) => {
  const tmp = paramObject;
  Object.keys(tmp).forEach((k) => {
    switch (typeof tmp[k]) {
      case 'number':
        if (tmp[k] < 0) delete tmp[k];
        break;

      case 'string':
        if (!tmp[k].length) delete tmp[k];
        break;

      case 'boolean':
        break;

      default:
        if (tmp[k] == null) delete tmp[k];
        break;
    }
  });

  return { ...tmp };
};
