import axios from '../axios';
import useSWR from 'swr';

const shouldImplement = () => {
  return new Promise((resolve) => {
    resolve();
  });
};

export const useManageAdmin = () => {
  const { data, mutate } = useSWR('/admin/list', (url) => axios.get(url).then((res) => res.data));
  /**
   * @param {"create"|"update"|"delete"} action
   */
  const dispatch = (action, payload) => {
    switch (action) {
      case 'create':
        return axios.post('/admin/create', payload);

      case 'update':
        return axios.put('/admin/update', payload);

      case 'delete':
        return axios.post('/admin/delete', payload);

      default:
        return shouldImplement();
    }
  };
  return { data: data?.data?.items || [], mutate, dispatch };
};
