import { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';

// import useSWR from "swr";
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [{ accessToken, refreshToken }, setCookie] = useCookies(['insku_auth']);
  const [auth, setAuth] = useState(accessToken && refreshToken ? { accessToken, refreshToken } : {});
  // const { data, mutate, error } = useSWR("auth", auth, {
  //     refreshInterval: 1000,
  //   });

  return <AuthContext.Provider value={{ auth, setAuth, setCookie }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
