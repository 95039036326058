import AddSchoolForm from '../SchoolManage/SchoolForm/AddSchoolForm';
import { Box } from '@mantine/core';
import DelSchoolForm from '../SchoolManage/SchoolForm/DelSchoolForm';
import DialogCard from '../../UI/Card/DaialogCard';
import DialogCardTable from '../../UI/Card/DaialogCardTable';
import LinkUI from '../../UI/Link/LinkUI';
import { ReactComponent as Pencil } from '../../../assets/icon/pencil.svg';
import React from 'react';
import SchoolOption from './SchoolOption';
import SchoolUpload from './SchoolUpload';
import Table from '../../UI/Table/Table';
import { createTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import download from 'downloadjs';
import { parse } from 'json2csv';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const SCHOOL_GROUP_LIST_URL = '/school-group/list';

export default function Schoolmanage() {
  const [elements, setElements] = React.useState([null]);
  const [option, setOption] = React.useState({
    affilition: '',
    province: '',
    status: '',
    searchText: '',
  });

  const axiosPrivate = useAxiosPrivate();

  React.useEffect(() => {
    schoolGroupListFetch();
  }, [option]);

  const schoolGroupListFetch = async () => {
    const response = await axiosPrivate.post(SCHOOL_GROUP_LIST_URL, {
      ...option,
      status: option?.status === 'active' ? true : option?.status === 'deactivated' ? false : null,
    });
    setElements(response.data.data.items);
  };
  const table = createTable().setRowType();

  const defaultColumns = table.createColumns([
    table.createDataColumn((row) => row.membershipStartAt, {
      id: 'startDate',
      cell: (info) => dayjs(info.value).format('DD/MM/YY'),
      header: () => <span>เริ่ม</span>,
    }),
    table.createDataColumn((row) => row.membershipEndAt, {
      id: 'endDate',
      cell: (info) => dayjs(info.value).format('DD/MM/YY'),
      header: () => <span>หมดอายุ</span>,
    }),
    table.createDataColumn((row) => ({ name: row.name, schoolGroupID: row.schoolGroupID }), {
      id: 'name',
      cell: (info) => {
        return <LinkUI href={info.value.schoolGroupID} name={info.value.name} />;
      },
      header: () => <span>กลุ่มโรงเรียน</span>,
    }),
    table.createDataColumn((row) => row.dashBoard, {
      id: 'dashBoard',
      cell: (info) => (
        <>
          <LinkUI href={`${process.env.REACT_APP_NEWSITE_URL}/schooldashboard/${info.row.original.schoolGroupID}`} />
        </>
      ),
      header: () => <span>แดชบอร์ดโรงเรียน</span>,
    }),
    table.createDataColumn((row) => row.affilition, {
      id: 'affilition',
      cell: (info) => info.value,
      header: () => <span>สังกัด</span>,
    }),
    table.createDataColumn((row) => row.province, {
      id: 'province',
      cell: (info) => info.value,
      header: () => <span>จังหวัด</span>,
    }),
    table.createDataColumn((row) => row.totalTeacher, {
      id: 'totalTeacher',
      cell: (info) => info.value ?? 0,
      header: () => <span>จำนวนครู</span>,
    }),
    table.createDataColumn((row) => row.totalPoor, {
      id: 'totalPoor',
      cell: (info) => info.value ?? 0,
      header: () => <span>จำนวนผอ.</span>,
    }),
    table.createDataColumn((row) => row.total, {
      id: 'total',
      cell: (info) => info.value ?? 0,
      header: () => <span>ทั้งหมด</span>,
    }),
    table.createDataColumn((row) => row.isActive, {
      id: 'code',
      cell: (info) => (
        <>
          {/* {info.value} */}
          {(() => {
            if (!info.row.original.membershipEndAt) return 'รอต่ออายุ';
            if (dayjs(info.row.original.membershipEndAt).diff(dayjs(), 'minutes') < 0) {
              return 'รอต่ออายุ';
            }
            return 'membership';
          })()}
        </>
      ),
      header: () => <span>สถานะ</span>,
    }),

    table.createDataColumn((row) => row.id, {
      id: 'id',
      cell: (info) => {
        return (
          <div className="flex justify-center">
            <AddSchoolForm
              Dialog={DialogCardTable}
              buttonIcon={<Pencil />}
              modalTitile="แก้ไขกลุ่มโรงเรียน"
              values={info.row.original}
              fetch={schoolGroupListFetch}
            ></AddSchoolForm>
          </div>
        );
      },
      header: () => <span></span>,
    }),
  ]);

  const exportCSVHandler = () => {
    const headers = [
      'เริ่ม',
      'หมดอายุ',
      'กลุ่มโรงเรียน',
      'แดชบอร์ดโรงเรียน',
      'สังกัด',
      'จังหวัด',
      'จำนวนครู',
      'จำนวนผอ',
      'ทั้งหมด',
      'สถานะ',
    ];

    const lists =
      elements?.schoolGroup.map((i) => ({
        [`เริ่ม`]: dayjs(i.membershipStartAt).format('DD/MM/YY'),
        [`หมดอายุ`]: dayjs(i.membershipEndAt).format('DD/MM/YY'),
        [`กลุ่มโรงเรียน`]: i.name || '-',
        [`แดชบอร์ดโรงเรียน`]: `${process.env.REACT_APP_NEWSITE_URL}/schooldashboard/${i.schoolGroupID}`,
        [`สังกัด`]: i.affilition,
        [`จังหวัด`]: i.province,
        [`จำนวนครู`]: i.totalTeacher,
        [`จำนวนผอ.`]: i.totalPoor,
        [`ทั้งหมด`]: i.total,
        [`สถานะ`]: (() => {
          if (!i.membershipEndAt) return 'รอต่ออายุ';
          if (dayjs(i.membershipEndAt).diff(dayjs(), 'minutes') < 0) {
            return 'รอต่ออายุ';
          }
          return 'membership';
        })(),
      })) || {};
    const csv = parse(lists, headers);
    download(
      csv,
      `แพ็คเกจกลุ่มโรงเรียน-จำนวน${elements?.schoolGroup?.length || '0'}กลุ่ม-คุณครู${elements.sumTeacher || '0'}คน-ผอ.${
        elements.sumPoor || '0'
      }คน-${dayjs().format('DD-MM-YYYY_HH-mm-ss')}.csv`,
      'text/csv'
    );
  };
  return (
    <div>
      <div className="text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">แพ็คเกจกลุ่มโรงเรียน</div>
      <div className="mt-8 inline-flex space-x-7">
        <AddSchoolForm
          Dialog={DialogCard}
          buttonTitle="+ เพิ่มกลุ่มโรงเรียน"
          modalTitile="เพิ่มกลุ่มโรงเรียน"
          fetch={schoolGroupListFetch}
        ></AddSchoolForm>
        <SchoolUpload downloadHandler={exportCSVHandler}></SchoolUpload>
      </div>
      {/* option */}
      <SchoolOption option={option} setOption={setOption}></SchoolOption>
      <div className="flex">
        <div className="mr-2 mt-8 text-sm text-[#6A6A6A]">จำนวน {elements?.schoolGroup?.length || '0'} กลุ่ม</div>
        <div className="mr-2 mt-8 text-sm text-[#6A6A6A]">คุณครู {elements.sumTeacher || '0'} คน</div>
        <div className="mr-2 mt-8 text-sm text-[#6A6A6A]">ผอ. {elements.sumPoor || '0'} คน</div>
      </div>
      {/* table */}
      <Box className="">
        <Table elements={elements?.schoolGroup ?? []} defaultColumns={defaultColumns} />
      </Box>
    </div>
  );
}
