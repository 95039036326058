import React from 'react';
import Select from '../../UI/Selector/Select';
import DateRange from '../../UI/DatePickerRange/DatePickerRange';
import Search from '../../UI/Search/Search';
import provinces from '../../../constants/provinces';
import affiliations from '../../../constants/affiliations';
export default function SchoolOption({ setOption, option }) {
  return (
    <div className="mt-8 space-y-4">
      {/* <div className="">
        <div className="max-w-sm">
          <DateRange label="" placeholder="เลือกวันที่เริ่ม - หมดอายุ"></DateRange>
        </div>
      </div> */}
      <div className="flex flex-row justify-center space-x-8">
        <div className="max-w-[174px]">
          <Select
            placeholder="สังกัด"
            data={affiliations}
            // {...form.getInputProps("affilition")}
            value={option.affilition}
            onChange={(event) => {
              setOption({ ...option, affilition: event });
            }}
            clearable
          ></Select>
        </div>
        <div className="max-w-[174px]">
          <Select
            placeholder="จังหวัด"
            data={provinces}
            value={option.province}
            onChange={(event) => {
              setOption({ ...option, province: event });
            }}
            clearable
          ></Select>
        </div>
        <div className="basis-1/6">
          <Select
            placeholder="สถานะ"
            data={[
              { label: 'membership', value: 'active' },
              { label: 'รอต่ออายุ', value: 'deactivated' },
            ]}
            value={option.status}
            onChange={(event) => {
              setOption({ ...option, status: event });
            }}
            clearable
          ></Select>
        </div>
        <div className="basis-1/2">
          <Search
            placeholder="ค้นหากลุ่มโรงเรียนหรือคูปองที่ใช้"
            value={option.searchText}
            onChange={(event) => {
              setOption({ ...option, searchText: event.target.value });
            }}
            clearable
          ></Search>
        </div>
        <div className="basis-1/3"></div>
      </div>
    </div>
  );
}
