import { ActionIcon, Anchor, Box, Button, Grid, Group, Image, ScrollArea, Select, Space, Text, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useDebouncedValue, useShallowEffect } from '@mantine/hooks';
import { useManageMember, useSchoolList } from '../../../api/presenter/useManageMember';

import AddMemberForm from './MemberForm/AddMemberForm';
import DateRange from '../../UI/DatePickerRange/DatePickerRange';
import DelMemberForm from './MemberForm/DelMemberForm';
import DialogCard from '../../UI/Card/DaialogCard';
import DialogCardTable from '../../UI/Card/DaialogCardTable';
import { ReactComponent as Download } from '../../../assets/icon/download.svg';
import { ReactComponent as Link } from '../../../assets/icon/link.svg';
import LinkUI from '../../UI/Link/LinkUI';
import MemberOption from './MemberOption';
import { ReactComponent as Pencil } from '../../../assets/icon/pencil.svg';
import { ReactComponent as Plus } from '../../../assets/icon/Plus.svg';
import SchoolUpload from '../SchoolManage/SchoolUpload';
import Search from '../../UI/Search/Search';
import Swal from 'sweetalert2';
import Table from '../../UI/Table/Table';
import TableGlobal from '../../Table/TableGlobal';
import { ReactComponent as Trash } from '../../../assets/icon/trash.svg';
import { ReactComponent as Unverified } from '../../../assets/icon/unverified.svg';
import UserIdUI from '../../UI/User/UserId';
import { ReactComponent as Verified } from '../../../assets/icon/verified.svg';
import { createTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import download from 'downloadjs';
import { parse } from 'json2csv';
import { usePackageList } from '../../../api/presenter/usePackage';
import withReactContent from 'sweetalert2-react-content';
import DelAdminForm from './MemberForm/DelMemberForm';

const ClearIcon = ({ ...props }) => (
  <Box {...props}>
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <path
        d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
      ></path>
    </svg>
  </Box>
);

const UserPosition = ({ row }) => {
  return (
    <Text className="text" sx={{ color: 'rgba(53,53,53,0.33)' }}>
      {row.schoolRoleID === 2 && 'ผอ. / ฝ่ายวิชาการ'}
      {row.schoolRoleID === 1 && 'School Membership'}
      {row.schoolRoleID !== 1 && row.schoolRoleID !== 2 && 'Membership'}
    </Text>
  );
};

export default function MemberManage() {
  const [dateTimeFilter, setdateTimeFilter] = useState([]);
  const [packageNames, setpackageNames] = useState({});
  const [isIntialized, setisIntialized] = useState(false);

  const [openedAdd, setOpenedAdd] = useState(false);

  const [aliasFilter, setaliasFilter] = useState('');
  const [levelTagFilter, setLevelTagFilter] = useState(null);
  const [subjectTagFilter, setSubjectTagFilter] = useState(null);
  const [occupationFilter, setoccupationFilter] = useState(null);
  const [schoolFilter, setschoolFilter] = useState(null);
  const [provinceFilter, setprovinceFilter] = useState(null);
  const [packageFilter, setPackageFilter] = useState(null);
  const [schoolRoleID, setschoolRoleID] = useState(null);

  const [alias] = useDebouncedValue(aliasFilter, 800);
  const [occupation] = useDebouncedValue(occupationFilter, 800);
  const [school] = useDebouncedValue(schoolFilter, 800);
  const [province] = useDebouncedValue(provinceFilter, 800);

  const { data, length, dispatch, mutate } = useManageMember({
    alias,
    occupation,
    school,
    province,
    schoolRoleID: schoolRoleID === '0' ? 0 : parseInt(schoolRoleID) || null,
    packageGroupID: parseInt(packageFilter) || null,
    ...(() => {
      if (!dateTimeFilter[0] | !dateTimeFilter[1]) return {};
      return {
        startExpirationDate: dateTimeFilter?.[0] || null,
        endExpirationDate: dateTimeFilter?.[1] || null,
      };
    })(),
    // tag: tagFilter || null,
    levelTag: levelTagFilter !== [''] ? levelTagFilter?.split(' ') : null || null,
    subjectTag: subjectTagFilter !== [''] ? subjectTagFilter?.split(' ') : null || null,
  });
  const { data: schoolListData } = useSchoolList();
  const { data: packageList } = usePackageList();

  useEffect(() => {
    if (!isIntialized && packageList) {
      let tmp = {};
      packageList?.map((item) => {
        tmp[item.packageID] = item.name;
        return tmp;
      });
      setpackageNames(tmp);
    }
  }, [packageList?.length]);

  const [openedDel, setOpenedDel] = useState(false);
  const [openedEdit, setOpenedEdit] = useState(false);
  const [openedRowEdit, setOpenedRowEdit] = useState(1);
  const [openedRowDel, setOpenedRowDel] = useState(null);

  function handleAddMemberManage({ oldID, packageID }) {
    dispatch('create', {
      oldID: parseInt(oldID),
      packageID: parseInt(packageID),
    })
      .then((res) => {
        setOpenedAdd(false);
        mutate();
        MySwal.fire({
          icon: 'success',
          title: 'สร้าง Membership สำเร็จ!',
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        setOpenedAdd(false);
        MySwal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(err);
      });
  }

  function handleDelMemberManage(values) {
    console.log(values);
  }

  function handleEditMemberManage(value) {
    console.log({ value });
  }

  const MySwal = withReactContent(Swal);

  const columns = [
    {
      name: 'สถานะ',
      width: '80px',
      center: true,
      selector: (row) => (
        <>
          {/* // ใช้ icon compt ละมันทับกัน งงแตก */}
          {dayjs(row.expirationDate).diff(dayjs(), 'minutes') > 0 ? <Verified /> : <Image src="/asset/icon/unverified.svg" width={20} />}
        </>
      ),
    },
    {
      name: 'แพ็คเกจ',
      width: '140px',
      selector: (row) => <>{packageNames?.[row.packageID] || 'School Package'}</>,
    },
    {
      name: 'เริ่ม',
      width: '100px',
      selector: (row) => (row.codeActiveDate ? dayjs(row.codeActiveDate).format('DD/MM/YY') : dayjs(row.createdAt).format('DD/MM/YY')),
    },
    {
      name: 'หมดอายุ',
      width: '100px',
      selector: (row) => dayjs(row.expirationDate).format('DD/MM/YY'),
    },
    {
      name: 'ตำแหน่ง',
      width: '180px',
      selector: (row) => <UserPosition row={row} />,
    },
    {
      name: 'userid',
      width: '120px',
      center: true,
      selector: (row) => <UserIdUI userId={row.oldID} />,
    },
    {
      name: 'ชื่อ-นามสกุล',
      width: '140px',
      selector: (row) => row.fullName,
    },
    {
      name: 'หน้าเป้าหมาย',
      width: '100px',
      selector: (row) => (
        <>
          <Anchor href={`${process.env.REACT_APP_OLDSITE_URL}/profile/${row.oldID}?tab=owngoal`} target="_blank">
            <Group spacing={0} noWrap>
              <Text>link</Text>
              <Link className="mx-2 self-center" />
            </Group>
          </Anchor>
        </>
      ),
    },
    {
      name: 'อาชีพ',
      width: '100px',
      selector: (row) => row.occupation || 'ไม่ระบุ',
    },
    {
      name: 'โรงเรียน',
      width: '100px',
      selector: (row) => row.school || 'ไม่ระบุ',
    },
    {
      name: 'จังหวัด',
      width: '100px',
      selector: (row) => row.province || 'ไม่ระบุ',
    },
    {
      name: 'วิชาที่สอน',
      width: '130px',
      selector: (row) => (
        <>
          <div className="flex max-w-xs flex-nowrap">
            <ScrollArea>
              <Space h={8} />
              {row?.subjects?.map((item, idx) => {
                return (
                  <Button
                    key={`subject-${row?.oldID}-${idx}`}
                    className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline"
                  >
                    {item}
                  </Button>
                );
              })}
              <Space h={12} />
            </ScrollArea>
          </div>
        </>
      ),
    },
    {
      name: 'ระดับชั้น',
      width: '130px',
      selector: (row) => (
        <>
          <div className="flex max-w-xs flex-nowrap">
            <ScrollArea>
              <Space h={8} />
              {row?.levels?.map((item, idx) => {
                return (
                  <Button
                    key={`subject-${row?.oldID}-${idx}`}
                    className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline"
                  >
                    {item}
                  </Button>
                );
              })}
              <Space h={12} />
            </ScrollArea>
          </div>
        </>
      ),
    },
    {
      name: '',
      width: '100px',
      right: true,
      selector: (row) => (
        <>
          <Group spacing={8} noWrap>
            {/* <ActionIcon
              variant="outline"
              radius={24}
              styles={{
                root: {
                  width: "120px !important",
                  backgroundColor: "#FBF8EF !important",
                  border: "1px solid#E5E5E5",
                },
              }}
            >
              <Pencil />
            </ActionIcon> */}
            {/* <ActionIcon
              variant="outline"
              radius={24}
              styles={{
                root: {
                  width: "120px !important",
                  backgroundColor: "#FBF8EF !important",
                  border: "1px solid#E5E5E5",
                },
              }}
            >
              <Trash />
            </ActionIcon> */}
            <DialogCardTable
              buttonIcon={<Trash />}
              modalTitile="ลบ Membership"
              setOpened={setOpenedDel}
              setOpenedRow={setOpenedRowDel}
              onSubmit={(setOpened) => {
                const { oldID } = row;
                dispatch('delete', { oldID })
                  .then((res) => {
                    setOpened(false);
                    mutate();
                    MySwal.fire({
                      icon: 'success',
                      title: 'ลบ Membership สำเร็จ!',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch((err) => {
                    setOpened(false);
                    MySwal.fire({
                      icon: 'error',
                      title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    console.log(err);
                  });
              }}
            >
              <DelMemberForm values={row}></DelMemberForm>
            </DialogCardTable>
          </Group>
        </>
      ),
    },
  ];

  const table = createTable().setRowType();

  const defaultColumns = table.createColumns([
    table.createDataColumn((row) => row.startDate, {
      id: 'startDate',
      cell: (info) => info.value,
      header: () => <span>เริ่ม</span>,
    }),
    table.createDataColumn((row) => row.endDate, {
      id: 'endDate',
      cell: (info) => info.value,
      header: () => <span>หมดอายุ</span>,
    }),
    table.createDataColumn((row) => row.position, {
      id: 'position',
      cell: (info) => <div className="font-thin text-gray-400">{info.value}</div>,
      header: () => <span>ตำแหน่ง</span>,
    }),
    table.createDataColumn((row) => row.userid, {
      id: 'userid',
      cell: (info) => <UserIdUI userId={info.value} />,
      header: () => <span>userid</span>,
    }),
    table.createDataColumn((row) => row.userName, {
      id: 'userName',
      cell: (info) => info.value,
      header: () => <span>ชื่อ-นามสกุล</span>,
    }),
    table.createDataColumn((row) => row.userGoal, {
      id: 'userGoal',
      cell: (info) => <LinkUI href={`${process.env.REACT_APP_OLDSITE_URL}/profile/${info.value}`} />,
      header: () => <span>หน้าเป้าหมาย</span>,
    }),
    table.createDataColumn((row) => row.userJob, {
      id: 'userJob',
      cell: (info) => info.value,
      header: () => <span>อาชีพ</span>,
    }),
    table.createDataColumn((row) => row.userSchool, {
      id: 'userSchool',
      cell: (info) => info.value,
      header: () => <span>โรงเรียน</span>,
    }),
    table.createDataColumn((row) => row.provinces, {
      id: 'provinces',
      cell: (info) => info.value,
      header: () => <span>จังหวัด</span>,
    }),
    table.createDataColumn((row) => row.userSubject, {
      id: 'userSubject',
      cell: (info) => (
        <>
          <div className="flex max-w-xs flex-nowrap">
            <div>
              <Button className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline">
                {info.value}
              </Button>
            </div>
            <div>
              <Button className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline">
                {info.value}
              </Button>
            </div>
          </div>
        </>
      ),
      header: () => <span>วิชาที่สอน</span>,
    }),
    table.createDataColumn((row) => row.userClass, {
      id: 'userClass',
      cell: (info) => (
        <>
          <div className="flex max-w-xs flex-nowrap">
            <div>
              <Button className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline">
                {info.value}
              </Button>
            </div>
            <div>
              <Button className="mr-1 h-8 w-auto rounded-full bg-secondary text-xs text-white transition-colors duration-150 hover:bg-secondary focus:shadow-outline">
                {info.value}
              </Button>
            </div>
          </div>
        </>
      ),
      header: () => <span>ระดับชั้น</span>,
    }),
    table.createDataColumn((row) => row.id, {
      id: 'id',
      cell: (info) => (
        <div className="flex justify-end space-x-2">
          <DialogCardTable
            buttonIcon={<Pencil />}
            modalTitile="เพิ่ม Membership"
            setOpened={setOpenedEdit}
            opened={openedEdit && info.row.index === openedRowEdit}
            row={info.row}
            setOpenedRow={setOpenedRowEdit}
          >
            <AddMemberForm values={info.row.values} handleSubmit={handleEditMemberManage} setOpened={setOpenedEdit}></AddMemberForm>
          </DialogCardTable>
          <DialogCardTable
            buttonIcon={<Trash />}
            modalTitile="ลบ Membership"
            setOpened={setOpenedDel}
            opened={openedDel && info.row.index === openedRowDel}
            row={info.row}
            setOpenedRow={setOpenedRowDel}
            onSubmit={() => {
              console.log(info);
            }}
          >
            <DelMemberForm values={info.row.values} handleSubmit={handleDelMemberManage} setOpened={setOpenedDel}></DelMemberForm>
          </DialogCardTable>
        </div>
      ),
      header: () => <span></span>,
    }),
  ]);

  const exportCSVHandler = () => {
    const headers = [
      'สถานะ',
      'แพ็คเกจ',
      'เริ่ม',
      'หมดอายุ',
      'ตำแหน่ง',
      'userid',
      'ชื่อ-นามสกุล',
      'อาชีพ',
      'โรงเรียน',
      'จังหวัด',
      'วิชาที่สอน',
      'ระดับชั้น',
    ];
    const lists =
      data?.map((i) => ({
        [`สถานะ`]: dayjs(i.expirationDate).diff(dayjs(), 'minutes') > 0 ? 'เปิดใช้งาน' : 'หมดอายุ',
        [`แพ็คเกจ`]: packageNames?.[i.packageID] || 'School Package',
        [`เริ่ม`]: i.codeActiveDate ? dayjs(i.codeActiveDate).format('DD/MM/YY') : dayjs(i.createdAt).format('DD/MM/YY'),
        [`หมดอายุ`]: i.expirationDate ? dayjs(i.expirationDate).format('DD/MM/YY') : dayjs(i.createdAt).format('DD/MM/YY'),
        [`ตำแหน่ง`]: (() => {
          switch (i.schoolRoleID) {
            case 1:
              return 'School Membership';
            case 2:
              return 'ผอ. / ฝ่ายวิชาการ';
            default:
              return 'Membership';
          }
        })(),
        [`userid`]: i.oldID,
        [`ชื่อ-นามสกุล`]: i.fullName,
        [`อาชีพ`]: i.occupation || 'ไม่ระบุ',
        [`โรงเรียน`]: i.school || 'ไม่ระบุ',
        [`จังหวัด`]: i.province || 'ไม่ระบุ',
        [`วิชาที่สอน`]: i.subjects.join(',') || '',
        [`ระดับชั้น`]: i.levels.join(',') || '',
      })) || {};

    const csv = parse(lists, headers);
    download(csv, `member-exported-${dayjs().format('DD-MM-YYYY_HH-mm-ss')}.csv`, 'text/csv');
  };

  return (
    <div>
      <Grid>
        <Grid.Col span={5}>
          <Grid>
            <Grid.Col span={11}>
              <Group spacing={16} noWrap>
                <Button
                  classNames={{
                    root: 'z-40 rounded-full',
                    label: 'text-black text-[#2D2E2E]',
                    icon: 'mr-0',
                    filled: 'bg-primary hover:bg-primary-600 active:bg-primary',
                  }}
                  leftIcon={<Plus />}
                  fullWidth
                  onClick={() => setOpenedAdd(true)}
                >
                  &nbsp;&nbsp; เพิ่ม Membership
                </Button>
                <Button
                  classNames={{
                    root: 'z-40 rounded-full',
                    label: 'text-black text-[#2D2E2E]',
                    icon: 'mr-0',
                    filled: 'bg-primary hover:bg-primary-600 active:bg-primary',
                    outline: 'border-gray-700',
                  }}
                  variant="outline"
                  leftIcon={<Download />}
                  fullWidth
                  onClick={exportCSVHandler}
                >
                  &nbsp;&nbsp; export csv.
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col xl={4} md={4}>
              <DateRange label="" value={dateTimeFilter} onChange={setdateTimeFilter} placeholder="เลือกวันที่เริ่ม - หมดอายุ" fullWidth />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Group>
            <Select
              placeholder="แพ็คเกจ"
              values={packageFilter}
              data={[
                {
                  label: `Kru Solo`,
                  value: '1',
                },
                {
                  label: `Kru Plus`,
                  value: '2',
                },
                {
                  label: `Family Plus`,
                  value: '3',
                },
                {
                  label: `School`,
                  value: '4',
                },
              ]}
              onChange={setPackageFilter}
              clearable
            />
            <Select
              placeholder="ตำแหน่ง"
              values={schoolRoleID}
              data={[
                {
                  label: `ผอ./ฝ่ายวิชาการ`,
                  value: '2',
                },
                {
                  label: `School Membership`,
                  value: '1',
                },
                {
                  label: `Membership`,
                  value: '0',
                },
              ]}
              onChange={setschoolRoleID}
              clearable
            />
            <TextInput
              placeholder="อาชีพ"
              value={occupationFilter}
              onChange={(e) => setoccupationFilter(e.target.value?.length ? e.target.value : null)}
            />
            <Select
              value={schoolFilter}
              onChange={(val) => setschoolFilter(val?.length ? val : null)}
              placeholder="โรงเรียน"
              data={schoolListData}
              clearable
              searchable
            />
            <TextInput
              placeholder="จังหวัด"
              value={provinceFilter}
              onChange={(e) => setprovinceFilter(e.target.value?.length ? e.target.value : null)}
            />
          </Group>
        </Grid.Col>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col span={3}>
              <TextInput
                placeholder="พิมพ์แท็ก ระดับชั้น"
                value={levelTagFilter}
                onChange={(e) => setLevelTagFilter(e.target.value?.length ? e.target.value : null)}
                rightSection={
                  <>
                    {levelTagFilter?.length > 0 && (
                      <ClearIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setLevelTagFilter('');
                        }}
                      />
                    )}
                  </>
                }
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                placeholder="พิมพ์แท็ก วิชาที่สอน"
                value={subjectTagFilter}
                onChange={(e) => setSubjectTagFilter(e.target.value?.length ? e.target.value : null)}
                rightSection={
                  <>
                    {subjectTagFilter?.length > 0 && (
                      <ClearIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSubjectTagFilter('');
                        }}
                      />
                    )}
                  </>
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Search
                value={aliasFilter}
                onChange={(e) => setaliasFilter(e.target.value?.length ? e.target.value : null)}
                placeholder="พิมพ์ชื่อ นามปากกา หรือ userid  "
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      <Space h={40} />
      <Text className="text">จำนวน {length} account</Text>
      <Space h={24} />
      <TableGlobal data={data} columns={columns} />

      <AddMemberForm
        opened={openedAdd}
        onClose={() => setOpenedAdd(false)}
        handleSubmit={(event) => handleAddMemberManage(event)}
        centered
      />
    </div>
  );
}
