import { Group, Loader, Modal } from '@mantine/core';
import { useScrollLock } from '@mantine/hooks';

const { createContext, useState, useContext, useEffect } = require('react');

const LoaderStore = createContext();

export const LoaderProvider = ({ children, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [scrollLocked, setScrollLocked] = useScrollLock();

  useEffect(() => {
    setScrollLocked(loading);
  }, [loading]);

  return (
    <LoaderStore.Provider value={{ setLoading }}>
      <>{children}</>
      <Modal
        opened={loading}
        onClos={() => {}}
        withCloseButton={false}
        centered
        styles={{
          modal: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <Group sx={{ justifyContent: 'center' }}>
          <Loader color="yellow" />
        </Group>
      </Modal>
    </LoaderStore.Provider>
  );
};

export const useLoader = () => useContext(LoaderStore);
