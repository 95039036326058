import { ReactComponent as IconLink } from '@assets/icon/open.svg';
import { Group } from '@mantine/core';
import { MemberSearchResult } from '@models/Member.type';
import UserAvatar from '@pages/users/components/UserAvatar';
import _ from 'lodash';
import React from 'react';
import { TableColumn } from 'react-data-table-component';

export const columns: TableColumn<MemberSearchResult>[] = [
  {
    name: 'User ID',
    minWidth: '120px',
    cell: (row) => <UserAvatar frame={row.frame} picture={row.picture} userId={row.userId} alt={row.aliasName || row.name} />,
  },
  {
    name: 'ชาว ins',
    cell: (row) => row.donationPackage || '-',
  },
  {
    name: 'หน้า Profile',
    minWidth: '10%',
    cell: (row) => (
      <a
        href={`${process.env.REACT_APP_NEWSITE_URL}/profile/${row.userId}`}
        target={row.aliasName || row.name}
        rel="noreferrer"
        className="text-secondary-500 underline"
      >
        <Group spacing={6}>
          {_.trim(row.aliasName || row.name)}
          <IconLink />
        </Group>
      </a>
    ),
  },
  {
    name: 'สมัครด้วย',
    cell: (row) => _.startCase(_.lowerCase(row.applicationChannel)),
  },
  {
    name: 'ข้อมูลการสมัคร',
    minWidth: '10%',
    cell: (row) => row.email || row.applicationInformation,
  },
  {
    name: 'ชื่อ นามสกุล',
    minWidth: '10%',
    cell: (row) => row.name,
  },
  {
    name: 'นามปากกา',
    cell: (row) => row.aliasName || '-',
  },
  {
    name: 'ไลน์ที่ผูกบัญชี',
    cell: (row) => row.line || '-',
  },
  {
    name: 'เบอร์ติดต่อ',
    cell: (row) => row.tel || '-',
  },
  {
    name: 'อีเมลติดต่อ',
    minWidth: '12%',
    cell: (row) => row.contactEmail || '-',
  },
  {
    name: 'อาชีพ',
    cell: (row) => row.occupation || '-',
  },
  {
    name: 'โรงเรียน',
    minWidth: '8%',
    cell: (row) => row.school || '-',
  },
  {
    name: 'จังหวัด',
    cell: (row) => row.province || '-',
  },
  {
    name: 'ยอดรวม donate',
    minWidth: '8%',
    cell: (row) => row.totalAmount || '-',
  },
];
