import React, { useEffect } from 'react';
import { Button, Group, Select, Space, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useLogicList } from '../../../api/presenter/useLogicMessage';

/**
 *
 * @param {{actions:{create:function,update:function,cancel:function}}} param0
 * @returns
 */
export default function AddMsgForm({
  actions = {
    create: () => {},
    update: () => {},
    cancel: () => {},
  },
  row = {},
  reset = () => {},
  ...props
}) {
  const form = useForm({
    initialValues: {
      value: row?.value || '',
      logicID: `${row?.logicID}` || '',
    },
  });

  const { logics } = useLogicList();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <>
      <div>
        <Select
          required
          className="mt-1"
          label="เลือก Logic"
          placeholder="เลือก Logic ที่เคยสร้างไว้"
          data={logics}
          {...form.getInputProps('logicID')}
        />
        <Textarea placeholder="Your comment" label="พิมพ์ข้อความที่ต้องการได้เลย" required {...form.getInputProps('value')} />
        <Space h={16} />
        <Group sx={{ justifyContent: 'flex-end' }}>
          <Button
            classNames={{
              root: 'z-40 border rounded-full bg-transparent  hover:bg-transparent border-black text-black',
              label: '',
            }}
            type="button"
            onClick={() => actions.cancel()}
          >
            ยกเลิก
          </Button>
          <Button
            classNames={{
              root: 'z-40 border rounded-full bg-primary hover:bg-primary-600 active:bg-primary text-black',
              label: '',
            }}
            type="submit"
            onClick={() => {
              if (row?.logicMessageID) {
                return actions.update({
                  ...form.values,
                  logicID: parseInt(form.values.logicID),
                  logicMessageID: row?.logicMessageID,
                });
              } else {
                return actions.create({
                  ...form.values,
                  logicID: parseInt(form.values.logicID),
                });
              }
            }}
          >
            ยืนยัน
          </Button>
        </Group>
      </div>
    </>
  );
}
