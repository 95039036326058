import { Outlet } from 'react-router-dom';
import MainNavbars from './MainHeader/MainNavBars';

const Layout = () => {
  return (
    <>
      <MainNavbars />
      <main>
        <div className="container mx-auto px-4">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default Layout;
