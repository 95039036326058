import { DateRangePickerValue } from '@mantine/dates';

export enum SortType {
  LATEST_PAYMENT = 'LATEST_PAYMENT',
  TOP_DONATE = 'TOP_DONATE',
  INS_MEMBER = 'INS_MEMBER',
}

export enum StatusType {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export default class InsUserDatabaseFilter {
  sizes: number = 10;
  pages: number = 1;
  textSearch: string = '';
  sort: SortType = SortType.LATEST_PAYMENT;
  currentPackage: number[] | undefined = undefined;
  everBoughtPackage: number[] | undefined = undefined;
  status: string = '';
  occupation: string[] | undefined = undefined;
  email: string = '';
  startDate: string | undefined = undefined;
  endDate: string | undefined = undefined;
  dates: DateRangePickerValue | [null, null] = [null, null];
  isSearch: boolean = true;
}

export enum InsUserDatabaseActionType {
  SET_TEXT_SEARCH = 'SET_TEXT_SEARCH',
  SET_SORT = 'SET_SORT',
  SET_EMAIL = 'SET_EMAIL',
  SET_CURRENT_PACKAGE = 'SET_CURRENT_PACKAGE',
  SET_EVER_BOUGHT_PACKAGE = 'SET_EVER_BOUGHT_PACKAGE',
  SET_STATUS = 'SET_STATUS',
  SET_OCCUPATION = 'SET_OCCUPATION',
  SET_START_DATE = 'SET_START_DATE',
  SET_END_DATE = 'SET_END_DATE',
  SET_PAGE = 'SET_PAGE',
  CLICK_SEARCH = 'CLICK_SEARCH',
  DATES = 'DATES',
  CLEAR = 'CLEAR',
}

export type InsUserDatabaseAction =
  | { type: InsUserDatabaseActionType.SET_TEXT_SEARCH; payload: string }
  | { type: InsUserDatabaseActionType.SET_SORT; payload: SortType }
  | { type: InsUserDatabaseActionType.SET_EMAIL; payload: string }
  | { type: InsUserDatabaseActionType.SET_CURRENT_PACKAGE; payload: number[] }
  | { type: InsUserDatabaseActionType.SET_EVER_BOUGHT_PACKAGE; payload: number[] }
  | { type: InsUserDatabaseActionType.SET_STATUS; payload: StatusType }
  | { type: InsUserDatabaseActionType.SET_OCCUPATION; payload: string[] | undefined }
  | { type: InsUserDatabaseActionType.SET_START_DATE; payload: string }
  | { type: InsUserDatabaseActionType.SET_END_DATE; payload: string }
  | { type: InsUserDatabaseActionType.SET_PAGE; payload: number }
  | { type: InsUserDatabaseActionType.CLICK_SEARCH; payload: boolean }
  | { type: InsUserDatabaseActionType.DATES; payload: DateRangePickerValue }
  | { type: InsUserDatabaseActionType.CLEAR; payload?: undefined };

export type InsUserDatabaseDispatch = (action: InsUserDatabaseAction) => void;
