import { IdeaTemplateType } from '@constants/IdeaDatabase';
import { Select } from '@mantine/core';
import { IdeaDetailRes, TemplateType } from '@models/IdeaDatabase.type';
import React from 'react';

import QuillEditor from './QuillEditor';

interface IdeaTemplateProps {
  editIdeaData: IdeaDetailRes;
  handleChange: <K extends keyof IdeaDetailRes>(key: K) => (value: IdeaDetailRes[K]) => void;
}

const IdeaTemplate: React.FC<IdeaTemplateProps> = ({ editIdeaData, handleChange }) => {
  return (
    <>
      <div className="flex justify-end">
        <Select
          className="w-1/6"
          label="เทมเพลต"
          placeholder="เลือกเทมเพลต"
          value={editIdeaData.templateType}
          onChange={(value) => handleChange('templateType')(value as TemplateType)}
          data={IdeaTemplateType}
          radius="md"
        />
      </div>

      {editIdeaData.templateType === TemplateType.CLIP_FILE || editIdeaData.templateType === TemplateType.TELL ? (
        <div>
          <p>{editIdeaData.templateType === TemplateType.TELL ? 'เล่าเอง' : 'คำแนะนำในการใช้สื่อ'}</p>
          <QuillEditor value={editIdeaData.content} onChange={(value) => handleChange('content')(value)} />
        </div>
      ) : null}

      {editIdeaData.templateType === TemplateType.INSKRU && (
        <div className="flex flex-col gap-8">
          <div>
            <p>ที่มาของไอเดีย หรือ จุดประสงค์การสอน</p>
            <QuillEditor value={editIdeaData.purposes} onChange={(value) => handleChange('purposes')(value)} />
          </div>
          <div>
            <p>ขั้นตอน</p>
            <QuillEditor value={editIdeaData.process} onChange={(value) => handleChange('process')(value)} />
          </div>
          <div>
            <p>ผลลัพธ์ที่เกิด หรือ คาดว่าจะเกิดขึ้น</p>
            <QuillEditor value={editIdeaData.consequence} onChange={(value) => handleChange('consequence')(value)} />
          </div>
          <div>
            <p>บริบท หรือ ข้อเสนอแนะ</p>
            <QuillEditor value={editIdeaData.suggestion} onChange={(value) => handleChange('suggestion')(value)} />
          </div>
        </div>
      )}
    </>
  );
};

export default IdeaTemplate;
