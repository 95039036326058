import NewDatePicker from '@components/UI/DatePickerRange/NewDatePicker';
import NewSearch from '@components/UI/Search/NewSearch';
import {
  IdeaDatabaseInskruSelectItems,
  IdeaDatabaseSortItems,
  IdeaDatabaseTemplateItems,
  IdeaRecommendItems,
  TypeOfIdeaItems,
} from '@constants/IdeaDatabase';
import OCCUPATIONS from '@constants/Occupations';
import { useIdeaDatabaseState } from '@context/IdeaDatabase.context';
import { Button, MultiSelect, Select } from '@mantine/core';
import React, { useState } from 'react';
import { useIdeaDatabaseFunc } from './utils';

const IdeaFilter = () => {
  const { sort, ideaTitle, userTag, dates, alias, occupation, templateType, isRecommend, inskruSelected, typeOfIdea } =
    useIdeaDatabaseState();
  const {
    handleChangeDate,
    handleChangeIdeaRecommend,
    handleChangeOccupation,
    handleChangeSort,
    handleChangeTemplate,
    handleClickSearchIcon,
    handleChangeIdeaTitle,
    handleChangeAlias,
    handleChangeUserTags,
    handleClickClearSearch,
    handleChangeInskruSelected,
    handleChangeTypeOfIdea,
  } = useIdeaDatabaseFunc();

  const [userTagsResults, setUserTagsData] = useState<string[]>([]);

  return (
    <div className="mt-8 flex flex-col gap-2">
      <div className="grid gap-2 md:grid-cols-3">
        <div className="flex items-center gap-2">
          <h2 className="w-fit">เรียงลำดับจาก : </h2>
          <Select radius="md" data={IdeaDatabaseSortItems} value={sort} onChange={handleChangeSort} />
        </div>
        <div className="flex items-center gap-2">
          <h2 className="w-fit">ประเภทไอเดีย : </h2>
          <Select radius="md" data={TypeOfIdeaItems} value={typeOfIdea} onChange={handleChangeTypeOfIdea} />
        </div>
      </div>

      <div className="grid gap-2 md:grid-cols-3">
        <NewSearch
          placeholder="ค้นหาผ่านชื่อไอเดีย"
          value={ideaTitle}
          onChange={(e) => handleChangeIdeaTitle(e.currentTarget.value)}
          onClickSearch={handleClickSearchIcon}
        />

        <NewSearch
          placeholder="userid ชื่อ นามปากกาคนเขียน "
          value={alias}
          onChange={(e) => handleChangeAlias(e.currentTarget.value)}
          onClickSearch={handleClickSearchIcon}
        />

        <NewDatePicker value={dates} onChange={handleChangeDate} placeholder="เลือกช่วงวันที่เผยแพร่" />

        <Select
          radius="md"
          placeholder="ไอเดียแนะนำ"
          value={isRecommend ? isRecommend : ''}
          data={IdeaRecommendItems}
          onChange={handleChangeIdeaRecommend}
        />

        <MultiSelect
          radius="md"
          placeholder="Template"
          clearable
          value={templateType ? templateType : []}
          data={IdeaDatabaseTemplateItems}
          onChange={handleChangeTemplate}
        />

        <MultiSelect
          radius="md"
          placeholder="อาชีพ"
          clearable
          value={occupation ? occupation : []}
          data={OCCUPATIONS}
          onChange={handleChangeOccupation}
        />
      </div>

      <div className="grid gap-2 md:grid-cols-2">
        <MultiSelect
          radius="md"
          placeholder="insKru Selected"
          clearable
          value={inskruSelected ? inskruSelected : []}
          data={IdeaDatabaseInskruSelectItems}
          onChange={handleChangeInskruSelected}
        />

        <MultiSelect
          placeholder="User Tag"
          radius="md"
          searchable
          clearable
          creatable
          data={userTagsResults}
          getCreateLabel={(query) => `+ ${query}`}
          value={userTag}
          onCreate={(query) => {
            setUserTagsData((prev) => [...prev, query]);
            return query;
          }}
          onChange={handleChangeUserTags}
        />
      </div>

      <div className="float-right w-full">
        <Button className="float-right w-fit" variant="subtle" onClick={handleClickClearSearch}>
          ล้างตัวกรอง
        </Button>
      </div>
    </div>
  );
};

export default IdeaFilter;
