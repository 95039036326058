import React from 'react';
import { ReactComponent as Calendar } from '../../../assets/icon/calendar.svg';
import { DatePicker } from '@mantine/dates';
import { useMediaQuery } from '@mantine/hooks';
export default function DatePickerUI(props) {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return (
    <DatePicker
      styles={{
        label: { alignSelf: 'center', marginRight: '10px' },
        arrow: { height: 0, width: 0 },
        calendarBase: { color: 'red' },
        calendarHeader: { color: 'black' },
        calendarHeaderControl: { color: 'gray' },
        calendarHeaderLevel: {
          color: 'black',
          fontSize: 'large',
        },
        yearPickerControlActive: { color: 'black' },
        monthPickerControlActive: { color: 'black' },
        calendarHeaderLevelIcon: { color: 'white !important' },
        weekday: { color: 'gray' },
        cell: { color: 'green !important' },
        weekend: { color: 'black !important' },
        selected: {
          color: 'black !important',
          backgroundColor: '#FFCF25 !important',
          borderRadius: 24,
        },
      }}
      // label={label}
      // placeholder={placeholder}
      firstDayOfWeek="sunday"
      icon={<Calendar />}
      dropdownType={isMobile ? 'modal' : 'popover'}
      {...props}
    ></DatePicker>
  );
}
