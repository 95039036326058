import React from 'react';
import { Box, Button } from '@mantine/core';
import { createTable } from '@tanstack/react-table';

import { ReactComponent as Trash } from '../../../../assets/icon/trash.svg';
import { ReactComponent as Pencil } from '../../../../assets/icon/pencil.svg';
import { ReactComponent as Plus } from '../../../../assets/icon/Plus.svg';

import { Switch } from '@mantine/core';
import Select from '../../../UI/Selector/Select';
import Search from '../../../UI/Search/Search';
import DialogCard from '../../../../components/UI/Card/DaialogCard';
import Table from '../../../UI/Table/Table';
import UserIdUI from '../../../UI/User/UserId';
import AddSubSchoolCodeForm from '../../SchoolManage/SubSchool/SubSchoolForm/AddSubSchoolCodeForm';
import DelSubSchoolForm from '../../SchoolManage/SubSchool/SubSchoolForm/DelSubSchoolForm';
import LinkUI from '../../../UI/Link/LinkUI';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import dayjs from 'dayjs';

const SCHOOL_GROUP_CODE_LIST_URL = '/school-group/code/list';
const SCHOOL_GROUP_CODE_ACTIVE_URL = '/school-group/code/active';
const SCHOOL_GROUP_CODE_IN_ACTIVE_URL = '/school-group/code/inactive';

export default function SubSchoolMember() {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const [elements, setElements] = React.useState([]);
  React.useEffect(() => {
    schoolGroupCodeListFetch();
  }, []);

  const schoolGroupCodeListFetch = async () => {
    const response = await axiosPrivate.post(SCHOOL_GROUP_CODE_LIST_URL, {
      schoolGroupID: +id,
    });
    setElements(response.data.data.items);
  };
  const table = createTable().setRowType();

  const hendleOnChang = async (schoolGroupCodeID, checked) => {
    console.log(schoolGroupCodeID, checked);
    if (checked) {
      await axiosPrivate.post(SCHOOL_GROUP_CODE_ACTIVE_URL, {
        schoolGroupCodeID,
      });
    } else {
      await axiosPrivate.post(SCHOOL_GROUP_CODE_IN_ACTIVE_URL, {
        schoolGroupCodeID,
      });
    }
    schoolGroupCodeListFetch();
  };

  const defaultColumns = table.createColumns([
    table.createDataColumn((row) => row.code, {
      id: 'position',
      cell: (info) => info.value,
      header: () => <span>รหัส Code</span>,
    }),
    table.createDataColumn((row) => row, {
      id: 'userid',
      cell: (info) => (
        <>
          {info.value.couponUsed} / {info.value.maxMember}{' '}
        </>
      ),
      header: () => <span>จำนวนทั้งหมด</span>,
    }),
    table.createDataColumn((row) => row.expiredAt, {
      id: 'userName',
      cell: (info) => dayjs(info.value).format('DD/MM/YY'),
      header: () => <span>วันหมดอายุ</span>,
    }),
    table.createDataColumn((row) => row.isActive, {
      id: 'userGoal',
      cell: (info) => (info.value ? 'กำลังใช้งาน' : 'ปิดใช้งาน'),
      header: () => <span>สถานะ</span>,
    }),
    table.createDataColumn(
      (row) => ({
        isActive: row.isActive,
        schoolGroupCodeID: row.schoolGroupCodeID,
      }),
      {
        id: 'userJob',
        cell: (info) => (
          <Switch
            checked={info.value.isActive}
            color="green"
            onChange={(event) => hendleOnChang(info.value.schoolGroupCodeID, event.currentTarget.checked)}
          />
        ),
        header: () => <span></span>,
      }
    ),
  ]);
  return (
    <div>
      {' '}
      <div className="mt-8 text-2xl font-bold tracking-wide md:text-xl lg:text-4xl">Code</div>
      <div className="mt-8">
        <AddSubSchoolCodeForm
          Dialog={DialogCard}
          buttonTitle="เพิ่ม Code"
          modalTitile="เพิ่ม Code"
          buttonIcon={<Plus />}
          fetch={schoolGroupCodeListFetch}
        ></AddSubSchoolCodeForm>
      </div>
      <div className="text-md mb-4 mt-8">จำนวน {elements.length} code</div>
      <Box>
        <Table elements={elements} defaultColumns={defaultColumns} />
      </Box>
    </div>
  );
}
