import { Button, Group, Modal, Space, Select, Text, NumberInput, Anchor } from '@mantine/core';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import download from 'downloadjs';
import { parse } from 'json2csv';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSubSchoolMember } from '../../../../../api/presenter/useSubSchoolMember';
import { ReactComponent as Download } from '../../../../../assets/icon/download.svg';
import { SCHOOL_ROLE_ID } from '../SubSchoolMember';
const SchoolRoleIDs = [
  {
    label: 'School Membership',
    value: '1',
  },
  {
    label: 'ผอ./ฝ่ายวิชาการ',
    value: '2',
  },
];

/**
 * @param {Object} props
 * @param {{state:{modal:boolean}}} props.state
 * @param {{actions:{setmodal:function}}} props.actions
 * @param {{oldID:string,schoolRoleID:string}} props.initialValues
 * @returns
 */
const SubSchoolMemberControlled = ({
  state = {
    modal: false,
  },
  actions = {
    setmodal: () => {},
    schoolGroupMemberListFetch: () => {},
  },
  initialValues = {},
  data,
  ...props
}) => {
  const { id } = useParams();
  const exportCSVHandler = function () {
    const headers = [
      'เริ่ม',
      'หมดอายุ',
      'userid',
      'ชื่อ-นามสกุล',
      'หน้าเป้าหมาย',
      'อาชีพ',
      'โรงเรียน',
      'จังหวัด',
      'วิชาที่สอน',
      'ระดับชั้น',
      'ตำแหน่ง',
    ];
    const director = data
      .filter((item) => (item?.schoolRoleID === SCHOOL_ROLE_ID.SCHOOL_DIRECTOR ? item : null))
      .map((item) => ({
        เริ่ม: (item.codeActiveDate ? dayjs(item.expirationDate) : dayjs()).format('DD/MM/YY') || '',
        หมดอายุ: (item.expirationDate ? dayjs(item.expirationDate) : dayjs()).format('DD/MM/YY') || '',
        userid: item.oldID || '',
        'ชื่อ-นามสกุล': item.fullName || '',
        อาชีพ: item.occupation || '',
        โรงเรียน: item.school || '',
        จังหวัด: item.province || '',
        วิชาที่สอน: item.subjects.join(',') || '',
        ระดับชั้น: item.levels.join(',') || '',
        ตำแหน่ง: 'ผอ. / ฝ่ายวิชาการ',
      }));
    const members = data
      .filter((item) => (item?.schoolRoleID === SCHOOL_ROLE_ID.MEMBERSHIP ? item : null))
      .map((item) => ({
        เริ่ม: (item.codeActiveDate ? dayjs(item.expirationDate) : dayjs()).format('DD/MM/YY') || '',
        หมดอายุ: (item.expirationDate ? dayjs(item.expirationDate) : dayjs()).format('DD/MM/YY') || '',
        userid: item.oldID || '',
        'ชื่อ-นามสกุล': item.fullName || '',
        อาชีพ: item.occupation || '',
        โรงเรียน: item.school || '',
        จังหวัด: item.province || '',
        วิชาที่สอน: item.subjects.join(',') || '',
        ระดับชั้น: item.levels.join(',') || '',
        ตำแหน่ง: 'School Membership',
      }));

    const csv = parse([...director, ...members], headers);
    download(csv, `member-exported-${dayjs().format('DD-MM-YYYY_HH-mm-ss')}.csv`, 'text/csv');
  };
  return (
    <div>
      <Space h={24} />
      <Group noWrap>
        <PrimaryBtn onClick={() => actions.setmodal(true)}>+ เพิ่มบัญชีในกลุ่มนี้</PrimaryBtn>
        <Anchor href={`${process.env.REACT_APP_NEWSITE_URL}/schooldashboard/${id}`} target="_blank">
          <Button className="rounded-full border border-black bg-transparent font-normal text-black hover:bg-transparent">
            ดูแดชบอร์ด
          </Button>
        </Anchor>
        <Button
          classNames={{
            root: 'z-40 rounded-full',
            label: 'text-black text-[#2D2E2E]',
            icon: 'mr-0',
            filled: 'bg-primary hover:bg-primary-600 active:bg-primary',
            outline: 'border-gray-700',
          }}
          variant="outline"
          leftIcon={<Download />}
          onClick={exportCSVHandler}
        >
          &nbsp;&nbsp; export csv.
        </Button>
      </Group>
      <ManualAdd
        initialValues={initialValues}
        opened={state.modal}
        onClose={() => actions.setmodal(false)}
        actions={{
          close: () => actions.setmodal(false),
          refetch: () => actions.schoolGroupMemberListFetch(),
        }}
      />
    </div>
  );
};

const PrimaryBtn = ({ classNames, styles, ...props }) => {
  return (
    <Button
      classNames={{
        root: 'z-40 bg-primary hover:bg-primary-600 active:bg-primary rounded-full text-black font-normal',
        label: '',
        icon: 'mr-0',
        ...classNames,
      }}
      styles={{
        leftIcon: {
          marginRight: 15,
        },
        ...styles,
      }}
      {...props}
    />
  );
};

const ManualAdd = ({ initialValues, actions, ...props }) => {
  const { dispatch } = useSubSchoolMember();

  const form = useForm({
    initialValues: {
      oldID: '',
      schoolRoleID: '',
    },
    validate: {
      oldID: (value) => (value > 0 ? null : 'กรุณากรอก UserId'),
      schoolRoleID: (value) => (value > 0 ? null : 'กรุณาเลือกตำแหน่ง'),
    },
  });

  useEffect(() => {
    form.setFieldValue('oldID', parseInt(initialValues?.oldID) || '');
    form.setFieldValue('schoolRoleID', initialValues?.schoolRoleID || '');
  }, [initialValues]);

  const { id } = useParams();

  return (
    <Modal centered {...props}>
      <Text
        className="text-[24px] font-semibold text-[#2D2E2E]"
        align="center"
        sx={{
          letterSpacing: '0.05em',
        }}
      >
        เพิ่มบัญชีในกลุ่มนี้
      </Text>
      <form
        onSubmit={form.onSubmit((value) => {
          if (initialValues?.oldID?.length) {
            dispatch('update', {
              oldID: value.oldID,
              schoolRoleID: value.schoolRoleID,
            }).then((res) => {
              actions.refetch();
              actions.close();
            });
          } else {
            dispatch('create', {
              oldID: value.oldID,
              schoolRoleID: value.schoolRoleID,
              schoolGroupID: id,
            }).then((res) => {
              actions.refetch();
              actions.close();
            });
          }
        })}
      >
        <NumberInput
          label="UserId"
          min={0}
          hideControls
          disabled={initialValues?.oldID?.length > 0}
          {...form.getInputProps('oldID')}
          // error="กรุณากรอก Userid"
        />
        <Select
          label="ตำแหน่ง"
          data={SchoolRoleIDs}
          {...form.getInputProps('schoolRoleID')}
          // error="กรุณาเลือกตำแหน่ง"
        />
        <Space h={16} />
        <Group sx={{ justifyContent: 'flex-end' }}>
          <Button
            classNames={{
              root: 'z-40 border rounded-full bg-transparent  hover:bg-transparent border-black text-black font-normal',
              label: '',
            }}
            type="button"
            onClick={actions.close}
          >
            ยกเลิก
          </Button>
          <Button
            classNames={{
              root: 'z-40 border rounded-full bg-primary hover:bg-primary-600 active:bg-primary text-black font-normal',
              label: '',
            }}
            type="submit"
          >
            ยืนยัน
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default SubSchoolMemberControlled;
